<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 958.58 595.28">
    <g id="Locations">
        <circle
            id="Ellipse_210"
            data-name="Ellipse 210"
            class="cls-1"
            cx="851.26"
            cy="385.13"
            r="7.65"
        />
        <circle
            id="Ellipse_240"
            data-name="Ellipse 240"
            class="cls-1"
            cx="64.23"
            cy="223.48"
            r="7.65"
        />
        <path
            id="Path_1902"
            data-name="Path 1902"
            class="cls-2"
            d="M62.3,223.28c23,19.76,21,20.3,21,20.3s-2.5,2.53-11.71,18.6S64.36,303,51.91,310.82s-15,6.13-11.8,25.39"
        />
        <path
            id="Path_1901"
            data-name="Path 1901"
            class="cls-2"
            d="M82.73,243.61l9.66-23.47"
        />
        <path
            id="Path_1893"
            data-name="Path 1893"
            class="cls-2"
            d="M87.56,233.64l21.94-5.87s5.93-10.13,11.92-10.81,11.41,3.13,16.79,2.79,19.2-5.7,19.2-5.7,8.55-.79,12.31,0,18,6,20.05,5.7,40.28-11.94,40.28-11.94,6.43-11.65,12.55-15,63.74-26.18,63.74-26.18h6.06s6.39,30.06,31,29.54,47.06-2.46,51.13,0,26.33,11.61,26.33,11.61,9.24,21.69,16.84,23.65,11.12,2.13,14.28,0,9.82-9,12.15-11.71,9.16-16.18,12.29-16.64,8.47-.35,14.06,0,37.69,11,41.3,10.94,10.12,13.09,10.79,13.72-1.43,16.75,0,19,14.27,15.66,14.27,15.66,26.44.28,29.4,2.11,17.91,24.76,23.93,24.86,26.07,3.4,30.1,4.38,29.53,8.88,35.39,11.76,19,23,24.53,25.49,33,21.88,35.87,22,32.34-1.25,36.82,3,8.42,15.52,13.17,17,29.75,8.21,29.75,8.21,2.19,4.54,0,6.88-2.11,6-5.2,8.86S799,405.44,799,405.44s-12.19,8-13,10-9.26,11.13-10.16,13.87-.82,17.1-3,20a22.32,22.32,0,0,0-3.13,7.56"
        />
        <path
            id="Path_1885"
            data-name="Path 1885"
            class="cls-2"
            d="M808.63,453s10.28-24.79,11.68-26.58,7.56-17.42,8.18-20.47S831,389.2,831,389.2l-14.23-6.92"
        />
        <path
            id="Path_1886"
            data-name="Path 1886"
            class="cls-2"
            d="M831,388.57c3.89-1.34,10.15-6.46,18.2-7.13s31.77,0,31.77,0"
        />
        <path
            id="Path_1887"
            data-name="Path 1887"
            class="cls-2"
            d="M853,420.79s-9.64,17.89-13.13,28.16-2.24,26.66-2.9,34.69,39.43-23.38,39.43-23.38S890.48,437,891.09,434,889,423,889,423"
        />
        <path
            id="Path_1888"
            data-name="Path 1888"
            class="cls-2"
            d="M889.3,423.15c-36.54.1-36.33-2.75-36.33-2.75"
        />
        <path
            id="Path_1882"
            data-name="Path 1882"
            class="cls-2"
            d="M886.87,420.4s-13.33-13.9-15.17-17-3.35-10.6-3.68-15.84S868,350,868,350s21.48-16.92,25.33-22.13,8.92-13.72,9.17-15.54-.76-14.77,2.45-18.55,16.5-24.06,16.5-24.06"
        />
        <path
            id="Path_1883"
            data-name="Path 1883"
            class="cls-2"
            d="M934.44,279.48s-13.88-8.26-16.29-12.06-9.08-15.06-11.87-17.29-20-7.2-20-7.2-46.71-39.69-50.32-41.69-19.9-4.6-22.35-4.41-6.13,9.06-15,7.51-17.59-2.87-20.53-3.1-7.53,5.94-11.56,3.1S738.18,164.73,735.76,164s-23.41,0-23.41,0-32.42-31.74-36.81-31.83-6.51-.61-11.41,0-43.93,5-47.15,6.42-14.77,11.91-18.7,13.46-19.27,5.62-30.76,5.36-34.64,0-34.64,0-15.67-5.3-18.25-5.36S485.26,140.26,480,141.29s-13.61,11.08-22.43,10.76-47.61-3.57-54.79-5.55-12.18-5.76-18.66-5.21-53.15,5.48-57,5.21S309.63,164,309.63,164"
        />
        <path
            id="Path_1895"
            data-name="Path 1895"
            class="cls-2"
            d="M306.66,161.23s-13.59-25.9-16.09-28.41-17.93-8.7-17.93-8.7.78-6.16-3.43-10.62-11.15-6.78-13.4-7.22-14.72-1.07-16.29,0-12.08,4.47-12.08,4.47"
        />
        <path
            id="Path_1889"
            data-name="Path 1889"
            class="cls-2"
            d="M780.66,367.82c-.57-3.48-.77-11.26,4.15-14.09s5.48,3.71,8.51-4.68,7-19.88,9.18-20.23,11.8,0,11.8,0l31,9.56,2.12,3.37s-2.66,9-2.12,12,3.52,15.64,5.61,18.26,4.66,13.09,4.55,15.57,1.42,17.39,0,19.25S853,420.4,853,420.4"
        />
        <path
            id="Path_1890"
            data-name="Path 1890"
            class="cls-2"
            d="M828.91,406.13,852.54,421"
        />
        <path
            id="Path_1891"
            data-name="Path 1891"
            class="cls-2"
            d="M846.59,357s4,3.7,10.91,0a76.21,76.21,0,0,0,11.68-7.44"
        />
        <path
            id="Path_1884"
            data-name="Path 1884"
            class="cls-2"
            d="M896.83,345.06c2.14-4.46,9.19-26.59,13-32.38s23.62-33.41,23.62-33.41"
        />
        <path
            id="Path_1896"
            data-name="Path 1896"
            class="cls-2"
            d="M112.39,227.48l22.68,22.44L189.42,222"
        />
        <path
            id="Path_1898"
            data-name="Path 1898"
            class="cls-2"
            d="M133.2,131.88c-6.41,1.64-10.83,2.63-13.48,7.22s-1,11.93,0,13.82,6.75,9.54,6.72,13.64,0,32.69,0,32.69"
        />
        <path
            id="Path_1899"
            data-name="Path 1899"
            class="cls-2"
            d="M139.23,175.53c-3.13.42-13.84,6-13.84,6"
        />
        <path
            id="Path_1897"
            data-name="Path 1897"
            class="cls-2"
            d="M149.39,217.32s-7.31-18.48-18.42-15.1"
        />
        <path
            id="Path_1900"
            data-name="Path 1900"
            class="cls-2"
            d="M102.23,115.31S77.42,162.25,124.65,203"
        />
        <circle
            id="Ellipse_241"
            data-name="Ellipse 241"
            class="cls-1"
            cx="72.26"
            cy="260.13"
            r="7.65"
        />
        <circle
            id="Ellipse_239"
            data-name="Ellipse 239"
            class="cls-1"
            cx="95.26"
            cy="217.13"
            r="7.65"
        />
        <circle
            id="Ellipse_243"
            data-name="Ellipse 243"
            class="cls-1"
            cx="39.26"
            cy="335.13"
            r="7.65"
        />
        <circle
            id="Ellipse_237"
            data-name="Ellipse 237"
            class="cls-1"
            cx="111.26"
            cy="226.13"
            r="7.65"
        />
        <circle
            id="Ellipse_234"
            data-name="Ellipse 234"
            class="cls-1"
            cx="136.26"
            cy="250.13"
            r="7.65"
        />
        <circle
            id="Ellipse_230"
            data-name="Ellipse 230"
            class="cls-1"
            cx="188.26"
            cy="222.13"
            r="7.65"
        />
        <circle
            id="Ellipse_231"
            data-name="Ellipse 231"
            class="cls-1"
            cx="206.26"
            cy="216.13"
            r="7.65"
        />
        <circle
            id="Ellipse_232"
            data-name="Ellipse 232"
            class="cls-1"
            cx="149.26"
            cy="216.13"
            r="7.65"
        />
        <circle
            id="Ellipse_235"
            data-name="Ellipse 235"
            class="cls-1"
            cx="126.26"
            cy="203.13"
            r="7.65"
        />
        <circle
            id="Ellipse_233"
            data-name="Ellipse 233"
            class="cls-1"
            cx="139.26"
            cy="175.13"
            r="7.65"
        />
        <circle
            id="Ellipse_236"
            data-name="Ellipse 236"
            class="cls-1"
            cx="133.26"
            cy="130.13"
            r="7.65"
        />
        <circle
            id="Ellipse_238"
            data-name="Ellipse 238"
            class="cls-1"
            cx="102.26"
            cy="116.13"
            r="7.65"
        />
        <circle
            id="Ellipse_227"
            data-name="Ellipse 227"
            class="cls-1"
            cx="229.26"
            cy="110.13"
            r="7.65"
        />
        <circle
            id="Ellipse_228"
            data-name="Ellipse 228"
            class="cls-1"
            cx="272.26"
            cy="123.13"
            r="7.65"
        />
        <circle
            id="Ellipse_229"
            data-name="Ellipse 229"
            class="cls-1"
            cx="309.26"
            cy="164.13"
            r="7.65"
        />
        <circle
            id="Ellipse_225"
            data-name="Ellipse 225"
            class="cls-1"
            cx="631.26"
            cy="291.13"
            r="7.65"
        />
        <circle
            id="Ellipse_226"
            data-name="Ellipse 226"
            class="cls-1"
            cx="664.26"
            cy="302.13"
            r="7.65"
        />
        <circle
            id="Ellipse_211"
            data-name="Ellipse 211"
            class="cls-1"
            cx="886.26"
            cy="242.13"
            r="7.65"
        />
        <circle
            id="Ellipse_209"
            data-name="Ellipse 209"
            class="cls-1"
            cx="934.26"
            cy="279.13"
            r="7.65"
        />
        <circle
            id="Ellipse_212"
            data-name="Ellipse 212"
            class="cls-1"
            cx="899.26"
            cy="345.13"
            r="7.65"
        />
        <circle
            id="Ellipse_213"
            data-name="Ellipse 213"
            class="cls-1"
            cx="846.26"
            cy="339.13"
            r="7.65"
        />
        <circle
            id="Ellipse_214"
            data-name="Ellipse 214"
            class="cls-1"
            cx="831.26"
            cy="391.13"
            r="7.65"
        />
        <circle
            id="Ellipse_215"
            data-name="Ellipse 215"
            class="cls-1"
            cx="818.26"
            cy="382.13"
            r="7.65"
        />
        <circle
            id="Ellipse_222"
            data-name="Ellipse 222"
            class="cls-1"
            cx="795.26"
            cy="406.13"
            r="7.65"
        />
        <circle
            id="Ellipse_223"
            data-name="Ellipse 223"
            class="cls-1"
            cx="770.26"
            cy="457.13"
            r="7.65"
        />
        <circle
            id="Ellipse_224"
            data-name="Ellipse 224"
            class="cls-1"
            cx="808.26"
            cy="453.13"
            r="7.65"
        />
        <circle
            id="Ellipse_216"
            data-name="Ellipse 216"
            class="cls-1"
            cx="821.26"
            cy="424.13"
            r="7.65"
        />
        <circle
            id="Ellipse_217"
            data-name="Ellipse 217"
            class="cls-1"
            cx="852.26"
            cy="419.13"
            r="7.65"
        />
        <circle
            id="Ellipse_218"
            data-name="Ellipse 218"
            class="cls-1"
            cx="889.26"
            cy="422.13"
            r="7.65"
        />
        <circle
            id="Ellipse_219"
            data-name="Ellipse 219"
            class="cls-1"
            cx="881.26"
            cy="382.13"
            r="7.65"
        />
        <circle
            id="Ellipse_220"
            data-name="Ellipse 220"
            class="cls-1"
            cx="876.26"
            cy="459.13"
            r="7.65"
        />
        <circle
            id="Ellipse_221"
            data-name="Ellipse 221"
            class="cls-1"
            cx="837.26"
            cy="483.13"
            r="7.65"
        />
        <path
            id="Path_1892"
            data-name="Path 1892"
            class="cls-2"
            d="M798.1,205.68s-7.17,21.44-3.7,31.74,3.43,23.58,8,26.92,21.27,17.36,22.93,21,5.45,19.64,5.3,24.63-8.82,20.4-8.82,20.4"
        />
        <path
            id="Path_1894"
            data-name="Path 1894"
            class="cls-2"
            d="M679.2,308.67s22,1.49,29.26,3.76,20,10.95,26.75,12a150.32,150.32,0,0,0,22.43,1.5c5.14-.16,13.4,2.33,20.74,3.24s24.33,0,24.33,0"
        />
    </g>
</svg>
