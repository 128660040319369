<div class="modal">
  <div class="modal-bg" @click="$emit('modal', false)"></div>
  <div class="calculate-savings-modal">
    <!-- Close button -->
    <div class="close-modal" @click="$emit('modal', false)">
      <div class="cross-1"></div>
      <div class="cross-2"></div>
    </div>
    <div class="col-1">
      <!-- Select type button -->
      <div class="select-type" @click="toggleTypes()">
        <div class="active-type">{{activeType}}</div>
        <div class="icon" :class="[{ open: isTypesOpen }]"></div>
      </div>
      <!-- Dynamic container types -->
      <div class="types" :class="[{active: isTypesOpen}]">
        <div v-for="type in types" class="type" @click="setType(type); toggleTypes();">{{type}}</div>
      </div>
      <!-- Mini description -->
      <div class="desc">Personalise the performance to suit your business needs.</div>
      <!-- Add requirements button (only on mobile devices) -->
      <div class="add-requirements mobile" @click="toggleRequirements()">
        <span class="btn-circle">{{addRequirements}}</span>
        <span class="label">{{addRequirementsLabel}}</span>
      </div>
      <!-- Dynamic requirements based on selected category -->
      <div class="requirements mobile" :class="[{active: isRequirementsOpen}]">
        <div v-for="requirement in [...activeRequirements['row-1'], ...activeRequirements['row-2'], ...activeRequirements['row-3']]"
          class="requirement">
          <div class="label" v-html="requirement.label"></div>
          <div class="input" v-for="input in requirement.inputs">
            <span class="prefix">{{input.prefix}}</span>
            <input type="number" v-model="inputs[input.name]">
            <span class="suffix">{{input.suffix}}</span>
          </div>
        </div>
      </div>
      <!-- Saving categories -->
      <div class="saving-categories">
        <div class="saving-category" :class="[{active: activeCategory === 'category-1'}]"
          @click="activateCategory('category-1')">
          CO<span class="sub">2</span>
        </div>
        <div class="saving-category" :class="[{active: activeCategory === 'category-2'}]"
          @click="activateCategory('category-2')">Additional cargo</div>
        <div class="saving-category" :class="[{active: activeCategory === 'category-3'}]"
          @click="activateCategory('category-3')">Fuel savings</div>
        <div class="saving-category" :class="[{active: activeCategory === 'category-4'}]"
          @click="activateCategory('category-4')">Additional residual value</div>
        <div class="saving-category" :class="[{active: activeCategory === 'category-5'}]"
          @click="activateCategory('category-5')">Total additional value</div>
      </div>
    </div>
    <div class="col-2">
      <!-- Dynamic requirements based on selected category -->
      <div class="requirements desktop-flex">
        <div class="row-1">
          <div v-for="requirement in activeRequirements['row-1']" class="requirement">
            <div class="label" v-html="requirement.label"></div>
            <div class="input" v-for="input in requirement.inputs">
              <span class="prefix">{{input.prefix}}</span>
              <input type="number" v-model="inputs[input.name]">
              <span class="suffix">{{input.suffix}}</span>
            </div>
          </div>
        </div>
        <div class="row-2">
          <div v-for="requirement in activeRequirements['row-2']" class="requirement">
            <div class="label" v-html="requirement.label"></div>
            <div class="inputs">
              <div class="input" v-for="input in requirement.inputs">
                <span class="prefix">{{input.prefix}}</span>
                <input type="number" v-model="inputs[input.name]">
                <span class="suffix">{{input.suffix}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row-3">
          <div v-for="requirement in activeRequirements['row-3']" class="requirement">
            <div class="label" v-html="requirement.label"></div>
            <div class="inputs">
              <div class="input" v-for="input in requirement.inputs">
                <span class="prefix">{{input.prefix}}</span>
                <input type="number" v-model="inputs[input.name]">
                <span class="suffix">{{input.suffix}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Dynamic results based on selected category -->
      <div class="header desktop" v-html="activeResults.header"></div>
      <div class="results">
        <div class="result" v-for="result in activeResults.results">
          <div class="title" v-html="result.title"></div>
          <div class="calcs">
            <div class="calc-item" v-for="calc in result.calcs">
              <span
                :title="`${calc.prefix}${toFixedDecimals(calc.value, 4)}`"
                class="prefix"
              >
                {{calc.prefix}}{{toFixedDecimals(calc.value, 4)}}
              </span>
              <div class="suffix" v-html="calc.suffix"></div>
            </div>
          </div>
        </div>
        <div class="preferred-savings" v-if="activeCategory === 'category-5'">
          <div>Select preferred savings</div>
          <div class="preferred-savings-form">
            <div class="form-control">
              <input type="radio" id="weight" name="type" value="weight" v-model="sensitiveCargo">
              <label for="weight">Weight Sensitive Cargo</label>
            </div>
            <span>or</span>
            <div class="form-control">
              <input type="radio" id="volume" name="type" value="volume" v-model="sensitiveCargo">
              <label for="volume">Volume Sensitive Cargo</label>
            </div>
          </div>
        </div>
        <div class="total">
          <div class="title" v-html="activeResults.total.title"></div>
          <div class="calcs">
            <div class="calc-item total-item" v-for="calc in activeResults.total.calcs">
              <div class="total-title">
                <span v-if="calc.title" v-html="calc.title"></span>
              </div>
              <div>
                <span
                  :title="`${calc.prefix}${toFixedDecimals(calc.value, 4)}`"
                  class="prefix"
                  :class="{'full-width': calc.suffix === ''}"
                >
                  {{calc.prefix}}{{toFixedDecimals(calc.value, 4)}}
                </span>
                <div class="suffix" v-html="calc.suffix"></div>
              </div>
            </div>
            <div class="outro" v-html="activeResults.total.outro"></div>
          </div>
        </div>
      </div>
      <div class="spacer desktop" v-if="activeCategory === 'category-5'"></div>
    </div>

  </div>
</div>