<template lang="html" src="./espa.html"></template>
<style lang="scss" scoped src="./_espa.mobile.scss"></style>
<style lang="scss" scoped src="./_espa.desktop.scss"></style>

<script>
export default {
  name: "EspaComponent",
  props: ["topRight", "bottomLeft"],
  methods: {}
};
</script>
