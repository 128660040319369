<template lang="html" src="./sustainability.section.html"></template>
<style lang="scss" scoped src="./_sustainability.section.mobile.scss"></style>
<style lang="scss" scoped src="./_sustainability.section.desktop.scss"></style>

<script>
import SeaPath from "./map-paths/sea-path/sea-path.vue";
import RoadPath from "./map-paths/road-path/road-path.vue";
import RailPath from "./map-paths/rail-path/rail-path.vue";

export default {
  name: "SustainabilitySection",
  props: ["isActive", "isLearnMoreActive"],
  components: {
    SeaPath,
    RoadPath,
    RailPath
  },
  data() {
    return {
      currMap: "sea",
      currSlide: "first"
    };
  },
  methods: {
    openLearnMore() {
      if (!this.isLearnMoreActive) {
        this.$emit("learn-more", "sustainability");
      }
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    },
    changeMap(map) {
      this.currMap = map;
    },
    changeSlide(slide) {
      this.currSlide = slide;
    }
  }
};
</script>
