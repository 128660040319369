<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="container">
          <div class="info">
            <div class="title">Make enquiry</div>
            <hr>
          </div>
          <div>
            <div class="title">Enter contact details</div>

            <div class="contact form-control">
              <div class="input form-control">
                <input type="text" placeholder="First name (required)" v-model="form.enquiry.firstname" required>
              </div>
              <div class="input form-control">
                <input type="text" placeholder="Last name (required)" v-model="form.enquiry.lastname" required>
              </div>
              <div class="multiline">
                <div class="input form-control">
                  <input type="text" placeholder="Company (optional)" v-model="form.enquiry.company">
                </div>
                <div class="input form-control">
                  <input type="text" placeholder="Country (required)" v-model="form.enquiry.country" required>
                </div>
              </div>
              <div class="input form-control">
                <input type="email" placeholder="Email address (required)" v-model="form.enquiry.email" required>
              </div>
              <div class="input form-control">
                <input type="phone" placeholder="Phone number (required)" v-model="form.enquiry.phone" required>
              </div>
            </div>

          </div>
          <div v-if="!isMobile" v-html="captcha"></div>
        </div>
      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="summary">
          <div class="summary-title">Order summary</div>
          <hr>
          <div class="container-image-background" :class="form.container.type"></div>
          <div class="section">
            <div class="section-title">Container specifications</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'container')">Edit</a>
              <div class="capitalize width-90">{{ form.container.type.slice(0, -1) }}D</div>
              <div>Color: {{ form.exterior.color }} <span :style="{backgroundColor: form.exterior.color, width: '12px', height: '12px', display: 'inline-block'}"></span></div>
              <div>Side Decal: {{ form.exterior.logos.logo1.label ? form.exterior.logos.logo1.label : "No graphics applied" }}</div>
              <div>Front Decal: {{ form.exterior.logos.logo2.label ? form.exterior.logos.logo2.label : "No graphics applied" }}</div>
              <div>Door Decal: {{ form.exterior.logos.logo3.label ? form.exterior.logos.logo3.label : "No graphics applied" }}</div>
              <div>Quantity: {{ form.container.quantity }}</div>
            </div>
            <div class="section-title">Use option</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'container')">Edit</a>
              <div class="capitalize width-90">{{ form.container.purchase }}</div>
              <div v-if="form.container.purchase === 'rent' && form.container.time.rent > 0" class="capitalize">{{ form.container.time.rent }} months</div>
              <div v-if="form.container.purchase === 'lease' && form.container.time.lease > 0" class="capitalize">{{ form.container.time.lease }} months</div>
            </div>
            <div class="section-title">Interior floor</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'interior')">Edit</a>
              <div class="capitalize width-90">100% Aluminium metallic floor with anti-slip tread plate finish</div>
            </div>
            <div class="section-title">Platform</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'smart-specs')">Edit</a>
              <div class="capitalize width-90" v-if="form.smartspecs.basic != ''">
                {{form.smartspecs.basic}} basic users
              </div>
              <div class="capitalize width-90" v-if="form.smartspecs.standard != ''">
                {{form.smartspecs.standard}} standard users
              </div>
              <div class="capitalize width-90" v-if="form.smartspecs.advanced != ''">
                {{form.smartspecs.advanced}} advanced users
              </div>
              <div class="capitalize width-90" v-if="form.smartspecs.premium != ''">
                {{form.smartspecs.premium}} premium users
              </div>
            </div>
            <div class="section-title section-gap">Freight Mobily as a Service</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'fmaas')">Edit</a>
              <div v-if="form.fmaas.short.months > 0" class="capitalize">{{ form.fmaas.short.months }} Short Term</div>
              <div v-if="form.fmaas.medium.months > 0" class="capitalize">{{ form.fmaas.medium.months }} Medium Term</div>
              <div v-if="form.fmaas.long.months > 0" class="capitalize">{{ form.fmaas.long.months }} Long Term</div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-section">
        <button
          :disabled="submitIsDisabled"
          class="btn-secondary"
          @click="$emit('submitForm')"
        >
        <div v-if="requestLoading" class="spinner"></div>
        <span v-else>{{submitButtonText}}</span>
      </button>
      </div>
    </div>
  </div>
  <!-- Mobile designs -->
  <div class="mobile">
    <div class="container">

      <div class="header">Order summary</div>
      <div class="img" :class="form.container.type"></div>

      <div class="header">Container specifications</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'container')">Edit</a>
        <div class="capitalize">{{ form.container.type.slice(0, -1) }}D</div>
        <div>Color: {{ form.exterior.color }}</div>
        <div>Side Decal: {{ form.exterior.logos.logo1.label ? form.exterior.logos.logo1.label : "No graphics applied" }}</div>
        <div>Front Decal: {{ form.exterior.logos.logo2.label ? form.exterior.logos.logo2.label : "No graphics applied" }}</div>
        <div>Door Decal: {{ form.exterior.logos.logo3.label ? form.exterior.logos.logo3.label : "No graphics applied" }}</div>
        <div>Quantity: {{ form.container.quantity }}</div>
      </div>
      <hr>

      <div class="header">Use option</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'container')">Edit</a>
        <div class="capitalize">{{ form.container.purchase }}</div>
        <div v-if="form.container.purchase === 'rent' && form.container.time.rent > 0" class="capitalize">{{ form.container.time.rent }} months</div>
        <div v-if="form.container.purchase === 'lease' && form.container.time.lease > 0" class="capitalize">{{ form.container.time.lease }} months</div>      </div>

      <div class="header">Interior floor</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'interior')">Edit</a>
        <div class="capitalize width-90">100% Aluminium metallic floor with anti-slip tread plate finish</div>
      </div>

      <div class="header">Smart package and plan</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'smart-specs')">Edit</a>
        <div v-if="form.smartspecs.basic > 0" class="capitalize width-90">{{form.smartspecs.basic}} basic users</div>
        <div v-if="form.smartspecs.standard > 0" class="capitalize width-90">{{form.smartspecs.standard}} standard users</div>
        <div v-if="form.smartspecs.advanced > 0" class="capitalize width-90">{{form.smartspecs.advanced}} advanced users</div>
        <div v-if="form.smartspecs.premium > 0" class="capitalize width-90">{{form.smartspecs.premium}} premium users</div>
      </div>

      <div class="header">Freight Monitoring as a Service</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'container')">Edit</a>
        <div v-if="form.fmaas.short.months > 0" class="capitalize">{{ form.fmaas.short.months }} Short Term</div>
        <div v-if="form.fmaas.medium.months > 0" class="capitalize">{{ form.fmaas.medium.months }} Medium Term</div>
        <div v-if="form.fmaas.long.months > 0" class="capitalize">{{ form.fmaas.long.months }} Long Term</div>
      </div>

      <!-- container -->
    </div>
    <div class="form">
      <div class="container">
        <div class="header">Make enquiry</div>
        <hr>
        <div class="header">Enter contact details</div>
        <div>
          <div class="input form-control">
            <input type="text" placeholder="First name (required)" v-model="form.enquiry.firstname" required>
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Last name (required)" v-model="form.enquiry.lastname" required>
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Company (optional)" v-model="form.enquiry.company">
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Country (required)" v-model="form.enquiry.country" required>
          </div>
          <div class="input form-control">
            <input type="email" placeholder="Email address (required)" v-model="form.enquiry.email" required>
          </div>
          <div class="input form-control">
            <input type="phone" placeholder="Phone number (required)" v-model="form.enquiry.phone" required>
          </div>
        </div>
        <div v-if="isMobile" v-html="captcha"></div>
        <div class="navigation">
          <button @click="$emit('changeStep', 'previous')" class="btn-secondary">Previous</button>
          <button @click="$emit('submitForm')" :disabled="submitIsDisabled" class="btn-secondary">
            <div v-if="requestLoading" class="spinner"></div>
            <span v-else>{{submitButtonText}}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- Mobile -->
  </div>
</div>
