<template lang="html" src="./interior.html"></template>
<style lang="scss" scoped src="./_interior.mobile.scss"></style>
<style lang="scss" scoped src="./_interior.desktop.scss"></style>

<script>
import constants from "../../../constants";
import numberUtils from "../../../helpers/numbers";

export default {
  name: "Interior",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      constants: constants.savingsConstants,
      description: {
        optionA: {
          title: "Option A",
          body: {
            type20d: `
              <p>100% Aluminium metallic floor with anti-slip tread plate finish.</p>
              <p>Panel dimensions:</p>
              <p>2pcs 1560mm + 2pcs 1590mm + 2pcs 1680mm + 4pcs 485mm</p>
            `,
            type40d: `
              <p>100% Aluminium metallic floor with anti-slip tread plate finish.</p>
              <p>Standard 40ft floor panel sizes.</p>
              <p>Main section: 2pcs 1380mm + 6pcs 2400mm</p>
              <p>Tunnel section: 4pcs 1620mm</p>
            `,
            type45d: `
              <p>100% Aluminium metallic floor with anti-slip tread plate finish.</p>
              <p>Standard 45ft floor panel sizes.</p>
              <p>Main section: 2pcs 2324mm + 6pcs 2400mm</p>
              <p>Tunnel section: 2pcs 1505mm + 2pcs 2341mm</p>
            `,
            type53d: `
              <p>100% Aluminium metallic floor with anti-slip tread plate finish.</p>
              <p>Panel dimensions</p>
              <p>Rear section: 2pcs 1785mm</p>
              <p>Main section: 2pcs 2318mm + 6pcs 2394mm + 2pcs 1205mm</p>
              <p>Tunnel section: 2pcs 1079mm + 2pcs 2107mm</p>
            `
          }
        },
        optionB: {
          title: "Option B",
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sagittis iaculis lectus, vel congue mauris imperdiet eget. Vestibulum in neque sit amet massa rutrum mattis."
        },
        optionC: {
          title: "Option C",
          body:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sagittis iaculis lectus, vel congue mauris imperdiet eget. Vestibulum in neque sit amet massa rutrum mattis."
        }
      }
    };
  },
  emits: ["changeStep", "valueChange"],
  methods: {
    toFixedDecimals(value, count) {
      return numberUtils.toFixedDecimals(value, count);
    }
  }
};
</script>
