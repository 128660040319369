<div class="footer">
  <!-- Desktop designs -->
  <div class="desktop-flex">
    <div class="transport">
      <button @click="toggleList">
        <div class="wrapper">
          <div class="text-wrapper">
            <div class="header capitalize">{{activeTransport}}</div>
            <div class="desc">Sensitive Cargo</div>
          </div>
          <div class="icon" :class="[{opened: isListActive}]"></div>
        </div>
      </button>
      <div v-if="isListActive" class="transport-list" :class="[{active: isListActive}]">
        <div 
          class="transport-item capitalize"
          v-for="item in transportMethods"
          @click="changeTransport(item)">{{item}}</div>
      </div>
    </div>
    <div class="info">
      <div v-for="item in calculateForm[activeTransport]" class="info-item">
        <div class="header">
          <span v-html="item.prefix"></span>
          {{ toFixedDecimals(item.value, 4) }}
          <span v-html="item.suffix"></span></div>
        <div class="desc" v-html="item.title"></div>
      </div>
      <span class="link" @click="$emit('modal', true);">Personalize performance</span>
    </div>
  </div>
  <!-- Mobile designs -->
  <div class="mobile">
    <div v-if="isListActive" class="transport-list" :class="[{active: isListActive}]">
      <div 
        class="transport-item capitalize"
        v-for="item in transportMethods"
        @click="changeTransport(item)">{{item}}</div>
    </div>
    <div class="container">
      <div class="wrapper">
        <button class="btn-secondary" @click="$emit('modal', true);">Personalize performance</button>
        <button @click="toggleList" class="btn-secondary transport">
          <span class="text-bold capitalize">{{activeTransport}}</span>
          <div class="icon" :class="[{opened: isListActive}]"></div>
        </button>
      </div>
      <div class="info">
        <div v-for="item in calculateForm[activeTransport]" class="info-item">
          <div class="header">
            <span v-html="item.prefix"></span>
            {{ toFixedDecimals(item.value, 4) }}
            <span v-html="item.suffix"></span></div>
          <div class="desc" v-html="item.title"></div>
        </div>
      </div>
      <div class="navigation">
        <button v-if="currentStep !== 'container'" @click="$emit('changeStep', 'previous')"
          class="btn-secondary">Previous</button>
        <button @click="$emit('changeStep', 'next')" class="btn-secondary">Next</button>
      </div>
    </div>
  </div>
</div>