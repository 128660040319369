<!-- Performance section -->
<section class="scroll-snap-item">
  <!-- Performance always displayed info -->
  <div class="main">
    <div class="img">
      <!-- Mobile -->
      <div class="info mobile">
        <div class="info-item">
          <div class="info-item-header">
            <i class="battery-icon icon"></i>
          </div>
          <div class="info-item-desc">
            10-year battery life (coming soon)
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <span class="text-big">24/7</span>
          </div>
          <div class="info-item-desc">
            Connnectivity
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <!-- TODO replace this with icon -->
            <span class="text-big">30tn</span>
          </div>
          <div class="info-item-desc">
            Payload
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              <span class="text-big">30,050</span>kg Payload
            </div>
            <div class="info-item-desc">
              Container<span class="sup">2.0</span> is high-performing all-round including its high payload.
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              <span class="text-big">24/7</span> Connectivity
            </div>
            <div class="info-item-desc">
              Wherever Container<span class="sup">2.0</span> is in the world, it is constantly connected 24/7, sea, road
              or rail
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header with-icon">
              <i class="battery-icon icon"></i>
              <span class="text-big">10-year</span> battery life
            </div>
            <div class="info-item-desc">
              A high-capacity lightweight battery with a long life of up to 10 years. Far longer than any other
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Performance</h5>
        <h3>
          Best-in-class, <br class="mobile"> wherever it goes
        </h3>
        <p>
          When a container is built to last, it not only is more cost efficient, but it is far more sustainable. It also requires
          far less maintenance, therefore reducing the operation costs, as well as the impact of transport
          and logistics industry on the environment.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Performance learn more sub-sections -->
  <div id="performance-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-1">
      <div class="header">
        <h3>Designed for the best performance, all-round</h3>
      </div>
      <div class="img">
        <div class="img-wrapper">
          <div class="img-text">
            <span class="text-bold">
              Container<span class="sup">2.0</span>
            </span>
            Type45D
          </div>
        </div>
      </div>
      <div class="subsection-info container">
        <div class="grid">
          <div class="grid-item">
            <div class="value">89.2 m3*</div>
            <div class="desc">Capacity</div>
            <div class="explanation">* Same as the industry standard</div>
          </div>
          <div class="grid-item">
            <div class="value">30050kg</div>
            <div class="desc">Payload
              <div class="explanation">(Best in class)</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="value">3950kg</div>
            <div class="desc">Tare weight
              <div class="explanation mobile">(Best in Class)</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="value">34000kg</div>
            <div class="desc">Gross weight</div>
            <div class="explanation">(Heavy Duty Standard)</div>
          </div>
          <div class="grid-item">
            <div class="value">24/7</div>
            <div class="desc">Connectivity</div>
          </div>
          <div class="grid-item">
            <div class="value">10-year*</div>
            <div class="desc">Battery life
              <div class="explanation desktop">(Coming soon)</div>
              <div class="explanation"> <span class="mobile">(Coming soon)</span><span>*Without need for recharging</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subsection-2">
      <div class="header">
        <h3>High-performing, through and through</h3>
      </div>
      <div class="img first"></div>
      <div class="subsection-info container">
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currSlide === 'first'}" @click="changeSlide('first')">
              <h3>Hygienic food-grade</h3>
              <p>Pesticide and pest-free, clean and naturally antibacterial the Container<span class="sup">2.0</span> is
                ready to transport
                edibles
                without any further treatment, the only such intermodal container in its class.</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'second'}" @click="changeSlide('second')">
              <h3>Maintenance</h3>
              <p>With no wooden floor, there’s no need for chemical treatment or the refurbishment of deteriorating
                linings making maintenance simple.</p>
            </div>
          </div>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
