<template lang="html" src="./field-of-application.section.html"></template>
<style
  lang="scss"
  scoped
  src="./_field-of-application.section.mobile.scss"
></style>
<style
  lang="scss"
  scoped
  src="./_field-of-application.section.desktop.scss"
></style>

<script>
export default {
  name: "FieldOfApplicationSection",
  props: ["isActive", "isLearnMoreActive"],
  data() {
    return {
      currSlide: "first"
    };
  },
  methods: {
    openLearnMore() {
      if (!this.isLearnMoreActive) {
        this.$emit("learn-more", "field-of-application");
      }
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    },
    changeSlide(slide) {
      const slideImg = document.querySelector(".subsection-2 > .img");
      slideImg.classList = `img ${slide}`;
      this.currSlide = slide;
    }
  }
};
</script>
