<template lang="html" src="./sidenav.html"></template>
<style lang="scss" scoped src="./_sidenav.mobile.scss"></style>
<style lang="scss" scoped src="./_sidenav.desktop.scss"></style>

<script>
export default {
  name: "SidenavComponent",
  props: ["currentActive", "isWhite", "typeName"],
  data() {
    return {
      sectionMargin: 150
    };
  }
};
</script>
