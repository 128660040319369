<template lang="html" src="./chat.html"></template>
<style lang="scss" scoped src="./_chat.mobile.scss"></style>
<style lang="scss" scoped src="./_chat.desktop.scss"></style>

<script>
import chatUtils from "../../helpers/chat";

export default {
  name: "ChatComponent",
  props: ["inverted"],
  mounted() {
    window.zE("webWidget:on", "close", () => {
      window.zE("webWidget", "hide");
    });
  },
  methods: {
    openChat() {
      chatUtils.openChat();
    }
  }
};
</script>
