<template>
  <router-view />
  <div class="cookie-notice" v-if="showCookies">
    <div class="cookie-info">
      <span
        >This website uses cookies. By continuing to use this website, you agree
        to the use of cookies. More info
      </span>
      <a href="/privacy-policy">
        here
      </a>
    </div>
    <div class="cookie-button" @click="acceptCookies()">&#215;</div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-notice {
  z-index: 1000000;
  display: flex;
  font-size: 13px;
  position: fixed;
  color: white;
  background-color: black;
  width: 100vw;
  height: 85px;
  bottom: 0px;
  padding: 20px;

  @media only screen and (min-width: $large) {
    height: 70px;
    border-radius: 20px;
    width: 410px;
    bottom: 15px;
    right: 10px;
  }
}

.cookie-info {
  margin-right: 20px;

  a {
    color: white !important;
  }
}

.cookie-button {
  color: white;
  appearance: none;
  border: none;
  cursor: pointer;
  color: #fff !important;
  font-size: 23px;
  display: block;
}
</style>

<script>
import "animate.css";
export default {
  name: "App",
  data() {
    return {
      showCookies: true
    };
  },
  mounted() {
    if (localStorage.getItem("accept-cookies")) {
      this.showCookies = false;
    }
  },
  methods: {
    acceptCookies() {
      this.showCookies = false;
      localStorage.setItem("accept-cookies", "true");
    }
  }
};
</script>
