<template lang="html" src="./navbar.html"></template>
<style lang="scss" scoped src="./_navbar.mobile.scss"></style>
<style lang="scss" scoped src="./_navbar.desktop.scss"></style>

<script>
export default {
  name: "Navbar",
  props: {
    formSteps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: String,
      required: true
    }
  },
  emits: ["changeStep"],
  methods: {
    getCurrentIndex() {
      return this.formSteps.findIndex(step => step.id === this.currentStep) + 1;
    }
  }
};
</script>
