<template lang="html" src="./footer.html"></template>
<style lang="scss" scoped src="./_footer.mobile.scss"></style>
<style lang="scss" scoped src="./_footer.desktop.scss"></style>

<script>
export default {
  name: "FooterComponent",
  props: {
    scrollSnap: {
      type: Boolean,
      required: true
    }
  }
};
</script>
