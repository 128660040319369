export default {
  toFixedDecimals(value, count) {
    const number = value.toFixed(count);
    const [integer, decimals] = number.split(".");
    const commaInteger = integer
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const maxLength = Math.max(5 - integer.length, 0);
    const delimiter = maxLength ? "." : "";
    return commaInteger + delimiter + decimals.slice(0, maxLength);
  }
};
