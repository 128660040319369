<template lang="html" src="./connectivity.section.html"></template>
<style lang="scss" scoped src="./_connectivity.section.mobile.scss"></style>
<style lang="scss" scoped src="./_connectivity.section.desktop.scss"></style>

<script>
export default {
  name: "ConnectivitySection",
  props: ["isActive", "isLearnMoreActive"],
  data() {
    return {
      currSlide: "first"
    };
  },
  methods: {
    openLearnMore() {
      this.$emit("learn-more", "connectivity");
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    },
    changeSlide(slide) {
      this.currSlide = slide;
    }
  }
};
</script>
