<div>
  <!-- Info modal -->
  <div class="modal" v-show="showModal">
    <div class="modal-bg" @click="toggleModal()"></div>
    <div class="smart-specs-modal">
      <!-- Close button -->
      <div class="close-modal" @click="toggleModal()">
        <div class="cross-1"></div>
        <div class="cross-2"></div>
      </div>

      <div class="spec-tabs container">
        <div class="horizontal-wrapper">
          <div class="device-specs-horizontal-info">
            <div class="horizontal-info ">
              <div class="horizontal-item" :class="{ active: currSlide === 'first'}" @click="changeSlide('first')">
                <i class="container-icon icon"></i>
                <h3>Container<span class="sup">1.5</span></h3>
              </div>
              <div class="horizontal-item" :class="{ active: currSlide === 'second'}" @click="changeSlide('second')">
                <i class="doubleL-icon icon"></i>
                <h3>DoubleL</h3>
              </div>
              <div class="horizontal-item" :class="{ active: currSlide === 'third'}" @click="changeSlide('third')">
                <i class="flex-icon icon"></i>
                <h3>Flex</h3>
              </div>
              <div class="horizontal-item" :class="{ active: currSlide === 'fourth'}" @click="changeSlide('fourth')">
                <i class="coin-icon icon"></i>
                <h3>Coin</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="specs device-specs">
          <div class="spec-item-header desktop">
            <div class="note">Smart devices</div>
            <div v-if="currSlide === 'first'">Container<span class="sup">1.5</span></div>
            <div v-if="currSlide === 'second'">Double L</div>
            <div v-if="currSlide === 'third'">Flex</div>
            <div v-if="currSlide === 'fourth'">Coin</div>
          </div>
          <div class="desktop">
            <div v-if="currSlide === 'first'">
              <div  v-for="spec in containerInfo" class="spec-item">
                <div class="spec-item-title" v-html="spec.title"></div>
                <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
              </div>
              <div v-for="application in containerApplication" class="spec-item">
                <div class="spec-item-title" v-html="application.title"></div>
                <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
              </div>
            </div>
            <div v-if="currSlide === 'second'">
              <div  v-for="spec in doubleLInfo" class="spec-item">
                <div class="spec-item-title" v-html="spec.title"></div>
                <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
              </div>
              <div v-for="application in doubleLApplication" class="spec-item">
                <div class="spec-item-title" v-html="application.title"></div>
                <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
              </div>
            </div>
            <div v-if="currSlide === 'third'">
              <div  v-for="spec in flexInfo" class="spec-item">
                <div class="spec-item-title" v-html="spec.title"></div>
                <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
              </div>
              <div v-for="application in flexApplication" class="spec-item">
                <div class="spec-item-title" v-html="application.title"></div>
                <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
              </div>
            </div>
            <div v-if="currSlide === 'fourth'">
              <div  v-for="spec in coinInfo" class="spec-item">
                <div class="spec-item-title" v-html="spec.title"></div>
                <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
              </div>
              <div v-for="application in coinApplication" class="spec-item">
                <div class="spec-item-title" v-html="application.title"></div>
                <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="img" :class="currSlide"></div>
        <div v-if="currSlide === 'first'" class="specs">
          <div v-for="spec in containerSpecs" class="spec-item">
            <div class="spec-item-title" v-html="spec.title"></div>
            <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
          </div>
          <div class="spec-actions">
            <div class="spec-item-header">
              <a class="download-wrapper" href="Container1.5 Specs.pdf" download>
                <i class="download-icon"></i>
                Download specs
              </a>
            </div>
          </div>
        </div>
        <div v-if="currSlide === 'second'" class="specs">
          <div v-for="spec in doubleLSpecs" class="spec-item">
            <div class="spec-item-title" v-html="spec.title"></div>
            <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
          </div>
          <div class="spec-actions">
            <div class="spec-item-header">
              <a class="download-wrapper" href="DoubleL Specs.pdf" download>
                <i class="download-icon"></i>
                Download specs
              </a>
            </div>
          </div>
        </div>
        <div v-if="currSlide === 'third'" class="specs">
          <div v-for="spec in doubleLSpecs" class="spec-item">
            <div class="spec-item-title" v-html="spec.title"></div>
            <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
          </div>
          <div class="spec-actions">
            <div class="spec-item-header">
              <a class="download-wrapper" href="Flex Specs.pdf" download>
                <i class="download-icon"></i>
                Download specs
              </a>
            </div>
          </div>
        </div>
        <div v-if="currSlide === 'fourth'" class="specs">
          <div v-for="spec in coinSpecs" class="spec-item">
            <div class="spec-item-title" v-html="spec.title"></div>
            <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
          </div>
          <div class="spec-actions">
            <div class="spec-item-header">
              <a class="download-wrapper" href="Coin Specs.pdf" download>
                <i class="download-icon"></i>
                Download specs
              </a>
            </div>
          </div>
        </div>     
      </div>
      
    </div>
  </div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">

        <div class="model-info-wrapper">
          <div class="model-devices">
            <div class="img-wrapper">
              <div class="img device-container"></div>
              <div class="img-text container-text">Container<span class="sup">1.5</span></div>
            </div> 
            <div class="img-wrapper">
              <div class="img device-doublel"></div>
              <div class="img-text">DoubleL</div>
            </div>
            <div class="img-wrapper">
              <div class="img device-flex"></div>
              <div class="img-text">Flex</div>
            </div>
            <div class="img-wrapper">
              <div class="img device-coin"></div>
              <div class="img-text">Coin</div>
            </div>           
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="section type">
          <div class="header">Select your Smart Devices</div>
          <div class="desc">Choose one or more for your supply chain.<br/>Click "Next" for SupplyChain<span class="sup">4.0</span> Platform only.</div>
          <div class="desc desc-note"><b>Note:</b> There is zero initial investment needed</div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.containerP.quantity > 0 || showContainerPPopup }">
                  <div class="header">Container<span class="sup">1.5</span> P: Primary battery</div>
                  <div class="desc">Best for businesses that need an invisible “install and forget” solution for their containers. Up to 10 year battery life without recharging.</div>
                  <div class="data-collect">
                    <span @mouseover="openContainerPPopup" @mouseleave="closeContainerPPopup" class="data-collect__text">
                      Can collect a variety of data in real time.
                      <span class="data-collect__icon">	&#63;</span>
                    </span>
                    
                    <div v-if="showContainerPPopup" class="data-collect__popup">
                      A variety of data can be collected such as, door open and locked status, temperature, humidity, light, shock, location, tilt and motion.
                    </div>
                  </div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.containerP.quantity" placeholder="No of units" required>
              </div>              
            </div>
          </div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.containerS.quantity > 0 || showContainerSPopup }">
                  <div class="header">Container<span class="sup">1.5</span> S: Secondary battery</div>
                  <div class="desc">Best for businesses that need an invisible solution for their containers with a rechargable long life battery.</div>
                  <div class="data-collect">
                    <span @mouseover="openContainerSPopup" @mouseleave="closeContainerSPopup" class="data-collect__text">
                      Can collect a variety of data in real time.
                      <span class="data-collect__icon">	&#63;</span>
                    </span>
                    <div v-if="showContainerSPopup" class="data-collect__popup">
                      A variety of data can be collected such as, door open and locked status, temperature, humidity, light, shock, location, tilt and motion.
                    </div>
                  </div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.containerS.quantity" placeholder="No of units" required>
              </div>
            </div>
          </div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.doubleL.quantity > 0 || showDoubleLPopup }">
                  <div class="header">DoubleL</div>
                  <div class="desc">Best for businesses that need a portable, rechargeable, multiple-use solution with long life battery.</div>
                  <div class="data-collect">
                    <span @mouseover="openDoubleLPopup" @mouseleave="closeDoubleLPopup" class="data-collect__text">
                      Can collect a variety of data in real time.
                      <span class="data-collect__icon">	&#63;</span>
                    </span>
                    <div v-if="showDoubleLPopup" class="data-collect__popup">
                      A variety of data can be collected such as, door open and locked status, temperature, humidity, light, shock, location, tilt and motion.
                    </div>
                  </div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.doubleL.quantity" placeholder="No of units" required>
              </div>
            </div>
          </div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.flexM.quantity > 0 || showFlexMPopup }">
                  <div class="header">Flex Multi-Use</div>
                  <div class="desc">Best for businesses that need a small, portable, and rechargable, multiple-use solution.</div>
                  <div class="data-collect">
                    <span @mouseover="openFlexMPopup" @mouseleave="closeFlexMPopup" class="data-collect__text">
                      Can collect a variety of data in real time.
                      <span class="data-collect__icon">	&#63;</span>
                    </span>
                    <div v-if="showFlexMPopup" class="data-collect__popup">
                      A variety of data can be collected such as, door open and locked status, temperature, humidity, light, shock, location, tilt and motion.
                    </div>
                  </div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.flexM.quantity" placeholder="No of units" required>
              </div>
            </div>
          </div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.flexS.quantity > 0 || showFlexSPopup }">
                  <div class="header">Flex Single-Use</div>
                  <div class="desc">Best for businesses that need a low-cost, small, portable, and customisable single-use solution.</div>
                  <div class="data-collect">
                    <span @mouseover="openFlexSPopup" @mouseleave="closeFlexSPopup" class="data-collect__text">
                      Can collect a variety of data in real time.
                      <span class="data-collect__icon">	&#63;</span>
                    </span>
                    <div v-if="showFlexSPopup" class="data-collect__popup">
                      A variety of data can be collected such as, door open and locked status, temperature, humidity, light, shock, location, tilt and motion.
                    </div>
                  </div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.flexS.quantity" placeholder="No of units" required>
              </div>
            </div>
          </div>
          <div class="form-control">
            <div class="form-container">
              <div class="form-buttons">
                <label :class="{ active: form.devices.coin.quantity > 0 }">
                  <div class="header">Coin</div>
                  <div class="desc">Best for businesses that need a very low-cost, multiple-use solution, to record temperature, humidity and light.</div>
                </label>
              </div>
              <div class="input form-control">
                <input min="1" type="number" v-model="form.devices.coin.quantity" placeholder="No of units" required>
              </div>
            </div>
          </div>
          <div class="form-control">
            <button class="btn btn-primary" @click="toggleModal()">Learn more</button>
          </div>
        </div>
      </div>
      <div class="button-section">
        <button @click="$emit('changeStep', 'fmaas')" class="btn-secondary">Next</button>
      </div>
    </div>
  </div>

  <!-- Mobile design -->
  <div class="mobile">
    <div class="container">
      <div class="header">Select type</div>
      <div class="img"></div>
      <div class="option-item">
        <div class="form-control">
          <span>CONTAINER<span class="sup">1.5</span> P</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.containerP.quantity" placeholder="No of units" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>CONTAINER<span class="sup">1.5</span> S</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.containerS.quantity" placeholder="No of units" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>DOUBLEL</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.doubleL.quantity" placeholder="No of units" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>FLEX Multi Use</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.flexM.quantity" placeholder="No of units" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>FLEX Single Use</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.flexS.quantity" placeholder="No of units" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>COIN</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.devices.coin.quantity" placeholder="No of units" required>
        </div>
      </div>

      <div v-for="option in package">
        <div class="description">
          <div class="description-title">
            {{ option.title }}
          </div>
          <div class="description-body">
            {{ option.body }}
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</div>