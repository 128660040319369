<template lang="html" src="./tech-specs.section.html"></template>
<style lang="scss" scoped src="./_tech-specs.section.mobile.scss"></style>
<style lang="scss" scoped src="./_tech-specs.section.desktop.scss"></style>

<script>
export default {
  name: "TechSpecsSection",
  data() {
    return {
      specs: [
        {
          title: "Max Payload",
          info: ["57,960 lbs"]
        },
        {
          title: "Max Gross Weight",
          info: ["67,200 lbs"]
        },
        {
          title: "Tare Weight",
          info: ["9,237 lbs"]
        },
        {
          title: "Capacity",
          info: ["3,987 cu ft"]
        },
        {
          title: "Floor",
          info: ["Metallic"]
        },
        {
          title: "Additional Cargo",
          info: ["Up to 2,205 lbs"]
        },
        {
          title: "CO<span class='sub'>2</span> reduction/1000km",
          info: ["Up to 33,100 gCO<span class='sub'>2</span>*"]
        },
        {
          title: "Fuel saving/1000km",
          info: ["Up to 12.8lt*"]
        },
        {
          title: "CO<span class='sub'>2</span> offset/ year",
          info: ["432.8k gCO<span class='sub'>2</span>"]
        },
        {
          title: "Battery life",
          info: ["<div><span>Up to 10 years</span></div>"]
        },
        {
          title: "Visibility",
          info: ["24/7"]
        },
        {
          title: "Alarms and Notifications",
          info: ["According to your requirements"]
        },
        {
          title: "Software Updates",
          info: ["Available over the air"]
        },
        {
          title: "M2M communication",
          info: [
            "<div><span>Container2Container</span></div><div><span>Container2Cargo</span></div><div><span>Container2(Truck, Trailer and other)</span></div>"
          ]
        }
      ]
    };
  }
};
</script>
