<section class="scroll-snap-item">
  <div class="main">

    <div class="header mobile">Tech specs</div>

    <div class="wrapper">
      <div class="img">
        <div class="img-wrapper">
          <div class="img-text">
            <span class="text-bold">
              Container<span class="sup">2.0</span>
            </span>
            Type53D
          </div>
        </div>
      </div>

      <div class="specs">
        <div class="spec-item-header desktop">
          Tech specs
          <div class="note">*Based on 1212lbs weight saving</div>
        </div>
        <div class="spec-item-header desktop">
          <a class="download-wrapper" href="Container2.0Type53D Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div>
        <div v-for="spec in specs" class="spec-item">
          <div class="spec-item-title" v-html="spec.title"></div>
          <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
        </div>
      </div>

      <div class="note mobile">*Based on 1212lbs weight saving</div>

      <div class="download mobile">
        <a class="download-wrapper" href="Container2.0Type53D Specs.pdf" download>
          <i class="download-icon"></i>
          Download specs
        </a>
      </div>
    </div>

  </div>
</section>
