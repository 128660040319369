<!-- Connectivity section -->
<section class="scroll-snap-item">
  <!-- Connectivity always displayed info -->
  <div class="main">
    <div class="img">
      <video autoplay muted loop playsinline class="video-hero">
        <source src="../../../../assets/media/videos/supply-chain/connectivity-hero.mp4" type="video/mp4" />
      </video>
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header">
            <i class="mesh-icon icon"></i>
          </div>
          <div class="info-item-desc">
            M2M communication
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <i class="explanation-icon icon"></i>
          </div>
          <div class="info-item-desc">
            Global tracking
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <i class="wifi-icon icon"></i>
          </div>
          <div class="info-item-desc">
            Over-the-air updates
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              <i class="mesh-icon icon"></i>
              M2M communication
            </div>
            <div class="info-item-desc">
              Keeping you constantly and consistently connected 24/7
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              <i class="location-icon icon"></i>
              Global tracking
            </div>
            <div class="info-item-desc">
              Fully customisable alert parameters for multi-platform and globally accessible insights
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              <i class="wifi-icon icon"></i>
              Over-the-air updates
            </div>
            <div class="info-item-desc">
              Over-the-air software updates introduce new features, functionality and performance
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Connectivity</h5>
        <h3>
          Fully visible <br class="mobile"> supply chain
        </h3>
        <p>
          By making all of your logistic units visible and connected 24/7, efficiency, productivity
          and sustainability are drastically improved, financial returns are increased and new
          revenue streams are created.
        </p>
      </div>
    </div>
  </div>
</section>
