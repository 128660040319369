<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">

        <div class="model-info-wrapper" :class="form.container.type">
          <div class="model-info">
            <div class="model-info-item">
              <div class="header">{{ payloads[form.container.type] }}</div>
              <div class="desc">payload</div>
            </div>
            <div class="divider">|</div>
            <div class="model-info-item">
              <div class="header">100%</div>
              <div class="desc">recyclable</div>
            </div>
            <div class="divider">|</div>
            <div class="model-info-item">
              <div class="header">24/7</div>
              <div class="desc">connectivity</div>
            </div>
            <div class="divider">|</div>
            <div class="model-info-item">
              <div class="header">10-year</div>
              <div class="desc">battery life</div>
              <div class="desc">(coming soon)</div>
            </div>
          </div>
        </div>

      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="section type">
          <div class="header">Select type</div>
          <div class="form-control">
            <input type="radio" id="type20" name="type" value="type20d" v-model="form.container.type">
            <label :class="{active: form.container.type === 'type20d' }" for="type20">Type 20D</label>
          </div>
          <div class="form-control">
            <input type="radio" id="type40" name="type" value="type40d" v-model="form.container.type">
            <label :class="{active: form.container.type === 'type40d' }" for="type40">Type 40D</label>
          </div>
          <div class="form-control">
            <input type="radio" id="type45" name="type" value="type45d" v-model="form.container.type">
            <label :class="{active: form.container.type === 'type45d' }" for="type45">Type 45D</label>
          </div>
          <div class="form-control">
            <input type="radio" id="type53" name="type" value="type53d" v-model="form.container.type">
            <label :class="{active: form.container.type === 'type53d' }" for="type53">Type 53D</label>
          </div>
        </div>
  
        <div class="section quantity">
          <div class="header">Quantity</div>
          <div class="input form-control">
            <input min="1" type="number" placeholder="Enter quantity of units" v-model="form.container.quantity">
          </div>
        </div>
  
        <div class="section purchase">
          <div class="header">Use option</div>
          <div class="form-control">
            <div>
              <input type="radio" id="buy" name="purchase" value="buy" v-model="form.container.purchase">
              <label :class="{active: form.container.purchase === 'buy' }" for="buy">Buy</label>
            </div>
          </div>
          <div class="form-control">
            <div>
              <input type="radio" id="lease" name="purchase" value="lease" v-model="form.container.purchase">
              <label :class="{active: form.container.purchase === 'lease' }" for="lease">Lease</label>
            </div>
            <div class="input form-control">
              <input min="1" type="number" v-model="form.container.time.lease" placeholder="Months needed" required>
            </div>
          </div>
          <div class="form-control">
            <div>
              <input type="radio" id="rent" name="purchase" value="rent" v-model="form.container.purchase">
              <label :class="{active: form.container.purchase === 'rent' }" for="rent">Rent</label>
            </div>
            <div class="input form-control">
              <input min="1" type="number" v-model="form.container.time.rent" placeholder="Months needed" required>
            </div>
          </div>
        </div>
      </div>

      <div class="button-section">
        <button @click="$emit('changeStep', 'exterior')" class="btn-secondary">Next</button>
      </div>

    </div>
  </div>

  <!-- Mobile design -->
  <div class="mobile">
    <div class="container">

      <div class="header">Select type</div>
      <div class="img" :class="form.container.type"></div>
      <div class="form-group">
        <div class="form-control">
          <input type="radio" id="type20m" name="typem" value="type20d" v-model="form.container.type">
          <label for="type20m">Type 20D</label>
        </div>
        <div class="form-control">
          <input type="radio" id="type40m" name="typem" value="type40d" v-model="form.container.type">
          <label for="type40m">Type 40D</label>
        </div>
        <div class="form-control">
          <input type="radio" id="type45m" name="typem" value="type45d" v-model="form.container.type">
          <label for="type45m">Type 45D</label>
        </div>
        <div class="form-control">
          <input type="radio" id="type53m" name="typem" value="type53d" v-model="form.container.type">
          <label for="type53m">Type 53D</label>
        </div>
      </div>

      <div class="header">Quantity</div>
      <div class="input form-control">
        <input min="1" type="number" placeholder="Enter quantity of units" v-model="form.container.quantity">
      </div>

      <div class="header">Use option</div>
      <div class="form-group">
        <div class="form-control">
          <input type="radio" id="m-buy" name="m-purchase" value="buy" v-model="form.container.purchase">
          <label for="m-buy">Buy</label>
        </div>
        <div class="form-control">
          <input type="radio" id="m-lease" name="m-purchase" value="lease" v-model="form.container.purchase">
          <label for="m-lease">Lease</label>
        </div>
        <div class="form-control">
          <input type="radio" id="m-rent" name="m-purchase" value="rent" v-model="form.container.purchase">
          <label for="m-rent">Rent</label>
        </div>
      </div>
      <div class="input form-control" v-if="form.container.purchase === 'rent'">
        <input min="1" type="number" placeholder="Enter months needed" v-model="form.container.time.rent">
      </div>
      <div class="input form-control" v-if="form.container.purchase == 'lease'">
        <input min="1" type="number" placeholder="Enter months needed" v-model="form.container.time.lease">
      </div>
    </div>
    <slot></slot>
  </div>

</div>