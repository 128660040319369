<template lang="html" src="./tech-specs.section.html"></template>
<style lang="scss" scoped src="./_tech-specs.section.mobile.scss"></style>
<style lang="scss" scoped src="./_tech-specs.section.desktop.scss"></style>

<script>
export default {
  name: "TechSpecsSection",
  data() {
    return {
      specs: [
        {
          title: "Max Payload",
          info: ["28,500 kg"]
        },
        {
          title: "Max Gross Weight",
          info: ["30,480 kg"]
        },
        {
          title: "Tare Weight",
          info: ["1,980 kg"]
        },
        {
          title: "Capacity",
          info: ["33.2 m3"]
        },
        {
          title: "Floor",
          info: ["Metallic"]
        },
        {
          title: "Additional Cargo",
          info: ["Up to 400kg"]
        },
        {
          title: "CO<span class='sub'>2</span> reduction/1000km",
          info: ["Up to 14,450 gCO<span class='sub'>2</span>*"]
        },
        {
          title: "Fuel saving/1000km",
          info: ["Up to 5.6lt*"]
        },
        {
          title: "CO<span class='sub'>2</span> offset/ year",
          info: ["166k gCO<span class='sub'>2</span>"]
        },
        {
          title: "Battery life",
          info: ["<div><span>Up to 10 years</span></div>"]
        },
        {
          title: "Visibility",
          info: ["24/7"]
        },
        {
          title: "Alarms and Notifications",
          info: ["According to your requirements"]
        },
        {
          title: "Software Updates",
          info: ["Available over the air"]
        },
        {
          title: "M2M communication",
          info: [
            "<div><span>Container2Container</span></div><div><span>Container2Cargo</span></div><div><span>Container2(Truck, Trailer and other)</span></div>"
          ]
        }
      ]
    };
  }
};
</script>
