<!-- Analytics section -->
<section class="scroll-snap-item">
  <!-- Analytics always displayed info -->
  <div class="main">
    <div class="img">
      <video autoplay muted loop playsinline class="video-hero">
        <source src="../../../../assets/media/videos/supply-chain/analytics-hero.mp4" type="video/mp4" />
      </video>
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header">
            Clear data reporting
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Detailed quality control
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Sustainability KPIs
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              Clear data reporting
            </div>
            <div class="info-item-desc">
              Improve a company's total performance
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              Detailed quality control
            </div>
            <div class="info-item-desc">
              Optimise asset use - improve financial return
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              Sustainability KPIs
            </div>
            <div class="info-item-desc">
              Measure your eco-footprint and optimise your sustainability performance
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Analytics</h5>
        <h3>
          Record more. Do more.
        </h3>
        <p>
          When it comes to logistics operations, more questions can be asked and, most importantly, 
          more questions can be answered by means of employing all possible data collection opportunities. 
          Deeper analysis provides a separate repository of collected data, specifically designed around a 
          specific logistics operation. This repository allows for deeper querying of huge amounts of data 
          which would otherwise be unachievable, especially if only real-time monitoring was used.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Analytics learn more sub-sections -->
  <div id="analytics-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-1">
      <div class="header">
        <h3>Customise your analytics for every operation and allow 
          purposeful and personalised improvements</h3>
          <p>The variety of goods requires different handling methods during their transport. 
            For example, pharmaceutical and edible goods are highly temperature-sensitive 
            whereas scientific machinery may be very sensitive to handling. Keeping this in mind, 
            we personalise the collection and analysis of data aiming to render the optimal outcome
            for all types of shipments.</p>
      </div>
      
      <div class="subsection-info container">
        <div class="grid">
          <div class="grid-item">
            <div class="icon">
              <i class="temperature-icon"></i>
            </div>
            <div class="info">
              <div class="value">Temperature</div>
              <div class="desc">Understand patterns of temperature changes for sensitive products</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="tampering-icon"></i>
            </div>
            <div class="info">
              <div class="value">Tampering</div>
              <div class="desc">Frequent tampering of goods containers in particular locations highlight the need for a new route</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="route-icon"></i>
            </div>
            <div class="info">
              <div class="value">Route</div>
              <div class="desc">Viewing current road conditions allows for efficient route planning, reducing frequent delays</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="disturbance-icon"></i>
            </div>
            <div class="info">
              <div class="value">Disturbance</div>
              <div class="desc">Repeated shock or vibration events highlight unsuitable logistic routes for goods sensitive to rough handling</div>
            </div>
            
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="dwell-time-icon"></i>
            </div>
            <div class="info">
              <div class="value">Dwell time</div>
              <div class="desc">Reoccurring long periods of dwell time indicate inefficient routes or logistic operator</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="location-icon"></i>
            </div>
            <div class="info">
              <div class="value">Location</div>
              <div class="desc">Knowing an assets location optimises its management, reducing fuel consumption, CO<span class="sub">2</span> emissions and idle times</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="humidity-icon"></i>
            </div>
            <div class="info">
              <div class="value">Humidity</div>
              <div class="desc">Understand patterns of humidity changes for sensitive products</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="journey-certificate-icon"></i>
            </div>
            <div class="info">
              <div class="value">Journey Certificate</div>
              <div class="desc">Proof of transport on right conditions</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="icon">
              <i class="tilt-icon"></i>
            </div>
            <div class="info">
              <div class="value">Tilt</div>
              <div class="desc">Tilt detection highlights unsuitable routes for cargo sensitive to rough handling</div>
            </div>
          </div>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
