<div class="navbar">
  <router-link to="/" class="logo">Supply Chain<span class="sup">4.0</span></router-link>
  <div class="step-indicator mobile">{{getCurrentIndex()}}/5</div>
  <div class="nav-list desktop-flex">
    <div
      v-for="formStep in formSteps"
      :key="formStep.id"
      @click="$emit('changeStep', formStep.id)"
      :class="{
        'active': currentStep === formStep.id
      }"
      class="nav-list-item"
      v-html="formStep.label"
    >
    </div>
    
  </div>
</div>