<template lang="html" src="./enquiry.html"></template>
<style lang="scss" scoped src="./_enquiry.mobile.scss"></style>
<style lang="scss" scoped src="./_enquiry.desktop.scss"></style>

<script>
export default {
  name: "Enquiry",
  props: {
    form: {
      type: Object,
      required: true
    },
    requestSent: {
      type: Boolean,
      required: true
    },
    requestLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      widgetId: 0,
      captcha: `<div class="g-recaptcha" id="recaptcha" data-sitekey="siteKey"></div>`
    };
  },
  mounted() {
    if (window.grecaptcha) {
      this.widgetId = window.grecaptcha.render("recaptcha", {
        sitekey: this.siteKey,
        callback: response => {
          const newForm = this.form;
          newForm.enquiry.recaptcha = response;
          this.$emit("valueChange", newForm);
        },
        "expired-callback": () => {
          const newForm = this.form;
          newForm.enquiry.recaptcha = "";
          this.$emit("valueChange", newForm);
        },
        "error-callback": error => {
          console.error("reCAPTCHA error:", error);
        }
      });
    }
  },
  computed: {
    submitIsDisabled() {
      const data = this.form.enquiry;
      return (
        !data.firstname ||
        !data.lastname ||
        !data.country ||
        !data.email ||
        !data.phone ||
        !data.recaptcha ||
        this.requestLoading ||
        this.requestSent
      );
    },
    submitButtonText() {
      if (this.requestSent) {
        return "Request sent";
      }
      return "Request quote";
    },
    isMobile() {
      return window.innerWidth < 1000;
    }
  },
  emits: ["changeStep", "valueChange", "submitForm"]
};
</script>
