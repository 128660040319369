<!-- Exterior-Interior section -->
<section class="scroll-snap-item">
  <!-- Exterior-Interior always displayed info -->
  <div class="main">
    <div class="img">
      <!-- Mobile -->
      <div class="info mobile">
        <div class="info-item">
          <div class="info-item-header">
            <span class="text-big">30,480</span>kg
          </div>
          <div class="info-item-desc">
            Max gross weight
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <span class="text-big">33.2</span>m3
          </div>
          <div class="info-item-desc">
            Internal capacity
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <span class="text-normal">(±2%)</span> <span class="text-big">1,980</span>kg
          </div>
          <div class="info-item-desc">
            Tare weight
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              <span class="text-big">30,480</span>kg <span class="text-normal">(67,200 lb)</span>
            </div>
            <div class="info-item-desc">
              Max gross weight
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              <span class="text-big">33.2</span>m3 <span class="text-normal">(1,173 cu ft)</span>
            </div>
            <div class="info-item-desc">
              Internal capacity
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              <span class="text-normal">(±2%)</span> <span class="text-big">1,980</span>kg <span class="text-normal">(4,365lb)</span>
            </div>
            <div class="info-item-desc">
              Tare weight
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Exterior/Interior</h5>
        <h3>
          Resourcefully designed, <br class="mobile"> inside and out
        </h3>
        <p>
          The Container<span class="sup">2.0</span> has been carefully designed to achieve the optimum performance while
          adhering to standard requirements of intermodal containers. It saves on resources needed for its construction
          as well as its use, all whilst yielding large scale added value.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Design learn more sub-sections -->
  <div id="exterior-interior-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2">
      <div class="header">
        <h3>Interior/exterior dimensions</h3>
      </div>
      <div class="img" :class="currSlide">
        <div class="img-wrapper">
          <div class="dimension-y desktop">
            <div v-if="currSlide === 'first'" class="text">2,280 mm (7’ 5 49/64”)</div>
            <div v-if="currSlide === 'second'" class="text">2,392 mm (7’ 10 11/64”)</div>
            <div v-if="currSlide === 'third'" class="text">2,591 mm (8’ 6”)</div>
            <div class="indicator temporarily-hidden"></div>
          </div>
          <div class="dimension-x desktop">
            <div v-if="currSlide === 'first'" class="text">2,340 mm (7’ 8 1/8”)</div>
            <div v-if="currSlide === 'second'" class="text">2,352 mm (7’ 8 19/32”)</div>
            <div v-if="currSlide === 'third'" class="text">2,438 mm (7’ 11 63/64”)</div>
            <div class="indicator temporarily-hidden"></div>
          </div>
          <div v-if="currSlide === 'third'" class="dimension-z desktop">
            <div class="text">6,058 mm (19’ 10 1/2")</div>
            <div class="indicator temporarily-hidden"></div>
          </div>
        </div>
      </div>
      <div class="subsection-info container">
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currSlide === 'first'}" @click="changeSlide('first')">
              <h3>Door opening dimensions</h3>
              <p><span class="text-bold">Height:</span> 2,280 mm (7’ 5 49/64”)</p>
              <p><span class="text-bold">Width:</span> 2,340 mm (7’ 8 1/8”)</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'second'}" @click="changeSlide('second')">
              <h3>Internal dimensions</h3>
              <p><span class="text-bold">Height:</span> 2,392 mm (7’ 10 11/64”)</p>
              <p><span class="text-bold">Width:</span> 2,352 mm (7’ 8 19/32”)</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'third'}" @click="changeSlide('third')">
              <h3>External dimensions</h3>
              <p><span class="text-bold">Height:</span> 2,591 mm (8’ 6”)</p>
              <p><span class="text-bold">Width:</span> 2,438 mm (7’ 11 63/64”)</p>
              <p><span class="text-bold">Length:</span> 6,058 mm (19’ 10 1/2")</p>
            </div>
          </div>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
