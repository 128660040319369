import { createApp } from "vue";
import App from "./app.vue";
import router from "./router/router";

import "@/assets/styles/main.scss";

// Hide the chat widget before vue loads
window.zE("webWidget", "hide");
const app = createApp(App);
app.use(router);
app.mount("#app");
