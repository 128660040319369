<template lang="html" src="./exterior.html"></template>
<style lang="scss" scoped src="./_exterior.mobile.scss"></style>
<style lang="scss" scoped src="./_exterior.desktop.scss"></style>
<style lang="css" src="./vue-color-kit.css"></style>

<script>
import { ColorPicker } from "vue-color-kit";
import simpleColorConverter from "simple-color-converter";

// TODO: Make this work for prodution build
// import "vue-color-kit/dist/vue-color-kit.css";
export default {
  name: "Exterior",
  components: {
    ColorPicker
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ralError: false,
      ralColor: "",
      defaultLabel: "Upload image"
    };
  },
  mounted() {
    this.updateBackgroundColor();
  },
  emits: ["changeStep", "valueChange"],
  methods: {
    updateForm(newForm) {
      this.$emit("valueChange", newForm);
    },
    updateBackgroundColor() {
      document.querySelector(
        "#container-background"
      ).style.background = this.form.exterior.color;
      document.querySelector(
        "#container-background-m"
      ).style.background = this.form.exterior.color;
    },
    changeColor(color) {
      // needed by vue-color-kit as it does not support v-model binding
      const newForm = this.form;
      document.querySelector("#container-background").style.background =
        color.hex;
      newForm.exterior.color = color.hex;
      this.updateForm(newForm);
      this.updateBackgroundColor();
      this.ralColor = "";
    },
    changeRal(event) {
      const color = new simpleColorConverter({
        ral: { ral: parseInt(event.target.value) },
        to: "hex6"
      });
      if (color.error) {
        // Show the error for invalid codes but not when empty
        this.ralError = !!event.target.value;
        return;
      }
      this.ralError = false;
      const newForm = this.form;
      newForm.exterior.color = "#" + color.color;
      this.updateForm(newForm);
      this.updateBackgroundColor();
    },
    async onLogoAdd(event, index) {
      const file = event.target.files[0];
      const newForm = this.form;
      newForm.exterior.logos[index].label = file.name;
      newForm.exterior.logos[index].fileURL = URL.createObjectURL(file);
      newForm.exterior.logos[index].fileb64 = await this.toBase64(file);
      this.updateForm(newForm);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>
