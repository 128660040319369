<template lang="html" src="./terms-and-services.html"></template>
<style lang="scss" scoped src="./_terms-and-services.mobile.scss"></style>
<style lang="scss" scoped src="./_terms-and-services.desktop.scss"></style>

<script>
import NavbarComponent from "@/components/navbar/navbar.component.vue";
import FooterComponent from "@/components/footer/footer.component.vue";

export default {
  name: "TermsAndServices",
  components: { NavbarComponent, FooterComponent }
};
</script>
