<NavbarComponent v-bind:isWhite="false" v-bind:videoEnded="true" />

<div class="main">
  <div class="header">
    <span>Terms of Use</span>
  </div>

  <div class="sub-header">
    <span>General</span>
  </div>
  <div class="paragraph">
    <span>
      This website (hereinafter the "Website") is provided by "PEOPLE Technology Solutions Ltd.", a company duly 
      incorporated under the laws of United Kingdom with its registered seat in London, 15 Hanover Square, St George 
      House (6th Floor), W1S 1HS, UK, and PEOPLE Private Company, with its registered office in Fessa 5, Messini, Messinias 
      PS 24200, Greece, (hereinafter referred to as "PEOPLE", "we", "us" or "our") and aims at providing information on 
      our services to the visitors of the Website.
    </span>
  </div>
  <div class="paragraph">
    <span>
      By using this Website, you agree to the present Terms of Use, which are applicable to the whole content and 
      materials of the Website. In case you do not agree to the Terms of Use, you should not use this Website and 
      its services.
    </span>
  </div>
  <div class="paragraph">
    PEOPLE reserves the right to modify at any time these Terms of Use even without your notice. Thus, it is strongly 
    recommended that you should visit frequently this page to keep being informed on any changes. In the event of any 
    subsequent amendment of the Terms of Use, your use of the Website at a future date will be taken to imply your 
    acceptance of the new terms.
  </div>

  <div class="sub-header">
    <span>Use of services</span>
  </div>
  <div class="paragraph">
    The Website's content is provided for informational purposes. Users may visit the Website and browse it without 
    charge and registration. In order to use PEOPLE's platform, the user must be validly logged into his/her account. 
    The user shall: (a) ensure that all persons who may have access to the platform on behalf of the user are aware 
    of, and comply with the agreement concluded between PEOPLE and the user, and (b) maintain the confidentiality and 
    security of any user login details, usernames, identification codes, passwords and/or other credentials that may 
    give access to the platform. If there has been an unauthorized use of your login details, please notify us immediately.
  </div>

  <div class="sub-header">
    <span>Intellectual Property Rights and Trademarks</span>
  </div>
  <div class="paragraph">
    <span>
      PEOPLE is the sole beneficiary of any and all intellectual property rights on the design, source code and the entire 
      content of the Website (including but not limited to pictures, graphics, photographs, texts and all other files of 
      this Website) either directly as creator or indirectly, licensed by their respective creators.
    </span>
  </div>
  <div class="paragraph">
    <span>
      Any trademarks, service marks, and logos appearing on the Website are either registered trademarks of PEOPLE and/or 
      third parties, protected by the relevant provisions of applicable trademark laws, and by international conventions 
      and treaties. Nothing contained on the Website should be construed as granting any license or right to use any 
      trademarks or service marks without our prior written consent. Violation by any means of the aforementioned rights 
      will draw the subsequent penalties and liability defined by applicable laws.
    </span>
  </div>

  <div class="sub-header">
    <span>External links</span>
  </div>
  <div class="paragraph">
    <span>
      The Website may contain links to other websites, controlled by third parties. In no event shall PEOPLE and its 
      representatives be held liable for the content of such sites, nor for any financial or other form of loss or 
      damage suffered by your visiting of such sites. Under no circumstances shall PEOPLE or its representatives 
      guarantee that these links will operate without interruption. Furthermore, these external links are indicative, 
      not exclusive or limiting in nature. PEOPLE reserves the right to remove or add links without notice, and to 
      change the content of the Website at any time.
    </span>
  </div>

  <div class="sub-header">
    <span>Disclaimer of Warranties</span>
  </div>
  <div class="paragraph">
    <span>
      The use of the Website is at your own risk. The Website and any content, information, products or services made 
      available on or through the Website are provided on an "AS IS" and "AS AVAILABLE" basis without warranty of any 
      kind. PEOPLE hereby disclaims all warranties and conditions with regard to this Website or any information, content, 
      products or services contained therein, whether express or implied, including, but not limited to, the implied warranties 
      of merchantability, fitness for a particular purpose and non-infringement. Specifically, PEOPLE makes no warranty that i) 
      the Website will meet your requirements, ii) any user access to the Website will be uninterrupted, timely, secure or 
      error-free, iii) the quality of any content, features, products, services, information or other material obtained through 
      the Website meet your expectations. You should be aware of the possibility of technical, human or other errors, which 
      may cause technical problems or affect the Website.
    </span>
  </div>

  <div class="sub-header">
    <span>Limitation of liability</span>
  </div>
  <div class="paragraph">
    <span>
      To the fullest extent permitted by law, in no event shall PEOPLE and its representatives be liable for any special, incidental, 
      consequential, punitive damages or lost profits arising out of or in connection with information or materials published on the 
      Website, or any act or omission resulting from use of any services of the Website, even if advised in advance of such damages.
    </span>
  </div>

  <div class="sub-header">
    <span>Governing Law/ Arbitration</span>
  </div>
  <div class="paragraph">
    <span>
      These Terms of Use shall be governed by and construed under the laws of England and Wales. Any dispute arising from or in 
      connection with these Terms, shall be referred to arbitration under the LCIA Rules. The number of arbitrators shall be three 
      and the seat, or legal place, of arbitration shall be London, England. The language to be used in the arbitration proceedings 
      shall be English.
    </span>
  </div>
</div>

<FooterComponent v-bind:scroll-snap="false" v-show="true" />
