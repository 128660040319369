<!-- Live monitoring section -->
<section class="scroll-snap-item">
  <!-- Live monitoring always displayed info -->
  <div class="main">
    <div class="img">
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header">
          </div>
          <div class="info-item-desc">
            Multi platform
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
          </div>
          <div class="info-item-desc">
            Customise interface
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
          </div>
          <div class="info-item-desc">
            Open API
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              Multi platform
            </div>
            <div class="info-item-desc">
              A multi-platform design allows for easy and intuitive access wherever you are.
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              Customised interface
            </div>
            <div class="info-item-desc">
              Simple and flexible customisation
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              Open API
            </div>
            <div class="info-item-desc">
              Easy to integrate and develop with other platforms
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Control</h5>
        <h3>
          Live monitoring 24/7
        </h3>
        <p>
          With a host of functionalities, our customisable and personalised 
          software will provide any transport and logistics operation with 
          vital real-data insights, notifications and alerts, as well as 
          status report
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- live-monitoring learn more sub-sections -->
  <div id="live-monitoring-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2">
      <div class="header">
        <h3>All the control in one application</h3>
        <p>Having the complete control for your whole supply chain and assets, 
           and all in a single application, makes for easy management and quick 
           overviews at any given moment.</p>
      </div>
      <div class="img"></div>
      <!--TODO: Change video if the client provides us with the one needed
      <div class="video-wrapper">
        <video class="video animate__animated animate__fadeIn" autoplay muted loop playsinline>
          <source src="../../../../assets/media/videos/type20d/connectivity-slide1.mp4" type="video/mp4" />
        </video>
      </div>-->
      <div class="subsection-info container">
        <div class="grid">
          <div class="grid-item">
              <h3>Time of Delivery</h3>
              <p>Easily view ETA/ETD times</p>
          </div>
          <div class="grid-item">
              <h3>Proof of delivery</h3>
              <p>Detach of specific shipment on specific location from its group or asset</p>
          </div>
          <div class="grid-item">
              <h3>Dwell Status</h3>
              <p>Receive dwell status notification on all assets</p>
          </div>
          <div class="grid-item">
              <h3>Global tracking</h3>
              <p>4G (LTE Cat M1) with 2G (GPRS) fallback</p>
          </div>
          <div class="grid-item">
              <h3>Temperature & Humidity</h3>
              <p>Easily monitor the temperature or humidity of any asset 24/7</p>
          </div>
          <div class="grid-item">
              <h3>Light</h3>
              <p>View ambient light conditions and detect door open/close status</p>
          </div>
          <div class="grid-item">
              <h3>Open API</h3>
              <p>Easily customise and integrate with WMS/ERP</p>
          </div>
          <div class="grid-item">
              <h3>24/7 Recording</h3>
              <p>Complete visibility of all assets 24/7 with recorded data and metrics</p>
          </div>
          <div class="grid-item">
              <h3>Auto Reporting</h3>
              <p>Receive automated reports with meaningful data and insights</p>
          </div>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>

    <div class="subsection-3">
      <div class="header">
        <h3>Easily customise your interface and integrate with any WMS or ERP</h3>
        <p>Supply Chain<span class="sup">4.0</span> not only makes it easy for any company to implement 
          a new system but also makes it easy to seamlessly integrate with any 
          existing WMS or ERP whilst providing great flexibility in customising 
          your interface to you specific needs.</p>
      </div>
      <div class="img"></div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
