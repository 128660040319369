<template lang="html" src="./insights.section.html"></template>
<style lang="scss" scoped src="./_insights.section.mobile.scss"></style>
<style lang="scss" scoped src="./_insights.section.desktop.scss"></style>

<script>
export default {
  name: "InsightsSection",
  props: ["isActive", "isLearnMoreActive"],
  methods: {
    openLearnMore() {
      if (!this.isLearnMoreActive) {
        this.$emit("learn-more", "insights");
      }
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    }
  }
};
</script>
