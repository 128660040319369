<template lang="html" src="./smart-specs.html"></template>
<style lang="scss" scoped src="./_smart-specs.mobile.scss"></style>
<style lang="scss" scoped src="./_smart-specs.desktop.scss"></style>

<script>
import specs from "./new-specs.json";
export default {
  name: "SmartSpecs",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      package: {
        basic: {
          title: "Basic",
          id: "basic",
          body:
            "For businesses needing easy-to-use and straightforward monitoring without complex installation and software."
        },
        standard: {
          title: "Standard",
          id: "standard",
          body:
            "For businesses needing simple monitoring and effective management of cargo/assets with vital notifications and alerts."
        },
        advanced: {
          title: "Advanced",
          id: "advanced",
          body:
            "For businesses needing advanced cargo/assets monitoring, effective notifications, efficient insights and optimization of their business."
        },
        premium: {
          title: "Premium",
          id: "premium",
          body:
            "For businesses needing complete control of their cargo/asset quality and integrity, and easy monitoring with advanced analytics and entire interaction with stakeholders."
        }
      },
      info: specs
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    }
  },
  emits: ["changeStep", "valueChange"]
};
</script>
