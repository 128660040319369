export default {
  savingsConstants: {
    type20d: {
      ADDITIONAL_CARGO: 240,
      CO2_OFFSET: 166.02,
      AL_FLOOR_W: 0.171,
      TREE_SAVINGS: 6.49
    },
    type40d: {
      ADDITIONAL_CARGO: 560,
      CO2_OFFSET: 287.622,
      AL_FLOOR_W: 0.296,
      TREE_SAVINGS: 11.2
    },
    type45d: {
      ADDITIONAL_CARGO: 330,
      CO2_OFFSET: 345.09,
      AL_FLOOR_W: 0.355,
      TREE_SAVINGS: 13.49
    },
    type53d: {
      ADDITIONAL_CARGO: 550,
      CO2_OFFSET: 432.8,
      AL_FLOOR_W: 0.446,
      TREE_SAVINGS: 16.92
    }
  },

  containerTypeMap: {
    type20d: "Type 20D",
    type40d: "Type 40D",
    type45d: "Type 45D",
    type53d: "Type 53D"
  }
};
