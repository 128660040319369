<template lang="html" src="./fmaas.html"></template>
<style lang="scss" scoped src="./_fmaas.mobile.scss"></style>
<style lang="scss" scoped src="./_fmaas.desktop.scss"></style>

<script>
export default {
  name: "Fmaas",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      package: {
        short: {
          title: "Short term",
          id: "short",
          body:
            "Best for businesses that require a short-term commitment below 12 months."
        },
        medium: {
          title: "Medium term",
          id: "medium",
          body:
            "Best for businesses that require a medium-term plan with 12 months commitment."
        },
        long: {
          title: "Long term",
          id: "long",
          body:
            "Best for businesses that require a long-term strategic plan with more than 24 months commitment."
        }
      }
    };
  },
  emits: ["changeStep"]
};
</script>
