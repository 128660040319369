<div class="sidenav" :class="{ white: isWhite }">
  <a href="#model" :class="[{ active: currentActive === 'model' }, 'nav-link']">
    <span>{{ typeName }}</span>
  </a>
  <a href="#connectivity" :class="[{ active: currentActive === 'connectivity' }, 'nav-link']">
    <span>Connectivity</span>
  </a>
  <a href="#live-monitoring" :class="[{ active: currentActive === 'live-monitoring' }, 'nav-link']">
    <span>Control</span>
  </a>
  <a href="#analytics" :class="[{ active: currentActive === 'analytics' }, 'nav-link']">
    <span>Analytics</span>
  </a>
  <a href="#insights" :class="[{ active: currentActive === 'insights' }, 'nav-link']">
    <span>Insights</span>
  </a>
  <a href="#field-of-application" :class="[{ active: currentActive === 'field-of-application' }, 'nav-link']">
    <span>Field of Application</span>
  </a>
  <a href="#tech-specs" :class="[{ active: currentActive === 'tech-specs' }, 'nav-link']">
    <span>Device Tech Specs</span>
  </a>
  <a href="#request-quote" :class="[{ active: currentActive === 'request-quote' }, 'nav-link']">
    <span>Request quote</span>
  </a>
</div>
