<NavbarComponent v-bind:isWhite="false" v-bind:videoEnded="true" />

<div class="main">
  <div class="header">
    <span>Privacy Policy</span>
  </div>
  <div class="sub-header center">
    <span>Privacy Policy</span>
  </div>
  <div class="space">&nbsp;</div>
  <div class="sub-header">
    <span>Last updated: 22/01/2021</span>
  </div>
  <div class="space">&nbsp;</div>

  <div class="sub-header">
    <span>Introduction</span>
  </div>
  <div class="paragraph justify">
    <span>
      This website (hereinafter the "Website") aims at providing information on our services to the visitors of the 
      Website. In this context, PEOPLE Technology Solutions LTD with its registered office in London, 15 Hanover 
      Square, St George House (6th Floor), W1S 1HS, UK, and PEOPLE Private Company, with its registered office in 
      Fessa 5, Messini, Messinias PS 24200, Greece, (hereinafter "PEOPLE", "we", "us" or "our") are according to 
      the applicable legislation joint Data Controllers of your personal data, as collected through the Website.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      We aim to ensure the protection of your personal data and for this reason, we are providing you with the following 
      information regarding the type of personal data we collect about you, the means and purposes for which we collect 
      them, the third parties with whom we share these data, as well as the rights you have, in compliance with the applicable 
      data protection legislation.
    </span>
  </div>

  <div class="sub-header">
    <span>What personal data do we process?</span>
  </div>
  <div class="paragraph justify">
    <span>
      'Personal data' means any information relating to an identified or identifiable natural person.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      Depending on which Website features you use, we collect from you and further process the following personal data: 
      identification information, contact details, details on your question/query/request, username and password, information 
      included in your CV and other information about you, as it may arise from your cover letter.
    </span>
  </div>
  <div class="space">&nbsp;</div>
  <div class="paragraph justify">
    <span>
      We also collect and further process information, which is necessary for the proper functioning of the Website, including 
      your IP address, as well as information on your device and browsing, as collected through internet trackers such as cookies. 
      For more information regarding the use of such technologies, please <a href="/privacy-policy">click here</a> to read our 
      <span class="underline">Cookies Policy</span>.
    </span>
  </div>

  <div class="sub-header">
    <span>For what purposes do we process your personal data?</span>
  </div>
  <div class="paragraph justify">
    <span>
      Depending on which Website features you use, we will process your personal data for the following purposes:
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      -in order for you to be able to contact us through an email, post mail or phone call. It is in our legitimate 
      interests to handle such a communication and properly respond to you.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      -in order to provide you with our services. For the execution of the services the legal basis for the processing 
      of your personal data is to perform each contract with you.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      -in order to pre-contractually assess your qualifications and process your application. You will find detailed 
      information about this processing operation, by accessing the <span class="underline">Candidates' Privacy Notice</span>.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      -in order to respond to a request for quote and ultimately to enter into a contract with the company/organization 
      you represent. It is in our legitimate interests to be able to contact prospective clients' representatives.
    </span>
  </div>

  <div class="space">&nbsp;</div>

  <div class="sub-header">
    <span>With whom do we share your personal data?</span>
  </div>
  <div class="paragraph justify">
    <span>
      Apart from our authorized personnel, your data may be disclosed to other affiliated companies. Moreover, your data 
      may be disclosed to our website operator, online forms providers, corporate communications management companies and 
      relevant suppliers who provide us with services related to the purposes described above and which are bound to comply 
      with all necessary technical and organizational measures for the protection of your personal data.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      In addition, your data may be processed by partnering cloud storage companies, such as Microsoft. In the context of 
      these companies' operation, your data may be transferred to third countries. In such cases, your data will be transferred 
      in compliance with the General Data Protection Regulation articles 46, 47, or 49(1), especially, Standard Contractual Clauses.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      Please read our <span class="underline">Cookies Policy</span> for any third parties having access to your information collected 
      through internet trackers such as cookies.
    </span>
  </div>

  <div class="sub-header">
    <span>How long is your personal data retained?</span>
  </div>
  <div class="paragraph justify">
    <span>
      Your personal data is retained for as long as this is required for the fulfillment of each processing purpose. For example, your 
      job application will be retained for a period of one (1) year and your personal data collected via the contact form will be kept 
      until the communication's request is fulfilled. Upon expiration of these terms, your personal data will be deleted, unless otherwise 
      required under the applicable legal and regulatory framework or for the establishment, exercise or defense of legal claims.
    </span>
  </div>

  <div class="sub-header">
    <span>How does PEOPLE protect your personal data?</span>
  </div>
  <div class="paragraph justify">
    <span>
      We have implemented all appropriate organizational and technical measures, pursuant to the applicable legal framework and standards, 
      in order to safeguard that processing of data is legitimate, appropriate and secured against any non-authorized or illegal access, 
      deletion, amendment or any other use of the data.
    </span>
  </div>

  <div class="sub-header">
    <span>What are your rights?</span>
  </div>
  <div class="paragraph justify>">
    <span>
      In any case, we would like to inform you that, in accordance with the applicable legislation, you have and can exercise the following rights:
    </span>
  </div>
  <ul>
    <li v-for="right in rights">
      <span>{{ right }}</span>
    </li>
  </ul>
  <div class="space">&nbsp;</div>
  <div class="paragraph justify">
    <span>
      You may exercise any of the above rights by submitting a request to us. You can expect a response to such a request within one (1) 
      month following reception thereof and, in any case, within two (2) additional months, if this is required due to the complexity of 
      your request or the total number of requests received. 
    </span>
  </div>

  <div class="space">&nbsp;</div>

  <div class="sub-header">
    <span>Contact Details</span>
  </div>
  <div class="paragraph justify">
    <span>
      You may address any questions or requests regarding the protection of your personal data handled by PEOPLE, as well as withdraw any 
      consent, using the following e-mail address: <a href="mailto:info@peoplethinkbeyond.com">info@peoplethinkbeyond.com</a>.
    </span>
  </div>
  <div class="paragraph justify">
    <span>
      In any case, if you deem that the protection of your personal data is somehow affected, you have the right to lodge a complaint with 
      the competent Information Commissioner's Office using the following contact details: 0303 123 1113 | 
      <a href="https://ico.org.uk/">www.ico.org.uk</a> or with the 
      Hellenic Data Protection Authority, using the following contact details: <a href="mailto:complaints@dpa.gr">complaints@dpa.gr</a> | +30 210 6475600 | 
      <a href="https://www.dpa.gr/">www.dpa.gr</a>.
    </span>
  </div>
</div>

<FooterComponent v-bind:scroll-snap="false" v-show="true" />
