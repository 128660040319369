<template lang="html" src="./platform.html"></template>
<style lang="scss" scoped src="./_platform.mobile.scss"></style>
<style lang="scss" scoped src="./_platform.desktop.scss"></style>

<script>
import specs from "./new-specs.json";
export default {
  name: "Platform",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      package: {
        location: {
          title: "Location",
          price: "Free",
          id: "location",
          body:
            "Best for businesses that need a simple and easy-to-use solution for tracking in near real time the position of their assets/cargo"
        },
        basic: {
          title: "Basic",
          price: "€4,00",
          id: "basic",
          body:
            "Best for businesses that need simple and easy to use monitoring procedures without time consuming installation and complex software"
        },
        standard: {
          title: "Standard",
          price: "€8,00",
          id: "standard",
          body:
            "For businesses needing simple monitoring and effective management of cargo/assets with vital notifications and alerts."
        },
        advanced: {
          title: "Advanced",
          price: "€16,00",
          id: "advanced",
          body:
            "For businesses needing advanced cargo/assets monitoring, effective notifications and efficient insights and optimisation of business."
        },
        premium: {
          title: "Premium",
          price: "€36,00",
          id: "premium",
          body:
            "Best for businesses that need to have full control of their cargo quality and integrity, simple monitoring  procedures, full interaction with all stakeholders and advanced analytics"
        }
      },
      info: specs
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    }
  },
  emits: ["changeStep"]
};
</script>
