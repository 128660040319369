<template lang="html" src="./calculate-savings.html"></template>
<style lang="scss" scoped src="./_calculate-savings.mobile.scss"></style>
<style lang="scss" scoped src="./_calculate-savings.desktop.scss"></style>

<script>
import numberUtils from "../../helpers/numbers";
import savingsUtils from "../../helpers/savings";
import constants from "../../constants";

export default {
  name: "CalculateSavingsComponent",
  emits: ["modal"],
  props: ["defaultTypeKey"],
  data() {
    const containerNumberRequirement = {
      label: "No. of Container<span class='sup'>2.0</span>",
      inputs: [
        {
          name: "numberOfBoxes",
          prefix: "",
          suffix: "containers"
        }
      ]
    };
    const operationalRequirement = {
      label: "Operational",
      inputs: [
        {
          name: "operationalLife",
          prefix: "",
          suffix: "years"
        }
      ]
    };
    const travelledRequirement = {
      label: "Travelled annually",
      inputs: [
        {
          name: "kmYearRoad",
          prefix: "",
          suffix: "km by road"
        },
        {
          name: "kmYearRail",
          prefix: "",
          suffix: "km by rail"
        },
        {
          name: "kmYearSea",
          prefix: "",
          suffix: "km by sea"
        }
      ]
    };
    const fuelPriceRequirement = {
      label: "Fuel Price (per lt)",
      inputs: [
        {
          name: "fuelPriceRoad",
          prefix: "$",
          suffix: "on road"
        },
        {
          name: "fuelPriceRail",
          prefix: "$",
          suffix: "on rail"
        },
        {
          name: "fuelPriceSea",
          prefix: "$",
          suffix: "on sea"
        }
      ]
    };
    const cargoPriceRequirement = {
      label: "Cargo Price (per tnkm)",
      inputs: [
        {
          name: "cargoPriceRoad",
          prefix: "$",
          suffix: "road"
        },
        {
          name: "cargoPriceRail",
          prefix: "$",
          suffix: "rail"
        },
        {
          name: "cargoPriceSea",
          prefix: "$",
          suffix: "sea"
        }
      ]
    };
    const requirements = {
      "category-1": {
        "row-1": [containerNumberRequirement, operationalRequirement],
        "row-2": [travelledRequirement],
        "row-3": []
      },
      "category-2": {
        "row-1": [containerNumberRequirement, operationalRequirement],
        "row-2": [travelledRequirement],
        "row-3": [cargoPriceRequirement]
      },
      "category-3": {
        "row-1": [containerNumberRequirement, operationalRequirement],
        "row-2": [travelledRequirement],
        "row-3": [fuelPriceRequirement]
      },
      "category-4": {
        "row-1": [containerNumberRequirement],
        "row-2": [
          {
            label: "Aluminium ingot price",
            inputs: [
              {
                name: "alIngotPrice",
                prefix: "$",
                suffix: "per tn"
              }
            ]
          },
          {
            label: "Scrap value",
            name: "",
            inputs: [
              {
                name: "scrapValue",
                prefix: "",
                suffix: "%"
              }
            ]
          }
        ],
        "row-3": []
      },
      "category-5": {
        "row-1": [containerNumberRequirement, operationalRequirement],
        "row-2": [],
        "row-3": []
      }
    };
    return {
      types: ["Type 20D", "Type 40D", "Type 45D", "Type 53D"],
      activeType: constants.containerTypeMap[this.$props.defaultTypeKey],
      isTypesOpen: false,
      addRequirements: "+",
      addRequirementsLabel: "Add your requirement",
      isRequirementsOpen: false,
      requirements: requirements,
      activeCategory: "category-1",
      sensitiveCargo: "weight",
      selectedType: this.$props.defaultTypeKey,
      inputs: {
        numberOfBoxes: 1,
        operationalLife: 12,
        kmYearRoad: 72405,
        kmYearRail: 37055,
        kmYearSea: 280094,
        alIngotPrice: 2031,
        scrapValue: 75,
        cargoPriceRoad: 0.115,
        cargoPriceRail: 0.017,
        cargoPriceSea: 0.0013,
        fuelPriceRoad: 1.961,
        fuelPriceRail: 1.961,
        fuelPriceSea: 1.0
      },
      constants: constants.savingsConstants
    };
  },
  watch: {
    defaultTypeKey(val) {
      this.selectedType = val;
      this.activeType = constants.containerTypeMap[val];
    }
  },
  computed: {
    intermediateValues() {
      return savingsUtils.calculateSavings(
        this.constants[this.selectedType],
        this.inputs
      );
    },
    results() {
      const results = {
        "category-1": {
          header: `CO<span class='sub'>2</span> reduction with
            <span class='text-bold'>Container<span class='sup'>2.0</span></span>`,
          results: [
            {
              title: "CO<span class='sub'>2</span> reduction (estimated)",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_ROAD,
                  suffix: "kgCO<span class='sub'>2</span> on road"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_RAIL,
                  suffix: "kgCO<span class='sub'>2</span> on rail"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_SEA,
                  suffix: "kgCO<span class='sub'>2</span> on sea"
                }
              ]
            },
            {
              title: "CO<span class='sub'>2</span> offset (estimated)",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_OFFSET,
                  suffix: "kgCO<span class='sub'>2</span>"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.TREES_SAVED,
                  suffix: "trees saved"
                }
              ]
            }
          ],
          total: {
            title: "Total",
            calcs: [
              {
                prefix: "",
                value: this.intermediateValues.CO2_TOTAL,
                suffix: "kgCO<span class='sub'>2</span>"
              }
            ],
            outro: "less contributing to the Earth’s global warming."
          }
        },
        "category-2": {
          header: `Additional cargo with 
            <span class='text-bold'>Container<span class='sup'>2.0</span></span>`,
          results: [
            {
              title: "Additional cargo (estimated)",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.CARGO_ROAD,
                  suffix: "tnkm by road"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CARGO_RAIL,
                  suffix: "tnkm by rail"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CARGO_SEA,
                  suffix: "tnkm by sea"
                }
              ]
            }
          ],
          total: {
            title: "Additional income or cost savings (estimated)",
            calcs: [
              {
                prefix: "$",
                value: this.intermediateValues.CARGO_TOTAL,
                suffix: ""
              }
            ]
          }
        },
        "category-3": {
          header: `Fuel savings with 
            <span class='text-bold'>Container<span class='sup'>2.0</span></span>`,
          results: [
            {
              title: "Fuel savings (estimated)",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.FUEL_ROAD,
                  suffix: "lt on road"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.FUEL_RAIL,
                  suffix: "lt on rail"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.FUEL_SEA,
                  suffix: "lt on sea"
                }
              ]
            }
          ],
          total: {
            title: "Cost savings (estimated)",
            calcs: [
              {
                prefix: "$",
                value: this.intermediateValues.FUEL_TOTAL,
                suffix: ""
              }
            ]
          }
        },
        "category-4": {
          header: `Additional residual value of 
            <span class='text-bold'>Container<span class='sup'>2.0</span></span>`,
          results: [],
          total: {
            title: "",
            calcs: [
              {
                prefix: "$",
                value: this.intermediateValues.RESIDUAL_VALUE,
                suffix: ""
              }
            ]
          }
        },
        "category-5": {
          header: `With <span class='text-bold'>Container<span class='sup'>2.0</span></span>`,
          results: [
            {
              title: "Reduction of CO<span class='sub'>2</span> emissions",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_ROAD,
                  suffix: "kg on road"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_RAIL,
                  suffix: "kg on rail"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_SAVINGS_SEA,
                  suffix: "kg on sea"
                }
              ]
            },
            {
              title: "CO<span class='sub'>2</span> offset",
              calcs: [
                {
                  prefix: "",
                  value: this.intermediateValues.CO2_OFFSET,
                  suffix: "kgCO<span class='sub'>2</span>"
                },
                {
                  prefix: "",
                  value: this.intermediateValues.TREES_SAVED,
                  suffix: "trees saved"
                }
              ]
            },
            {
              title: "Additional income or cost savings",
              calcs: [
                {
                  prefix: "$",
                  value: this.intermediateValues.CARGO_ROAD_SAVINGS,
                  suffix: "on road"
                },
                {
                  prefix: "$",
                  value: this.intermediateValues.CARGO_RAIL_SAVINGS,
                  suffix: "on rail"
                },
                {
                  prefix: "$",
                  value: this.intermediateValues.CARGO_SEA_SAVINGS,
                  suffix: "on sea"
                }
              ]
            },
            {
              title: "Fuel savings",
              calcs: [
                {
                  prefix: "$",
                  value:
                    this.intermediateValues.FUEL_ROAD *
                    this.inputs.fuelPriceRoad,
                  suffix: "on road"
                },
                {
                  prefix: "$",
                  value:
                    this.intermediateValues.FUEL_RAIL *
                    this.inputs.fuelPriceRail,
                  suffix: "on rail"
                },
                {
                  prefix: "$",
                  value:
                    this.intermediateValues.FUEL_SEA * this.inputs.fuelPriceSea,
                  suffix: "on sea"
                }
              ]
            },
            {
              title: "Additional residual value",
              calcs: [
                {
                  prefix: "$",
                  value: this.intermediateValues.RESIDUAL_VALUE,
                  suffix: ""
                }
              ]
            }
          ],
          total: {
            title: "Total additional value",
            calcs: []
          }
        }
      };

      const WEIGHT_SENSITIVE_CALCS = [
        {
          title: "CO<span class='sub'>2</span> offset",
          prefix: "",
          value: this.intermediateValues.CO2_OFFSET,
          suffix: "kgCO<span class='sub'>2</span>"
        },
        {
          title: "Additional income or cost savings",
          prefix: "$",
          value: this.intermediateValues.CARGO_TOTAL,
          suffix: ""
        },
        {
          title: "Additional residual value",
          prefix: "$",
          value: this.intermediateValues.RESIDUAL_VALUE,
          suffix: ""
        }
      ];

      const VOLUME_SENSITIVE_CALCS = [
        {
          title: "CO<span class='sub'>2</span> total reduction",
          prefix: "",
          value: this.intermediateValues.CO2_TOTAL,
          suffix: "kgCO<span class='sub'>2</span>"
        },
        {
          title: "Fuel Savings",
          prefix: "$",
          value: this.intermediateValues.FUEL_TOTAL,
          suffix: ""
        },
        {
          title: "Additional residual value",
          prefix: "$",
          value: this.intermediateValues.RESIDUAL_VALUE,
          suffix: ""
        }
      ];

      if (this.sensitiveCargo === "weight") {
        results["category-5"].total.calcs = WEIGHT_SENSITIVE_CALCS;
      } else {
        results["category-5"].total.calcs = VOLUME_SENSITIVE_CALCS;
      }
      return results;
    },
    activeResults() {
      return this.results[this.activeCategory];
    },
    activeRequirements() {
      return this.requirements[this.activeCategory];
    }
  },
  methods: {
    toggleTypes() {
      this.isTypesOpen = !this.isTypesOpen;
    },
    setType(value) {
      this.activeType = value;
      switch (value) {
        case "Type 20D":
          this.selectedType = "type20d";
          this.inputs.weightSaving = this.constants.type20d.ADDITIONAL_CARGO;
          break;
        case "Type 40D":
          this.selectedType = "type40d";
          this.inputs.weightSaving = this.constants.type40d.ADDITIONAL_CARGO;
          break;
        case "Type 45D":
          this.selectedType = "type45d";
          this.inputs.weightSaving = this.constants.type45d.ADDITIONAL_CARGO;
          break;
        case "Type 53D":
          this.selectedType = "type53d";
          this.inputs.weightSaving = this.constants.type53d.ADDITIONAL_CARGO;
          break;
      }
    },
    toggleRequirements() {
      if (this.addRequirements === "+") {
        this.addRequirements = "-";
        this.addRequirementsLabel = "Close requirement";
      } else {
        this.addRequirements = "+";
        this.addRequirementsLabel = "Add your requirement";
      }
      this.isRequirementsOpen = !this.isRequirementsOpen;
    },
    activateCategory(value) {
      this.activeCategory = value;
    },
    toFixedDecimals(value, count) {
      return numberUtils.toFixedDecimals(value, count);
    }
  }
};
</script>
