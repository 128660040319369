<!-- Connectivity section -->
<section class="scroll-snap-item">
  <!-- Connectivity always displayed info -->
  <div class="main">
    <div class="img">
      <video autoplay muted loop playsinline class="video-hero">
        <source src="../../../../assets/media/videos/type20d/connectivity-hero.mp4" type="video/mp4" />
      </video>
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header">
            <i class="mesh-icon icon"></i>
          </div>
          <div class="info-item-desc">
            M2M communication
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <i class="explanation-icon icon"></i>
          </div>
          <div class="info-item-desc">
            Attachment/ Detachment
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <i class="wifi-icon icon"></i>
          </div>
          <div class="info-item-desc">
            Over-the-air updates
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              <i class="mesh-icon icon"></i>
              M2M communication
            </div>
            <div class="info-item-desc">
              Container<span class="sup">2.0</span>-to-Container<span class="sup">2.0</span>
              and communication with cargo, trailers, trucks, railcars and other logistics equipment.
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              <i class="explanation-icon icon"></i>
              Attachment/detachment
            </div>
            <div class="info-item-desc">
              Load/ Unload notifications and alerts
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              <i class="wifi-icon icon"></i>
              Over-the-air updates
            </div>
            <div class="info-item-desc">
              Over-air software updates introduce new features, functionality and performance
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Connectivity</h5>
        <h3>
          Built smart, <br class="mobile"> from the ground up
        </h3>
        <p>
          Unlike most standard intermodal containers, which are made smart with the addition of third-party gadgets or
          devices, the Container<span class="sup">2.0</span> has state-of-the-art smart technology
          built in from its construction and
          designed to be dynamically improved via Remote Firmware Updates.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Connectivity learn more sub-sections -->
  <div id="connectivity-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2">
      <div class="header">
        <h3>Thinking, beyond the box</h3>
        <p>The Container<span class="sup">2.0</span> is built for the future of transport and logistics: Using
          intelligent software and cloud technology, enables monitoring at any point within the supply chain and
          increases efficiency, visibility and productivity.</p>
      </div>
      <div class="video-wrapper">
        <video class="video animate__animated animate__fadeIn" autoplay muted loop playsinline v-if="currSlide === 'first'">
          <source src="../../../../assets/media/videos/type20d/connectivity-slide1.mp4" type="video/mp4" />
        </video>
        <video class="video animate__animated animate__fadeIn" autoplay muted loop playsinline v-if="currSlide === 'second'">
          <source src="../../../../assets/media/videos/type20d/connectivity-slide2.mp4" type="video/mp4" />
        </video>
        <video class="video animate__animated animate__fadeIn" autoplay muted loop playsinline v-if="currSlide === 'third'">
          <source src="../../../../assets/media/videos/common/connectivity-section/connectivity-slide3.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="subsection-info container">
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currSlide === 'first'}" @click="changeSlide('first')">
              <h3>Alarms and notifications</h3>
              <p>Custom alert parameters for location (route and geofences), climate factors (temperature and humidity)
                and shipment integrity (shock and vibration) reducing recalls and increasing acceptance rates.</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'second'}" @click="changeSlide('second')">
              <h3>24/7 Global tracking</h3>
              <p>Transceivers of cellular technology, integrated into our tracking devices, allow worldwide 24/7
                monitoring of the cargo conditions and alarms.</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'third'}" @click="changeSlide('third')">
              <h3>Open API</h3>
              <p>Easily integrates with existing enterprise software.</p>
            </div>
          </div>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
