<!-- Field of application section -->
<section class="scroll-snap-item">
  <!-- Field of application always displayed info -->
  <div class="main">
    <!--TODO: Change img once the client provides us with the video needed-->
    <div class="img">
      <div class="tp-layer"></div>
      <div class="bt-layer"></div>
      <video autoplay muted loop playsinline class="video-hero">
        <source src="../../../../assets/media/videos/supply-chain/field-of-application-hero.mp4" type="video/mp4" />
      </video>
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header">
            Customise for any industry
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Better business. Better world
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Real-time solutions
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              Customised for any industry
            </div>
            <div class="info-item-desc">
              Fully customisable software to provide any industry operation with vital real-data insights
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              Better business. Better world
            </div>
            <div class="info-item-desc">
              Intelligent, connected industries are more responsive, insight driven and sustainable.
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              Real-time solutions
            </div>
            <div class="info-item-desc">
              Real-time solutions can be applied to an array of means of transport or modes of distribution.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Field of application</h5>
        <h3>Perfect for any industry</h3>
        <p>
          Whether your industry has sensitive cargo, delivery times or transportation costs, 
          Supply Chain<span class="sup">4.0</span> makes any supply chain in any industry as efficient and 
          lucrative as possible. With customisable and personalised features specific to your 
          needs Supply Chain<span class="sup">4.0</span> will bring all the control needed to your fingertips.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Field of Application learn more sub-sections -->
  <div id="field-of-application-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2">
      <div class="header">
        <h3>Industry benefits and solutions</h3>
        <p>Supply Chain<span class="sup">4.0</span> enables any industry to drastically increase the quality of shipments. 
          By greatly optimising an overall supply chain operation, lost cargo is eliminated, fuel 
          consumption is greatly decreased, CO<span class="sub">2</span> emissions drastically reduced and no extra cost is 
          spent moving goods, making it a far more environmentally friendly and sustainable operation 
          for both business and the world.</p>
      </div>
      
      <div class="subsection-info container">
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currSlide === 'first'}" @click="changeSlide('first')">
              <h3>Pharmaceutical</h3>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'second'}" @click="changeSlide('second')">
              <h3>FMCG, F&B</h3>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'third'}" @click="changeSlide('third')">
              <h3>General Cargo</h3>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'fourth'}" @click="changeSlide('fourth')">
              <h3>Sea & Land Ports</h3>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'fifth'}" @click="changeSlide('fifth')">
              <h3>Transport & Logistics</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="img" :class="currSlide">
        <div class="bt-layer"></div>
        <!-- Mobile -->
        <div class="mobile">
          <div v-if="currSlide === 'first'">
            <div class="info">
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">24/7</span>
                </div>
                <div class="info-item-desc">
                  temperature monitor
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Certification</span>
                </div>
                <div class="info-item-desc">
                  for transportation conditions
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Ensure</span>
                </div>
                <div class="info-item-desc">
                  cargo security
                </div>
              </div>
            </div>
          </div>
          <div v-if="currSlide === 'second'">
            <div class="info">
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Control</span>
                </div>
                <div class="info-item-desc">
                  Full control of conditions
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Notifications</span>
                </div>
                <div class="info-item-desc">
                  ETD/ETA/Delays notifications
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Avoid</span>
                </div>
                <div class="info-item-desc">
                  unnecessary transporting
                </div>
              </div>
            </div>
          </div>
          <div v-if="currSlide === 'third'">
            <div class="info">
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Ensure</span>
                </div>
                <div class="info-item-desc">
                  cargo security
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Certification</span>
                </div>
                <div class="info-item-desc">
                  for optimum transportation
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Avoid</span>
                </div>
                <div class="info-item-desc">
                  unnecessary transporting
                </div>
              </div>
            </div>
          </div>
          <div v-if="currSlide === 'fourth'">
            <div class="info">
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Full</span>
                </div>
                <div class="info-item-desc">
                  visibility and control
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Ρevenue</span>
                </div>
                <div class="info-item-desc">
                  Additional revenue stream
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <!-- TODO replace this with icon -->
                  <span class="text-big">Monitor</span>
                </div>
                <div class="info-item-desc">
                  storage conditions
                </div>
              </div>
            </div>
          </div>
          <div v-if="currSlide === 'fifth'">
            <div class="info">
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Ρevenue</span>
                </div>
                <div class="info-item-desc">
                  Additional revenue stream
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Ensure</span>
                </div>
                <div class="info-item-desc">
                  cargo security
                </div>
              </div>
              <div class="divider"></div>
              <div class="info-item">
                <div class="info-item-header">
                  <span class="text-big">Reduce</span>
                </div>
                <div class="info-item-desc">
                  overall costs
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Desktop -->
        <div class="container desktop">
          <div class="info" v-if="currSlide === 'first'">
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive }, 'transparent']">
              <div class="info-item-header">
                24/7<br/>temperature monitor
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
              <div class="info-item-header">
                Certification for<br/>transportation conditions
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
              <div class="info-item-header">
                Ensure<br/>cargo security
              </div>
            </div>
          </div>
          <div class="info" v-if="currSlide === 'second'">
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive }, 'transparent']">
              <div class="info-item-header">
                Full control<br/>of conditions
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
              <div class="info-item-header">
                ETD/ETA/Delays<br/>notifications
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
              <div class="info-item-header">
                Avoid unnecessary<br/> transporting
              </div>
            </div>
          </div>
          <div class="info" v-if="currSlide === 'third'">
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive }, 'transparent']">
              <div class="info-item-header">
                Ensure<br/>cargo security
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
              <div class="info-item-header">
                Certification for<br/>optimum transportation
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
              <div class="info-item-header">
                Avoid unnecessary<br/> transporting
              </div>
            </div>
          </div>
          <div class="info" v-if="currSlide === 'fourth'">
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive }, 'transparent']">
              <div class="info-item-header">
                Full<br/>visibility and control
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
              <div class="info-item-header">
                Additional<br/>revenue stream
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
              <div class="info-item-header">
                Monitor<br/>storage conditions
              </div>
            </div>
          </div>
          <div class="info" v-if="currSlide === 'fifth'">
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive }, 'transparent']">
              <div class="info-item-header">
                Additional<br/>revenue stream
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
              <div class="info-item-header">
                Ensure<br/>cargo security
              </div>
            </div>
            <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
            <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
              <div class="info-item-header">
                Reduce<br/>overall costs
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subsection-info container industry-benefits">
        <div class="benefits" v-if="currSlide === 'first'">
          <p>Pharmaceutical products are greatly sensitive to environmental conditions. 
            If cargo experiences temperature fluctuations it can suffer an irreparable damage, 
            leading to public health concerns and lost revenue for the company. Currently, 
            most pharmaceutical companies depend on passive temperature loggers, 
            offering data that can only be accessed after receipt of shipment, 
            lacking real-time visibility and insight into the exact time and place of events 
            or cause.
          </p>
            <ul>
              <li>Increase acceptance rate</li>
              <li>Ensure good conditions from start to end</li>
              <li>Increase quality</li>
              <li>Decrease theft and cargo loss</li>
              <li>Reduce time travel</li>
            </ul>
        </div>
        <div class="benefits" v-if="currSlide === 'second'">
          <p>FMCG and F&B companies work on a fast flow of supply and 
            stock. Revenues rely on operational efficiency and cost 
            optimisation. Supply Chain<span class="sup">4.0</span> enables you to eliminate lost or 
            stolen cargo, reduce dwell and travel times, avoid unnecessary 
            transportation and inefficient shipments, enabling proactive 
            replenishment of eventful shipments and revealing new revenue 
            streams.
          </p>
            <ul>
              <li>Avoid additional costs</li>
              <li>Ensure cargo security</li>
              <li>Ensure good conditions from start to end</li>
              <li>Reduce travel time</li>
              <li>Load/Unload/Delivery notifications</li>
              <li>Decrease cargo loss</li>
            </ul>
        </div>
        <div class="benefits" v-if="currSlide === 'third'">
          <p>Smooth transport conditions and swift arrival at the designated destination 
            are the main concerns of general cargo companies. With Supply 
            Chain<span class="sup">4.0</span> a business can take control of their data creating a 
            far more efficient supply chain. Creating clear accountability and 
            unbiased information about an end-to-end supply chain without the 
            need to rely on the organisation and communication of multiple 
            parties for shipment data.
          </p>
            <ul>
              <li>Increase acceptance rate</li>
              <li>Reduce travel time</li>
              <li>Ensure cargo security</li>
              <li>Ensure good conditions from start to end</li>
              <li>Increase quality</li>
              <li>Decrease theft and cargo loss</li>
            </ul>
        </div>
        <div class="benefits" v-if="currSlide === 'fourth'">
          <p>Sea and land ports are at the heart of the cross-functional activities 
            of multiple parties and modes of transport, where quality and efficiency are the keys
            to their successful operation. Supply Chain<span class="sup">4.0</span> makes use of its complete 
            potential and capacity by creating full visibility and control over a wide 
            range of equipment and the facilities available, optimising each and every 
            movement and space that is available at any given moment 24/7.
          </p>
            <ul>
              <li>Improve service quality</li>
              <li>Decrease cargo loss</li>
              <li>Ensure cargo handling conditions</li>
              <li>Multi parties instant communication</li>
            </ul>
        </div>
        <div class="benefits" v-if="currSlide === 'fifth'">
          <p>Transport & Logistic companies are continually challenged to offer 
            competitive advantages to their clients and to provide key 
            differentiating tools without a reduction to their own revenues. 
            With easy implementation, Supply Chain<span class="sup">4.0</span> provides the ability to 
            create additional revenue streams all whilst increasing the efficiency 
            and intelligence of a supply chain and offering additional services. 
            Supply Chain<span class="sup">4.0</span> allows companies to take their business to the next 
            level without the risk of a return on investment.
          </p>
            <ul>
              <li>Additional service to offer</li>
              <li>Optimum conditions certification</li>
              <li>Increase acceptance rate</li>
              <li>Ensure good conditions from start to end</li>
              <li>Increase quality</li>
              <li>Ensure cargo security</li>
              <li>Reduce travel time</li>
              <li>Decrease theft and cargo loss</li>
            </ul>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
