<section class="scroll-snap-item">
  <div class="main">
    <div class="img"></div>
    <div class="header-wrapper">
      <div class="header">We aim at building long-term
        collaborations, leading to the best
        solutions and creating the highest
        possible value.</div>

      <div class="header">
        Monitor your supply chain end-to-end
        24/7 and have all the vital information
        you need to take full control.
      </div>
      <button class="btn-primary">
        <a href="/supply-chain-request-form">Request quote</a>
      </button>
    </div>
  </div>
</section>