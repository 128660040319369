<div class="navbar" :class="[{ white: isWhite }, { 'animate__animated animate__fadeInDown': videoEnded }, 'transparent', 'animate__delay-1s']">
  <div class="logo">
    <a href="http://people-t.com/">
      <i class="header-icon"></i>
    </a>
  </div>
  <div class="nav-btn-group btn-group desktop">
    <a href="/type40d">
      <button class="btn-primary">Container<span class="sup">2.0</span></button>
    </a>
    <a class="active" href="/supply-chain">
      <button class="btn-primary">Supply Chain<span class="sup">4.0</span></button>
    </a>
  </div>
  <div class="btn-group desktop">
    <a target="_blank" href="https://rtm.people-t.com/#/loginpage">
      <button class="btn-primary">Log in</button>
    </a>
    <a href="/supply-chain-request-form">
      <button class="btn-primary">Request quote</button>
    </a>
  </div>
  <button class="navbar-menu mobile" @click="toggleMenu()"></button>
</div>

<div class="sidemenu-mobile" :class="{active: isMenuActive}">
  <div class="close-menu" @click="toggleMenu()">
    <div class="cross-1"></div>
    <div class="cross-2"></div>
  </div>
  <div class="models">
    <div class="models-item"><a href="/type40d#model">Container<span class="sup">2.0</span></a></div>
    <div class="models-item"><a href="/supply-chain">Supply Chain<span class="sup">4.0</span></a></div>
  </div>

  <div class="btn-group">
    <a target="_blank" href="https://rtm.people-t.com/#/loginpage">
      <button class="btn-primary">Log in</button>
    </a>
    <button class="btn-secondary" @click="openChat()">Chat with us</button>
  </div>
</div>