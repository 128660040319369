<template lang="html" src="./cookies-policy.html"></template>
<style lang="scss" scoped src="./_cookies-policy.mobile.scss"></style>
<style lang="scss" scoped src="./_cookies-policy.desktop.scss"></style>

<script>
import NavbarComponent from "@/components/navbar/navbar.component.vue";
import FooterComponent from "@/components/footer/footer.component.vue";

export default {
  name: "CookiesPolicy",
  components: { NavbarComponent, FooterComponent },
  data() {
    return {
      rights: [
        "the right to access your personal data, as well as to receive information about their processing",
        "the right to rectification of any inaccurate or incomplete personal data",
        'the right to erasure ("right to be forgotten")',
        "the right to restrict the processing of your personal data",
        "the right to data portability, including having your data directly transferred to another controller or receiving them in a structured, commonly used and machine-readable format (e.g. USB)",
        "the right to object to the processing of your personal data",
        "the right to withdraw any consent you have given, at any time and free of charge."
      ]
    };
  }
};
</script>
