<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="container">
          <div class="info">
            <div class="title">4. Request Quote</div>
            <hr>
          </div>
          <div>
            <div class="title">Enter contact details</div>

            <div class="contact form-control">
              <div class="input form-control">
                <input type="text" placeholder="First name (required)" v-model="form.enquiry.firstname" required>
              </div>
              <div class="input form-control">
                <input type="text" placeholder="Last name (required)" v-model="form.enquiry.lastname" required>
              </div>
              <div class="multiline">
                <div class="input form-control">
                  <input type="text" placeholder="Company (optional)" v-model="form.enquiry.company">
                </div>
                <div class="input form-control">
                  <input type="text" placeholder="Country (required)" v-model="form.enquiry.country" required>
                </div>
              </div>
              <div class="input form-control">
                <input type="email" placeholder="Email address (required)" v-model="form.enquiry.email" required>
              </div>
              <div class="input form-control">
                <input type="phone" placeholder="Phone number (required)" v-model="form.enquiry.phone" required>
              </div>
            </div>

          </div>
          <div v-if="!isMobile" v-html="captcha"></div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="summary">
          <div class="summary-title">Order summary</div>
          <hr>
          <div class="section">
            <div v-if="form.devices.doubleL.quantity > 0" class="section-title device-title">DoubleL<span class="device-quantity">({{form.devices.doubleL.quantity}})</span></div>
            <div v-if="form.devices.containerP.quantity > 0" class="section-title device-title">Container<span class="sup">1.5</span>P<span class="device-quantity">({{form.devices.containerP.quantity}})</span></div>
            <div v-if="form.devices.containerS.quantity > 0" class="section-title device-title">Container<span class="sup">1.5</span>S<span class="device-quantity">({{form.devices.containerS.quantity}})</span></div>
            <div v-if="form.devices.flexM.quantity > 0" class="section-title device-title">Flex Single User<span class="device-quantity">({{form.devices.flexM.quantity}})</span></div>
            <div v-if="form.devices.flexS.quantity > 0" class="section-title device-title">Flex Multi User<span class="device-quantity">({{form.devices.flexS.quantity}})</span></div>
            <div v-if="form.devices.coin.quantity > 0" class="section-title device-title">Coin<span class="device-quantity">({{form.devices.coin.quantity}})</span></div>

            <div class="section-title section-gap">Freight Monitoring as a Service</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'fmaas')">Edit</a>
              <div v-if="form.fmaas.short.months > 0" class="capitalize">{{ form.fmaas.short.months }} Short Term</div>
              <div v-if="form.fmaas.medium.months > 0" class="capitalize">{{ form.fmaas.medium.months }} Medium Term</div>
              <div v-if="form.fmaas.long.months > 0" class="capitalize">{{ form.fmaas.long.months }} Long Term</div>
            </div>
            <div class="section-title section-gap">Platform</div>
            <hr>
            <div class="section-details">
              <a @click="$emit('changeStep', 'platform')">Edit</a>
              <div v-if="form.platform.basic.users > 0" class="capitalize width-90">{{ form.platform.basic.users }} Basic</div>
              <div v-if="form.platform.standard.users > 0" class="capitalize width-90">{{ form.platform.standard.users }} Standard</div>
              <div v-if="form.platform.advanced.users > 0" class="capitalize width-90">{{ form.platform.advanced.users }} Advanced</div>
              <div v-if="form.platform.premium.users > 0" class="capitalize width-90">{{ form.platform.premium.users }} Premium</div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-section">
        <button
          :disabled="submitIsDisabled"
          class="btn-secondary"
          @click="$emit('submitForm')"
        >
        <div v-if="requestLoading" class="spinner"></div>
        <span v-else>{{submitButtonText}}</span>
      </button>
      </div>
    </div>
  </div>
  <!-- Mobile designs -->
  <div class="mobile">
    <div class="container">

      <div class="header">Order summary</div>

      <div class="header">Container specifications</div>
      <div v-if="form.devices.doubleL.quantity > 0" class="section-title device-title">DoubleL<span class="device-quantity">({{form.devices.doubleL.quantity}})</span></div>
      <div v-if="form.devices.containerP.quantity > 0" class="section-title device-title">Container<span class="sup">1.5</span>P<span class="device-quantity">({{form.devices.containerP.quantity}})</span></div>
      <div v-if="form.devices.containerS.quantity > 0" class="section-title device-title">Container<span class="sup">1.5</span>S<span class="device-quantity">({{form.devices.containerS.quantity}})</span></div>
      <div v-if="form.devices.flexM.quantity > 0" class="section-title device-title">Flex Single User<span class="device-quantity">({{form.devices.flexM.quantity}})</span></div>
      <div v-if="form.devices.flexS.quantity > 0" class="section-title device-title">Flex Multi User<span class="device-quantity">({{form.devices.flexS.quantity}})</span></div>
      <div v-if="form.devices.coin.quantity > 0" class="section-title device-title">Coin<span class="device-quantity">({{form.devices.coin.quantity}})</span></div>
      <hr>
      <div class="header">Freight Monitoring as a Service</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'fmaas')">Edit</a>
        <div v-if="form.fmaas.short.months > 0" class="capitalize">{{ form.fmaas.short.months }} Short Term</div>
        <div v-if="form.fmaas.medium.months > 0" class="capitalize">{{ form.fmaas.medium.months }} Medium Term</div>
        <div v-if="form.fmaas.long.months > 0" class="capitalize">{{ form.fmaas.long.months }} Long Term</div>
      </div>
      <div class="header">Platform</div>
      <hr>
      <div class="section-details">
        <a @click="$emit('changeStep', 'platform')">Edit</a>
        <div v-if="form.platform.basic.users > 0" class="capitalize width-90">Basic</div>
        <div v-if="form.platform.standard.users > 0" class="capitalize width-90">Standard</div>
        <div v-if="form.platform.advanced.users > 0" class="capitalize width-90">Advanced</div>
        <div v-if="form.platform.premium.users > 0" class="capitalize width-90">Premium</div>
      </div>

     <!--container-->
    </div>
    <div class="form">
      <div class="container">
        <div class="header">Make enquiry</div>
        <hr>
        <div class="header">Enter contact details</div>
        <div>
          <div class="input form-control">
            <input type="text" placeholder="First name (required)" v-model="form.enquiry.firstname" required>
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Last name (required)" v-model="form.enquiry.lastname" required>
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Company (optional)" v-model="form.enquiry.company">
          </div>
          <div class="input form-control">
            <input type="text" placeholder="Country (required)" v-model="form.enquiry.country" required>
          </div>
          <div class="input form-control">
            <input type="email" placeholder="Email address (required)" v-model="form.enquiry.email" required>
          </div>
          <div class="input form-control">
            <input type="phone" placeholder="Phone number (required)" v-model="form.enquiry.phone" required>
          </div>
        </div>
        <div v-if="isMobile" v-html="captcha"></div>
        <div class="navigation">
          <button @click="$emit('changeStep', 'previous')" class="btn-secondary">Previous</button>
          <button @click="$emit('submitForm')" :disabled="submitIsDisabled" class="btn-secondary">
            <div v-if="requestLoading" class="spinner"></div>
            <span v-else>{{submitButtonText}}</span>
          </button>
        </div>
      </div>
    </div>
    <!--Mobile--> 
  </div>
</div>
