<!-- Sustainability section -->
<section class="scroll-snap-item">
  <!-- Sustainability always displayed info -->
  <div class="main">
    <div class="img">
      <video autoplay muted loop playsinline class="video-hero">
        <source src="../../../../assets/media/videos/common/sustainability-section/SustainabilityHero.mp4" type="video/mp4" />
      </video>
      <!-- Mobile -->
      <div class="container-hero info mobile">
        <div class="info-item">
          <div class="info-item-header arrow">
            <span class="text-big">3.3</span>mn
          </div>
          <div class="info-item-desc">
            gCO<span class="sub">2</span> reduction p.a. per container
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header arrow arrow-2">
            <span class="text-big">1.3</span>Kl
          </div>
          <div class="info-item-desc">
            Less fuel p.a. per container
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            <span class="text-big">432.8</span>K
          </div>
          <div class="info-item-desc">
            gCO<span class="sub">2</span> offset p.a. per container
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container container-hero desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header arrow">
              <span class="text-big">3.3mn</span> gCO<span class="sub">2</span> p.a.
            </div>
            <div class="info-item-desc">
              That’s the equivalent of saving up to 33 gCO<span class="sub">2</span> emissions for every km
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header arrow">
              <span class="text-big">1.3K</span> litres p.a.
            </div>
            <div class="info-item-desc">
              That’s the equivalent of saving up to 0.013 litres for every km
            </div>
          </div>
          <div class="divider"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item"
            :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header with-icon">
              <i class="sustainable-icon icon"></i>
              <span class="text-big">432.8K</span> gCO<span class="sub">2</span> p.a. offset
            </div>
            <div class="info-item-desc">
              On average that’s the same as saving up to 16.9 trees for every container
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Sustainability</h5>
        <h3>
          Better for global business and the world
        </h3>
        <p>
          Small changes can have a big impact on a large scale. With no change to the standard container production
          line, Container<span class="sup">2.0</span> can drastically reduce the global transport and logistic
          industry’s carbon footprint making for a
          better world.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary" @click="$emit('modal', true);">Calculate your savings</button>
      </div>
    </div>
  </div>

  <!-- Sustainability learn more sub-sections -->
  <div id="sustainability-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2 maps">
      <div class="header">
        <h3>Reduce emissions or cost wherever you go</h3>
        <p>Using Container<span class="sup">2.0</span> would save fuel and reduce CO<span class="sub">2</span>, making it
          the most efficient and most environmentally friendly intermodal container globally.</p>
      </div>
      <div class="img" :class="currMap">
        <div class="path-wrapper">
          <RoadPath v-if="currMap === 'road'"></RoadPath>
          <RailPath v-if="currMap === 'rail'"></RailPath>
        </div>
      </div>
      <div class="subsection-info container">
        <div class="text">By using Container<span class="sup">2.0</span></div>
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currMap === 'rail'}" @click="changeMap('rail')">
              <div class="by">by Rail</div>
              <h3>USA</h3>
              <ul>
                <li>With no additional cost</li>
                <li>With no additional CO<span class="sub">2</span> emissions</li>
              </ul>
              <div class="cargo">
                <div class="column">
                  <div class="row-header-1">790k TEUs</div>
                  <div class="row-1">(298k units)</div>
                </div>
                <div class="column">=</div>
                <div class="column">
                  <div class="row-header-2">163.9k tn</div>
                  <div class="row-2">more cargo can be moved every year</div>
                </div>
              </div>
            </div>
            <div class="horizontal-item" :class="{active: currMap === 'road'}" @click="changeMap('road')">
              <div class="by">by Road</div>
              <h3>USA</h3>
              <ul>
                <li>
                  <p>Up to <span class="emphasized-value">986mn</span> kgCO<span class="sub">2</span></p>
                  <p>reduction every year</p>
                </li>
                <li>
                  <p>Up to <span class="emphasized-value">382mn</span> lt</p>
                  <p>fuel savings every year</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subsection-2 bottom">
      <div class="header">
        <h3>A cleaner, safer and 100% recyclable container</h3>
        <p>Instead of using wood for its internal lining, the Container<span class="sup">2.0</span> uses 100% recyclable
          material making it
          naturally
          more hygienic, odourless and sustainable than standard shipping containers currently in circulation.</p>
      </div>
      <div class="img first"></div>
      <div class="subsection-info container">
        <div class="horizontal-wrapper">
          <div class="horizontal-info">
            <div class="horizontal-item" :class="{active: currSlide === 'first'}" @click="changeSlide('first')">
              <h3>Wood free, pollution free</h3>
              <p>There is no need for dangerous chemical treatments to clean wooden interiors making it a safer
                container
                for the shipment, the user and the environment.</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'second'}" @click="changeSlide('second')">
              <h3>100% Recyclable,<br> 100% Responsible</h3>
              <p>Container<span class="sup">2.0</span> is made from 100% recyclable metal, with no change to a typical container
                production line.</p>
            </div>
            <div class="horizontal-item" :class="{active: currSlide === 'third'}" @click="changeSlide('third')">
              <h3>Hygienic food-grade</h3>
              <p>Pesticide and pest-free, clean and naturally antibacterial the Container<span class="sup">2.0</span> is
                ready to transport
                edibles
                without any further treatment, the only intermodal container like it in its class.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subsection-3">
      <div class="header">
        <h3>If the world used only <br class="desktop"> Container<span class="sup">2.0</span>?</h3>
        <p>The Container<span class="sup">2.0</span> uses 100% recyclable aluminium instead of wood for internal lining
          which takes one step
          closer in reducing the amount of natural resources the world uses and completely removing any waste created in
          the use of traditional intermodal containers.</p>
      </div>
      <div class="img">
        <video autoplay muted loop playsinline class="video-hero">
          <source src="../../../../assets/media/videos/common/sustainability-section/Sustainability_Trees.mp4"
            type="video/mp4" />
        </video>
        <!-- Mobile -->
        <div class="container-hero sustainability-learn-more-info mobile">
          <div class="img-text">If the world only used <span class="text-bold">Container<span class="sup">2.0</span></span></div>
          <div class="info">
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">200mn</span>
              </div>
              <div class="info-item-desc">
                Acres of forestry saved a year
              </div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">5.2 tr</span>
              </div>
              <div class="info-item-desc">
                Transport gCO<span class="sub">2</span> emissions offset
              </div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">Zero</span>
              </div>
              <div class="info-item-desc">
                Waste produced
              </div>
            </div>
          </div>
        </div>
        <!-- Desktop -->
        <div class="container-hero container desktop">
          <div class="img-text">If the world only used <span class="text-bold">Container<span class="sup">2.0</span></span></div>
          <div class="info">
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">More than 200mn</span> trees a year saved
              </div>
              <div class="info-item-desc">
                Acres of forestry would be saved a year instead of being felled for shipping containers
              </div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">5.2 trillion</span> of gCO<span class="sub">2</span> offset per year
              </div>
              <div class="info-item-desc">
                The carbon emissions created by the transport and logistics industry would be halved
              </div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-item-header">
                <span class="text-big">Zero</span> waste created
              </div>
              <div class="info-item-desc">
                Because all materials used are 100% recyclable, zero waste is created.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="subsection-info">
          <h3>No wooden floor, no waste and more trees <br class="desktop"> to offset carbon emissions</h3>
          <p>We replace the wood in our Container<span class="sup">2.0</span> to reduce its tare weight but also to save
            on the thousands of
            acres
            of
            trees cut down every year to line the inside of shipping containers. Less tare weight also means less fuel
            is
            needed for transportation too, but less wood also means there are more trees to absorb the CO<span
              class="sub">2</span> created,
            further
            offsetting CO<span class="sub">2</span> emissions.</p>
        </div>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
