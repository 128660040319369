<section class="scroll-snap-item">

  <div class="video-container desktop" :class="[{ hide: videoEnded }]">
    <video autoplay muted id="intro-video" @play="txtAnimation()" @ended="onVideoEnd()">
      <source src="../../../../assets/media/videos/supply-chain/intro.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div 
      class="overlay-text" 
      v-html="txtOvlay"
      :class="[{animate: animate}]"
    >
    </div>
    <div class="skip-intro" @click="onVideoEnd()">
      Skip intro
    </div>
  </div>

  <div class="main">
    <div class="img">
      <div :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent']">
        <span class="img-header text-bold">
          Supply Chain<span class="sup">4.0</span>
        </span>
      </div>

      <p class="img-text mobile" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">Our Supply Chain<span class="sup">4.0</span> system provides all the vital information you need to take full control anytime, anywhere.</p>

      <div class="img-icons"></div>
    
      <p class="img-text desktop" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">Our Supply Chain<span class="sup">4.0</span> system provides all the vital information you need to take full control anytime, anywhere.</p>

      <div class="personalize" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">
        <a href="/supply-chain-request-form">
          <button class="btn-primary">Request quote</button>
        </a>
        <a href="/supply-chain#connectivity">
          <button class="btn-primary">Learn more</button>
        </a>
      </div>
    </div>

    <div class="scroll" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">Scroll to explore</div>
  </div>
</section>