<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 958.58 595.28">
    <g id="Locations">
        <g id="Group_1866" data-name="Group 1866">
            <path
                id="Path_1786"
                data-name="Path 1786"
                class="cls-1"
                d="M263.07,346s1-8.06,3.1-9.1,5.27-6.19,5.27-6.19V325"
            />
            <path
                id="Path_1787"
                data-name="Path 1787"
                class="cls-1"
                d="M250.46,334.35l20.46-8,14.77-7.14s-1.2-15.06,0-17.87a72.23,72.23,0,0,1,3.44-6.59v-4.72l9.95.59c2.2-2.64,10.08-18.9,10.08-18.9l9.25-2.35"
            />
            <path
                id="Path_1788"
                data-name="Path 1788"
                class="cls-1"
                d="M303.11,268.76l7.51,2.75,3.61,6.1,5.27,8s4.2,4.38,5,6.1a78.6,78.6,0,0,1,2.52,8.17s-5.14,5-2.52,7.77,1.6,7.31,2.52,8.83,8.32,18,8.32,18l8.79,11.9,2.77,7.05,4-4.15h7.05v4.15l4.11,3.53,8.27,2.19"
            />
            <path
                id="Path_1789"
                data-name="Path 1789"
                class="cls-1"
                d="M340.18,363.89l4.26-7.06,5.19-3.11"
            />
            <path
                id="Path_1790"
                data-name="Path 1790"
                class="cls-1"
                d="M360.39,353.83H346.6"
            />
            <path
                id="Path_1791"
                data-name="Path 1791"
                class="cls-1"
                d="M299.08,345.83h6l7.13,2.15s3.63,3.12,6.07,3,3.84.66,6.35,0a15.54,15.54,0,0,1,7.24,0c2.65.47,4.52-1.28,7.76,0s7.51,0,7.51,0"
            />
            <path
                id="Path_1792"
                data-name="Path 1792"
                class="cls-1"
                d="M328.29,348.77c-.4,2.29,3.37-.65,3.36-4.43s2-6.21,4.12-8.16S328.69,346.47,328.29,348.77Z"
            />
            <path
                id="Path_1793"
                data-name="Path 1793"
                class="cls-1"
                d="M303.11,320s2.65,0,5.9,0,4.16.6,6.68,0,3.13-.59,6.22,0a14.22,14.22,0,0,0,5.44,0l6.47-7.91h8.61s2.26,3.36,4.3,3a60,60,0,0,1,7.82,0"
            />
            <path
                id="Path_1794"
                data-name="Path 1794"
                class="cls-1"
                d="M342.64,295s-2.5,3.22-2.59,6.36,0,10.23,0,10.23v10.69l2.59,12.22,5.29,7.43,4.31,7.83"
            />
            <path
                id="Path_1795"
                data-name="Path 1795"
                class="cls-1"
                d="M370.32,346s-1.76-5.66-3.82-6.73-6.41,1-8.47,0-9.92-3.4-9.92-3.4H335"
            />
            <path
                id="Path_1796"
                data-name="Path 1796"
                class="cls-1"
                d="M357,385.87a34.24,34.24,0,0,1,9.3,2.65c4.25,2,4.56,4.5,7.23,5.6s8,0,8,0l4.7,3.55s10.87-11.31,13.1-9.14,2.83,6.64,5.65,5.6,18.49-5.6,18.49-5.6l1.56.9,2.53,1.47,5.53,3.22a31,31,0,0,0,5.9,3.55c2.55,1,4.14,1,5.11,3.75s2.66,8.37,0,9.06-7.17,2.16-8.63,3.87-.49,1.94-4.4,4.82-21.1,7.94-21.1,7.94,4-7.14,4.18-10.06,3.3-4.93,4.46-6.57,6-8.77,4.86-9.06,0-7.3,0-7.3l1.56-3.22"
            />
            <path
                id="Path_1797"
                data-name="Path 1797"
                class="cls-1"
                d="M385.25,395.58s-2.2,1.38-2.06,3.06-4.36,6,2.06,8a43.63,43.63,0,0,1,12.3,5.76c1.81,1.3,6.93,7.57,8.69,9.34a17.45,17.45,0,0,0,4.6,3v22.76l-4.6,20.76L415,478.87S420.6,481,422.6,481s3.3-1.46,6.46-2.16a155,155,0,0,0,16-5.78c1.56-.78,7.43-4.8,7.43-4.8s2.58-3.2,5-2.6,8.72,2.6,8.72,2.6l4.43,10.58,6.24-5.77s4.82,1.61,7.28,3.76,4,3.5,5.8,9.32a155.89,155.89,0,0,0,6.09,15v4s7.85,2.53,9.84,3.91,5.56,5.22,10.75,9.5,9.44,3.77,11.59,4.88,7,10.13,8.11,12.61,0,12.2,0,12.2l-4.22,8.92-8.59,8.07-6.89,2.52s-10.82-9.59-13.38-8.51"
            />
            <path
                id="Path_1798"
                data-name="Path 1798"
                class="cls-1"
                d="M295.28,585.78s-7.82-4.09-7.43-6,2.89-9.19,2.89-9.19,6.42-.24,8.42,0,5.83,2,7.28,4.15,3,6.47,6.38,5,10.34,2.74,13.15,0,7.16-.69,7.68-5,4-8.87,4-8.87l8.45-7.8,11.31-8.12-6.74-7.8,3-7.84s10.22-10.23,10.41-12.53,3-5.47,5.06-6.72,9.19-4.93,9.19-4.93l10.18-6.35s1-8.76,0-11.37,0-6.59,0-6.59l20.2-14.38"
            />
            <path
                id="Path_1799"
                data-name="Path 1799"
                class="cls-1"
                d="M379.21,511.25s-10.22-4.2-13.9-3.64-4.9,5.24-7.32,3.64-13.31-3.64-13.31-3.64l-12.81-9.87-7.07-3.09-2-10.48s-5.86-3.95-7.42-4-4.06,5.09-7.22,4-12.77-4.21-15.08-4-13,.11-16.36,0-8.35,4-8.35,4H263s-4.86,8.93-4.21,10.48-1.13,8.42,0,9.94a8.56,8.56,0,0,1,2.18,6.66,69.31,69.31,0,0,0,0,9.34s.79,8.22,0,10.29-2.18,7.5-2.18,7.5,3.77,2.62,0,4.66-5.5,8-5.5,8l5.5,2.18s8.92-1.76,9.58-2.18a11.44,11.44,0,0,1,8.35-2.83c5.49.11,9.58,4.27,11.79,0s4.57-9.81,4.57-9.81,1-4.61,4.47-5.22,16.21-5.14,17.83-6.7,8.16-3.8,9.44-5.87,9.07-6.94,9.07-6.94l6.24-2.4,4.57-2.8"
            />
            <path
                id="Path_1800"
                data-name="Path 1800"
                class="cls-1"
                d="M278.07,570.43h12.57s7.18-2.47,9.35-3.36,5.42-5.06,5.42-5.06,5.91-1.76,7.53-2.83,4.54-5.72,5-7.59,1.14-9.26,0-11.17-2.65-10.89-2.65-10.89V514.41l-2.32-8.85,2.32-7.88"
            />
            <path
                id="Path_1801"
                data-name="Path 1801"
                class="cls-1"
                d="M260.75,523s5.27-.14,8.25,0,7.17,1.09,10.7,0,5.4-2.38,8.17-3.41,9.68-5.21,10.8-6.73,4.73-6.86,6.86-8.75a52.84,52.84,0,0,1,7.44-5.34c1.57-.85,14.38-10.39,14.38-10.39l2.58-4.23"
            />
            <path
                id="Path_1802"
                data-name="Path 1802"
                class="cls-1"
                d="M323.12,486.23c3.62-.4,8.4,2.35,10.83,0s5.12-2.28,6.39-4.51,4.47-9.6,4.47-9.6l2.34-7.13s2.51-2,3-4.1,0-13,0-13-1.18-4.63,0-6.49,2.22-4.25,4.17-5.45,4.14-2,5.86-4.9,3.64-9.58,3.64-9.58a3.08,3.08,0,0,1,2.11-3.82l.14,0c3.06-.83,10.18-5.87,10.18-5.87v-14.4l2.53-2.87"
            />
            <path
                id="Path_1803"
                data-name="Path 1803"
                class="cls-1"
                d="M328.83,421.09c3.74.54,6.72-1.31,9.17,0s9.18-2.82,11.91-2.67a11.39,11.39,0,0,1,6.44,2.67c1.65,1.27,4.92,0,4.92,0s1.64-1.76,4.9,0,7.95-2.16,10.56,0,3.26,2.5,4.8,5.69-.07,5.27,3,7.63,5.89,3,6.48,4.9-2.78,6,0,6.6a18.9,18.9,0,0,0,7.64,0c2.45-.41,10.78,0,10.78,0s2.58,4.53,6.18,4.52H425s1.29,4.63,3.72,5.85,11.2,0,11.2,0a71.61,71.61,0,0,1,8.26-3.79c2.38-.69,9.38-2.06,9.38-2.06l7.39-2.16s8,3.46,10.66,4.22,10-2.06,10-2.06l5.21,2.06,13.71-6.58"
            />
            <path
                id="Path_1804"
                data-name="Path 1804"
                class="cls-1"
                d="M480.64,477.13c.06-2.43-3.45-9.07,0-11.05s8.74-13.83,8.74-13.83"
            />
            <path
                id="Path_1805"
                data-name="Path 1805"
                class="cls-1"
                d="M504.49,482s-24.18-16.67-26.65-17.85-11-2.89-13.58-5.66-7.25-7.37-7.25-7.37"
            />
            <path
                id="Path_1806"
                data-name="Path 1806"
                class="cls-1"
                d="M435.71,456.64l6.11,3.15s-4.95,2.54,0,6.06,5.2,5.16,7.4,4.44,6.45-3.89,6.4-4.44-.36-9,0-9.21-.61-2.64,0-5.53,0-11,0-11-3.64-6.72-4.23-8.42a31.31,31.31,0,0,0-4.82-7.49c-1.61-1.71-8.05-8.37-8.05-8.37"
            />
            <path
                id="Path_1807"
                data-name="Path 1807"
                class="cls-1"
                d="M445.6,402.48c4.62-2.66,3.86-8.84,6.11-10.65s-.09-12.9,0-15.46,0-8.18,0-8.18,6.9-2.49,9.26-3.3,9.52-5,11.76-4.19,2.91,2.3,4.44,1.6,2.37-.7,3.63-1.6,3.6-.63,4.79,0a29.81,29.81,0,0,1,2.58,1.6"
            />
            <path
                id="Path_1808"
                data-name="Path 1808"
                class="cls-1"
                d="M381.79,396s3.23-4.22,3.8-5.77,2.77-14.79,2.84-16.36-.15-7.73,2-9S405,355.87,405,355.87"
            />
            <path
                id="Path_1809"
                data-name="Path 1809"
                class="cls-1"
                d="M387.57,352.33s13.75,6,15.16,6.08a79.89,79.89,0,0,1,8.37,1.93c.69.26,5.93,2.25,8.67,2.28s5.63-2,7.66-2.28,7-3.76,7-3.76,1.9,5.5,2.51,6,2.72,2.52,4.09,2.88,3.69.73,4.72,1.73,2.85,5.4,4.06,5.51,2,.53,3.3,0,.78-1.74,3.27,1,7.68,6.25,9.27,5.66,1.61-2.72,3-2.9,3.29-.65,4.75,0,4.6,2.36,4.55,2.9-1.46,1.73,0,2.25a16.65,16.65,0,0,1,5,3.2c1.91,1.64,4.72,5.38,7.14,6.67s7.45,1,9.26,2,5.63,4.46,6.44,6.17,6.49,5.58,6.49,5.58,8.72-.45,11.38,0,8.24.26,10.41.85,5.26-.46,6.55,0,1.57.88,5.3,2.77,5.47,5.68,7.69,5.4,1.67-1,6.35,1,9.34,2.53,10.45,4.17,4,3.9,5.09,7.74-.92,7.85,3.15,8.9,6.66.84,10.19,1.23,5.78.92,5.4,2.08-2,5.07-1.48,7.58,1,7.93-.82,8.78-6.81,3.49-7.78,4-3.52,3.68-3.52,3.68.35,4.24,0,5.4.46,6.95,0,9a61.19,61.19,0,0,1-2.91,8.76c-.45.62-6.69,8.48-7.31,9.4s.25,7.45,1.33,8.43,4,6.55,3.75,9.48-1.65,3.84-1.76,5.36,2.24,6.81,4,8.63,5.2,6.11,6.43,6.81,1.43,9.44,2.7,10,3.2,3.81,5.08,5.61,6.17,4.7,8,5.08,7.54,2.2,8.29,2.77,4.08-.47,6,3.16,6.74-3.16,6.74-3.16-5.53-3.36-6.74-3.88-.45-3.16-2.37-4-6.61-2.17-6.61-2.17,4.39,1.13,4.78,0a5.61,5.61,0,0,0,0-3.44c-.3-1.65,1.06-2.5,0-3.57s-5.24-1.94-4.78-3.1a19.38,19.38,0,0,0,1.57-4.56c.17-1.57-.59-6,0-7.3s-.07-3.66,1.37-4.35,2.44.46,4.87,0,4,1.23,6.15,0,5.57-2.91,7-3.71,6.16-3.49,9.2-2.94a11.89,11.89,0,0,1,5.47,2.94,9.53,9.53,0,0,0,2.56,1.1,13.67,13.67,0,0,0,4.33,0"
            />
            <path
                id="Path_1810"
                data-name="Path 1810"
                class="cls-1"
                d="M582.12,539.42s4.78-2.52,6.06-3.08,6.37-.78,6.22-2.41.57-5.46,1.69-5.93,3.8.17,5.37-1a54.28,54.28,0,0,1,5-3.24,73.51,73.51,0,0,0,7.39-4.32c1.07-.91,3.89-1.57,3.14-3.61s-2.65-6.58-3.14-8a111.09,111.09,0,0,1-2.3-11.54c.08-.72.58-4.15,3.76-4.59s7.24-3,7.24-3,3.94-2.77,6.29-2.85a39.22,39.22,0,0,1,6.32,0,24.08,24.08,0,0,0,4.65,0s.74-5.48,2.2-6.87,2.95-5.39,2.8-6.29a34.78,34.78,0,0,1,0-5.59c.13-.59,12-2.05,14.11-1.71s9.75,1.52,10.68,1.71,4.73,2.71,4.73,2.71"
            />
            <path
                id="Path_1811"
                data-name="Path 1811"
                class="cls-1"
                d="M661.89,494.2c-.87-.11-4.4.05-5.65,0s-12.25,1.65-13.08,2.27-6.82,3-8.69,3-8.4,1.06-9.5,0-4-1.07-5-3-7.51-5.26-7.51-5.26-4.7-4.72-5.09-5.88a6.39,6.39,0,0,0-3.43-3.08,48.88,48.88,0,0,0-5.75.95s-4.72,3.88-5,4.64-2.94,2.37-2.3,4.33c.32,1,.92,3,1.51,5.11a23.71,23.71,0,0,1,.79,3.33c0,.72-2.26,1.23-3.31,2.34s-.25,5.46-1.19,7.13-1.63,3.63-2.49,4.22a41.58,41.58,0,0,1-5,2s-6.28,1.52-7,0a8.26,8.26,0,0,1-.83-4c.13-.84.38-3.15,1.82-4.29s3.28-3.85,6-5.05,7.9-6.16,8.7-6.48"
            />
            <path
                id="Path_1812"
                data-name="Path 1812"
                class="cls-1"
                d="M607.37,522a57.54,57.54,0,0,0-1.08-6.41c-.53-2.49-3.22-4.74-5.4-6.2a12.49,12.49,0,0,1-4.14-4.89c-.8-1.53-4-3.31-4-3.31"
            />
            <path
                id="Path_1813"
                data-name="Path 1813"
                class="cls-1"
                d="M596.08,503.75s1.23-2.13,1.91-3.6.49-2.53,1.76-4.22a13.43,13.43,0,0,0,2.33-4.18c1-2.34-.41-3.27-.89-3.91s-2.92-4-3.2-5.59,1-2.16,0-3.54a58.21,58.21,0,0,1-4.66-6.57c-1.14-2.18-3.84-8.71-3.84-8.71l-4.47-.94-1.7-2.2s-3.1-4.12-3.79-6.06-3.74-2.54-3.79-4.1.56-7.72,0-8.92,0-3.53,0-3.53l2.54-1.4"
            />
            <path
                id="Path_1814"
                data-name="Path 1814"
                class="cls-1"
                d="M582.12,462.94a65.8,65.8,0,0,0-7.2-4c-1.75-.6-7-.88-8.64-.94s-6,1-8.21,0-10.88-2.43-10.88-2.43a40,40,0,0,1-6-3.59c-2-1.65-5.65-2-5.24-4.95s.54-6.15,2-7.92,2.58-2.33,4.8-3.45,4.67-2.62,6.7-3.59,13-7.31,14.37-8.89,1.58-3.54,4.11-4.39,2.13-1.6,5.44-2.16,12.31-1.77,13-2,3.38-.59,6.3-1.32,5.37-.84,7.47-1.66,8-7.92,10.45-9.71,1.17-6,2.92-6.52,4-.64,5.57-3a12.5,12.5,0,0,0,2-6c0-1,2.48-8.26,2.48-8.26s3.52-1.66,4.74-1.55,4.89,1.63,6.14,0,5.55-5.14,7-6.68,3.34-3.57,6.11-3.84a69.27,69.27,0,0,1,11,0c1.53.3,5.39.39,8.51,1.77s3.34,3.43,7.07,3.13,21.22-2,21.22-2,4.16,2,6,2,3.18,1,6.23,1.42,8.35-.5,9.82,0,2.95,2.06,4.91,3.31,5.12,5.1,5.12,5.1,6.39,2.3,9.11,2.57,5.63,1,7.67,0,5.63-5.26,7.34-5.23a20.41,20.41,0,0,0,6.8-1.55c1.2-.67,2-5.62,2-5.62"
            />
            <path
                id="Path_1815"
                data-name="Path 1815"
                class="cls-1"
                d="M773.64,425.54l-6.57,2.23-7.85,3.37s-7.7.55-8.43,0-5.5.08-6.83,0-12,.15-12.64,0-6.05-.94-6.05-.94S721,431.74,720,432s-2.82,1.12-3.79,0-2.61-4-3.23-4.18-4.63-.9-5.3,0S704.3,432,704.3,432h-4.63s-5.72-1.72-5.78-2.57-1.19-13.1-1.3-14.75.1-16.74,0-18.09-1.23-4.63-1.27-6-.05-13.45,0-14.91,6-6.59,6-6.59a28,28,0,0,0,0,4.67c.25,1.25,4.33,4.88,5.38,5.63a6.37,6.37,0,0,1,2.47,2.47c.81,1.44,1.85,2.86,3.32,3.26a24.93,24.93,0,0,1,4.47,2.32s3.8,4.62,6,5.1,3-.4,5.4,0,5.77,1.09,6.86,2.27,2.06,4.24,4.84,5,4.39,2.79,6.17,3.33,3.59-.16,4.93-2.12,1.84-6.89,2.72-8.49.81-6.08.81-7.42,2.73-3.85,3.62-4.82,2.43-2.75,2.43-2.75"
            />
            <path
                id="Path_1816"
                data-name="Path 1816"
                class="cls-1"
                d="M712.55,427c.78.21,3.88-4.48,4.47-6.58s.58-4.56,1.39-5.07a12.44,12.44,0,0,1,4.12-1.62,26.72,26.72,0,0,0,6.55-1.84c2.49-1.18,6.61-4,8.36-4.9a26.48,26.48,0,0,1,4.79-2.25c1.4-.38,3.4-2.53,3.4-2.53"
            />
            <path
                id="Path_1817"
                data-name="Path 1817"
                class="cls-1"
                d="M697,431s-4.51,3.47-5.84,2.31-5.46-3.72-6.4-3.85-6.68-1.54-6.68-1.54-1.14-4,0-4.5,5.88-3,6.68-4.46,2.65-2.34,4.67-2.87a20.25,20.25,0,0,0,3.45-1.23"
            />
            <path
                id="Path_1818"
                data-name="Path 1818"
                class="cls-1"
                d="M677.54,427.73c-.95-1.26-2.95-2.2-3.5-3.8s-2.6-4.09-4.81-3.39a6.75,6.75,0,0,0-3.84,2.51c-.78,1-.95,2.4-3.07,2.27s-3.9.33-5.3,0a26.37,26.37,0,0,1-3.65-1.39s-6.94-12.48-7.53-13.3-3-5.08-3.19-7,.82-5.48,0-6.66,0-3.07,0-4.56-.37-4.8-1.11-5.95a10.79,10.79,0,0,0-5.39-3.83c-2.64-.84-3-1.61-5.64-1.31a19.39,19.39,0,0,1-5.38,0c-1-.15-8.85-2.52-10.2-2.63s-4.48.34-5.88,0-6.2-4.24-6.2-4.24-.09-4.35,1.24-5.45,2.41-1.81,2.07-3.95-3.76-2.54-2.07-4.05a5.55,5.55,0,0,0,1.26-6.75,36.37,36.37,0,0,0-3.32-4.68c-1.34-1.72-4.7-4.33-5-5.9s-2.51-5.62-3.41-5.83,3.46.61,4.73,0,1.93-1.08,4.46,0,2.58,3.77,5.1,3.93,3.5,1.27,7,0,3.53-2.38,5.56-2.66,3.57.15,6.49-1.27,11.78-7.95,13.34-10,3.5-5.87,5.52-7.32,6.63-5.47,7.53-6,5.79-2.58,5.79-2.58l4.54-4.35,9.19-5.78a42.34,42.34,0,0,1,6.73-3.46c2.85-1,7.58-3.23,9.3-3.47s6.27-1.52,8.35-2a5.56,5.56,0,0,1,3.13,0"
            />
            <path
                id="Path_1819"
                data-name="Path 1819"
                class="cls-1"
                d="M697,370.6a51.2,51.2,0,0,0,4-6.63c1-2.42,2.94-7.09,2.78-9.52a101.78,101.78,0,0,0-2.78-12.78c-.8-2.76-.75-4.76-4-8.25s-9.83-9.3-14.46-11.58-6.5-1.45-10-4.62-9.7-8.67-9.7-8.67-13.68-5.94-14.43-7.41-7.92-5.88-12.7-8.5-11.82-6.05-14.28-8.34-2.6-3.6-5.86-4.79-7.18-2.27-10.25-3.59-8-3.51-10-3.84a5.1,5.1,0,0,0-2.63,0"
            />
            <path
                id="Path_1820"
                data-name="Path 1820"
                class="cls-1"
                d="M596.87,239.74c1,.07,3.29,2.66,5,3.48s4.25.67,6.07,1.39,4.35,5.14,7.08,5.55,6.61-.77,8.48,0a62.66,62.66,0,0,0,9.05,2.35c3.87.83,5.17,2.08,6.92,2.78s5,2,5.6,3.34,3.53,9.28,4.17,9.58a48.39,48.39,0,0,0,7.14,2.42c1.8.29,3.85,1.26,6.3,1.16s2.27-2.17,4.77,0,8.21,4.75,10,5.49,2.15,3,4.22,3.71,8.52,3,9.57,3.11,6.26,2.69,7.33,3.17"
            />
            <path
                id="Path_1821"
                data-name="Path 1821"
                class="cls-1"
                d="M669.45,261.71c-.93-.53-4-3.57-6.2-3.84s-6.48.43-7.1,0-3.22-2-4.36-1.57-6.18,1.57-6.18,1.57"
            />
            <path
                id="Path_1822"
                data-name="Path 1822"
                class="cls-1"
                d="M625.21,250.54s-.08,3.56,0,4.42-1.89,5.44,0,6.5,3,.09,3.13,3a10.38,10.38,0,0,1-1.55,5.69c-.4.87-1.74,2.6-1.58,4.49s-4,9.87-4,9.87a22.85,22.85,0,0,0,0,3.51,4.44,4.44,0,0,1-2,3.55,55.91,55.91,0,0,0-5.6,4.07l-1.46,3.1s1.45,4.06,0,4.85-6.2,6.38-6.85,6.73-1.64,5.75-2.48,6.74a61.94,61.94,0,0,0-3.95,7.3,33.66,33.66,0,0,1-2.15,4.83c-1,1.61-7.28,9.59-7.28,9.59s-1.63-3.14-2.94-3.67-4.79-2.69-4.3-4a92.7,92.7,0,0,0,1.63-10.21,20.15,20.15,0,0,0-3.12-7.71,69.6,69.6,0,0,0-6.89-7.86c-2.36-2.48-2.47-3.5-5-4.17s-7.24-4.26-7.06-2.44-.86,4.39,0,6.61,1.4,10.18,1.49,11.71-1.39,5.11,0,7.3a30.74,30.74,0,0,1,2.91,6.77c.61,1.92,5.9,8.44,6.19,9.1s1.48,4,1.48,4,3,.8,4.32,0,6.27-3.78,7.5-4a6,6,0,0,1,2.16,0"
            />
            <path
                id="Path_1823"
                data-name="Path 1823"
                class="cls-1"
                d="M573.36,343.77s-4.7.88-5.54,0a14.92,14.92,0,0,0-6.11-3.56c-2.65-.67-4.76-.29-7.63-1.28a40.19,40.19,0,0,0-8.63-2.26c-2.88-.3-7.62.53-10.87,0s-8.35-.61-10.41,0-11.87,1.21-13.14,1.19-4.69.57-7.22,0a35.14,35.14,0,0,0-7.55-1.19c-3.13-.08-9.23,2-10.73,2.26s-5.61,1.12-7.7,0a29.78,29.78,0,0,0-7.27-2.26c-2-.4-6.82-1.28-8.5-1s-3.31,3.69-7.07,3.28-7.34-1-8.85-1.07-4.41-1.43-7-1.19-6.08.91-9.56,1.19-8.23,2.89-9.66,2.35-5.8-1.63-7.22-2.35-2.33-2.53-4.07-1.19a43.85,43.85,0,0,0-5,5.64s-.75,2,2.11,2.62,4.85,2.34,8.07,1.39,1.92-1.13,6.12-1.39,3.4-.36,5.24,0,5.16,4,6.11,4.77,2.24-.19,4.23,0,3.2-.68,5.29,0,3.37.74,5.38-1.08,3.81-3.85,5.07-4.85a19.86,19.86,0,0,0,2.91-3.56"
            />
            <path
                id="Path_1824"
                data-name="Path 1824"
                class="cls-1"
                d="M445.6,338.32s-4.33,3.3-4.64,4.15-1.3,3.69-1.3,3.69v3.38"
            />
            <path
                id="Path_1825"
                data-name="Path 1825"
                class="cls-1"
                d="M442.63,349.92a3.64,3.64,0,0,1-1.47,1.81,11.93,11.93,0,0,0-3.31,3.06c-.32.64-1.28,4.08-1.28,4.08l-2.45,1.42s-1.52-5.31-1.76-5.5a22.68,22.68,0,0,1-1.17-3.06l1.17-1.81"
            />
            <path
                id="Path_1826"
                data-name="Path 1826"
                class="cls-1"
                d="M431.19,353.43c-.2-.4-2.93.68-3.59,0s-6.61.24-7.58,0a27.8,27.8,0,0,0-4.47,0s-2.94,2.38-3.94,2.35-4.62,0-4.62,0l-2.22,3.46L406,361.3"
            />
            <path
                id="Path_1827"
                data-name="Path 1827"
                class="cls-1"
                d="M466.63,335.26s1.78,3.22,1.37,4.79a50.18,50.18,0,0,1-2.21,5.68c-.71,1.61-2,6.25-2.6,7.23s-2.87,2.64-2.36,4.73,2,5.9,2.36,7.1,2.6,6.44,2.6,6.44l2.21,5.4h0"
            />
            <path
                id="Path_1829"
                data-name="Path 1829"
                class="cls-1"
                d="M498.67,336s3.1-1,3-3.11a6,6,0,0,0-1.95-4.83c-1.64-1.8-2.14-3.25-3.72-4.54s-2.17-1.08-2.21-4.19.7-3.94,0-5.83-3.1-7.32-3.18-8.38"
            />
            <path
                id="Path_1830"
                data-name="Path 1830"
                class="cls-1"
                d="M469.05,314.42a50.31,50.31,0,0,1,5.76,0c.9.2,4.39,1.17,6.39,1.65a22.64,22.64,0,0,1,6,2c1.48,1,7.21,3.79,7.21,3.79"
            />
            <path
                id="Path_1831"
                data-name="Path 1831"
                class="cls-1"
                d="M504.49,333.7c1.1-2.48,1-4.91,2.54-6.1s1.71-1,2.67-3.16.62-3.88,2.3-4.3,3.87-.31,5-1.53,3.23-2.62,3.84-3.95,1.43-3.17,1.2-4.08-5-3.26-5-3.26"
            />
            <path
                id="Path_1832"
                data-name="Path 1832"
                class="cls-1"
                d="M528.31,335.88a9,9,0,0,0-2-4.95c-.83-.9-2.08-1.32-3.16-3s-4.62-7.79-5-8.47a2,2,0,0,1,0-1.47"
            />
            <path
                id="Path_1833"
                data-name="Path 1833"
                class="cls-1"
                d="M506.64,327c.66,2.12-1.33,3.93,0,4.6s2.59,4.37,2.59,4.37-1.63,3.25-1.18,4a19.22,19.22,0,0,1,2.37,6.17c.39,2.69-.86,5.4-1.19,8.15s-.77,4.35,0,5.82-.28,5.41,1.19,6.76,5.23,7.75,6,8.7,2.32,5,3.76,6.43a37.41,37.41,0,0,0,9.76,7.52c4.84,2.41,7.75,2,9.63,3.77s4.07,3.27,4.37,4.67-.22,2.7,0,4.64-.54,4.84,0,5.6"
            />
            <path
                id="Path_1834"
                data-name="Path 1834"
                class="cls-1"
                d="M543.92,408.19c.78-.19,10.43-2.56,10.77-4.81s2.23-7.28,2.95-8.08a48.64,48.64,0,0,1,5.1-4.63c1.27-.88,4.57-7,4.77-7.6a35.53,35.53,0,0,0,0-4.84"
            />
            <path
                id="Path_1835"
                data-name="Path 1835"
                class="cls-1"
                d="M539.9,393.43a16,16,0,0,1,4.46-2.57,17.54,17.54,0,0,1,4.43-1.44,2.44,2.44,0,0,0,2.17-2.08c.3-1.15,1.87-2.15,3.61-3.11s4.39-4.19,5.88-5.7,3.49-4.71,3.49-5.32,5.06-.91,5.24,0,6,2.67,6.85,3.09,1.78,1.65,7.2.88,9.8,0,12.24-.88a75.83,75.83,0,0,1,7.61-1.81"
            />
            <path
                id="Path_1836"
                data-name="Path 1836"
                class="cls-1"
                d="M569.09,373a17.78,17.78,0,0,0,1.54-4.93c.17-2.08-1.63-3.78,0-6.73s4-6.87,4.16-7.61-.56-3.81-1.79-4-2.2.57-3.91.92-4.44-.46-5.52.95.5,1.41-3.34,2.1-9.17,1.69-10.37,1.95-4.08.16-4.72,1.49,1.25,5.27,2.83,6.34,4.49,2.58,7.46,4.56,7.23,4.93,7.23,4.93,3.78-1.91,4.25-2.58"
            />
            <path
                id="Path_1837"
                data-name="Path 1837"
                class="cls-1"
                d="M545,360.26a7.06,7.06,0,0,1-4.45,0,37.73,37.73,0,0,1-5.46-2.66c-1.38-1-5-3.32-7.18-2.74s-6,3.69-6.86,2.74-3.9-1.13-6-1a57,57,0,0,0-5.94,1"
            />
            <path
                id="Path_1838"
                data-name="Path 1838"
                class="cls-1"
                d="M509.1,360.37a41.69,41.69,0,0,1-5.85-1.14,37.53,37.53,0,0,1-4.75-2.57c-.73-.52-3.6-1.25-3.63-2.24s-2-2.75-2-2.75h-2.95l-3.5-4-2.51-8.61"
            />
            <path
                id="Path_1839"
                data-name="Path 1839"
                class="cls-1"
                d="M536,357.87a6.48,6.48,0,0,0,.91-3.57,11.31,11.31,0,0,0-2.77-5.41c-1.05-1-4-4.23-3.89-4.84s-1.52-4-1.87-5a8.13,8.13,0,0,1,0-2.95"
            />
            <path
                id="Path_1840"
                data-name="Path 1840"
                class="cls-1"
                d="M538.1,357.81l-2.65,3.53-2.78,2.51v5.43s.16,4.05-1,4.55a15.53,15.53,0,0,1-4,1.52c-1,.06-9.71,0-9.71,0l-6,3.32-7.31,4.46s-6.84.13-8,0-6.81,3-7.36,3.69-6,.94-6,.94L478.1,390l-5.82,1.65s-4.41.76-5.09.95a35.11,35.11,0,0,1-6.32,0c-1.12-.19-3.47.73-4.55,0s-4.41-2.6-4.41-2.6"
            />
            <path
                id="Path_1841"
                data-name="Path 1841"
                class="cls-1"
                d="M450,395.72s4.58,3.34,6,3,2.95-4,2.95-4l2.7-2.34"
            />
            <path
                id="Path_1842"
                data-name="Path 1842"
                class="cls-1"
                d="M484.25,407.87c-1.61-.41-4.42-3.47-5.62-3.51s-2.8-.94-4.45,0a5.69,5.69,0,0,1-4.84,0,12.51,12.51,0,0,0-4.9-1c-1,.2-2.19-.78-2.87-1.64s-4.26-3.91-4.26-3.91"
            />
            <path
                id="Path_1843"
                data-name="Path 1843"
                class="cls-1"
                d="M516.51,405.09c-.81.58-4.27,4.45-5.2,4.81-1.23.4-4.57,1.82-7.43,3.18s-2.86,1-6.58,1.81-2.63.09-5.36,0-3.1-2.48-3.78-3-2.67-3.6-3.72-4.49-.63-2.54-1.26-4.6.55-3.4,0-4.88,2.2-10.12,2.56-11l2.43-5.61c1.11-1.62-3-1.87-2.39-3.73s2.4-5.3,2.72-6.86-.52-3.73,0-6.25,1.82-5.06,2.21-7-1.18-5.23,0-6.85,1.66-4.2,2.36-5.34,5.29-5.51,5.74-6.25,1.6-2.22,1.6-2.22"
            />
            <path
                id="Path_1845"
                data-name="Path 1845"
                class="cls-1"
                d="M544.18,397.29a20.93,20.93,0,0,0-4.15,5.39c-.66,1.4-1.58,2.82-2.47,5s-2.88,4-2.67,6.54,1.07,8.44-.82,9.75a15.9,15.9,0,0,1-5.1,2.4,14.41,14.41,0,0,0-1.9.8,7.06,7.06,0,0,1-2.83.86,6.38,6.38,0,0,0-4.36,2.94c-.43.68-2.52,1.74-4.39,2s-6.23,1-7.47,1.41-4.08.89-5.26,2-2.92,1.73-1.68,4.24,1,4.46,3.74,5.57,1.67,3.27,6.34,2,5.62-3.05,5.8-4.48-1.6-2.74,1.31-3a41.31,41.31,0,0,0,6.78-1.53c2.35-.57,2.93-.52,3.92-2.71s1.77-1,1.1-3.38"
            />
            <path
                id="Path_1846"
                data-name="Path 1846"
                class="cls-1"
                d="M527.06,428.45l3.08,4.67s-.73,1.16,2,1.24,4.5-1.75,6-1.24,4.56,1.24,4.56,1.24h1.6"
            />
            <path
                id="Path_1847"
                data-name="Path 1847"
                class="cls-1"
                d="M516.51,442.26c1.78.23,3.49,2.18,6.1,2.43a20.87,20.87,0,0,0,4.47,0,6.94,6.94,0,0,1,3.14,1.25l1.83,2.26s.93,1.8-.84,2.35-4.38,2.54-5.33,2.83-7-.77-7.82,0"
            />
            <path
                id="Path_1848"
                data-name="Path 1848"
                class="cls-1"
                d="M522,459.6c1.86-.29,3-.57,3.45-2.14a5,5,0,0,0,0-2.81v-1.23"
            />
            <path
                id="Path_1849"
                data-name="Path 1849"
                class="cls-1"
                d="M524.4,457.38a10.44,10.44,0,0,0,1.48,1.7c.69.57,2.37,4,2.81,4.75s3.66,0,1.36,2.77-2.63,4.22-2.63,4.22,6.84,5.75,7.75,6.24,3.83,1,5.56,1.77,3.65,2.51,4.51,3.05a28.36,28.36,0,0,1,4.45,3.43c2.1,1.93,2,3.31,4.85,5.39s5.36,5,7.09,5.28,3.28-.42,4.69,1.52,4.6,5.31,4.6,5.31"
            />
            <path
                id="Path_1850"
                data-name="Path 1850"
                class="cls-1"
                d="M612.61,444.91c-3.78-.32-3.56-1-5.08,0a23.77,23.77,0,0,1-5.23,2.2c-1.83.6-2.47,1.17-3.82,1.1a11.13,11.13,0,0,1-3.42-1.1l-2.5-1.17"
            />
            <path
                id="Path_1851"
                data-name="Path 1851"
                class="cls-1"
                d="M601,447.28s1.18,2.25,1.59,3.21a18.88,18.88,0,0,1,1.3,4.79c.2,1.85-2.14,3.2,0,5.67s3.26,2.95,5.77,5.57,2.8,7.44,2.8,7.44-5,2.8-5,3.7-2,1.31,1.36,3.26,2.48,2.44,5.77,3.79,4.12,2.12,4.68,3.24,1.38,3.18,1.38,3.18"
            />
            <path
                id="Path_1852"
                data-name="Path 1852"
                class="cls-1"
                d="M645.61,469.39c-1.77.26-3.24,1.46-5.18,2.48s-3.23,2.24-5.16,1.67-2.53-2.3-4.3-1.67-3.19,0-5,0-3.18-1.87-5-1.62a18.55,18.55,0,0,0-5.44,1.62c-1,.63-3.1,1.67-3.1,1.67"
            />
            <path
                id="Path_1853"
                data-name="Path 1853"
                class="cls-1"
                d="M647.81,466.92s-4.49-.09-5.21,0-4.22-2.05-5.12-2.57-5.23-2.09-5.3-3.51,1.32-2.55,0-4.68-3.39-6.59-4.52-7.73-2.2-.48-4.14-2.26-3.33-3.22-4-3.41-1.53-.92-.82-2.56,2.43-5.93,2.43-5.93,5.92.19,6.58,0-.78-.42,1.55,0,5.87-1.81,6.88-2.35,4.09-1.53,5.36-2.35,4.83-2.89,5.49-2.89-.38-3,.87-3.83,4.46-1.25,4.46-1.25"
            />
            <path
                id="Path_1854"
                data-name="Path 1854"
                class="cls-1"
                d="M644.13,466.92c.2-1.53,0-4.56,1.6-6.17s2.08-3.23,3.63-3.54,4.72-.23,5.62-.85,3.82-4.13,4.1-6.07a44.4,44.4,0,0,0,0-7.82c-.16-3.08-1.7-5.3-1.87-6.81s-1.3-8.36-1.3-8.36l-2.5-4.44"
            />
            <path
                id="Path_1855"
                data-name="Path 1855"
                class="cls-1"
                d="M703.73,359.35s5.52-.87,8.37-1.65,3.66-1.21,8.36-3.19a94.07,94.07,0,0,0,10-5.32c2.09-1.08,7-3.87,7-3.87"
            />
            <path
                id="Path_1856"
                data-name="Path 1856"
                class="cls-1"
                d="M444.15,337.55s2.47-1.73,2.83-3.1a16.9,16.9,0,0,0,0-4.38s1.35-4.32,1.13-6.54-.1-5.65-1.13-7a5.92,5.92,0,0,1-1-3.37"
            />
            <path
                id="Path_1857"
                data-name="Path 1857"
                class="cls-1"
                d="M451.91,309.17c.69.75-1.25,7.07-1.13,8.27s.12,4.75,2.32,6,2.76.79,5.33,2.23,5.22,2.56,6.1,4.42a27.9,27.9,0,0,1,1.46,5.77"
            />
            <path
                id="Path_1858"
                data-name="Path 1858"
                class="cls-1"
                d="M469.05,317.94c.42,1-.32,3.77-1.2,5.33a27,27,0,0,1-3.37,4.53C463.26,329.18,463,333,463,333v2.7"
            />
            <path
                id="Path_1860"
                data-name="Path 1860"
                class="cls-1"
                d="M468.06,319.68s2.17-2.58,3.71-4.05,2.59-5,3.79-6.5.05-1.75,2.84-5,4.16-5,5.69-6.82,3.49,0,4.13-5a44.28,44.28,0,0,1,2.1-9l2.78-2.43s4.71-5.56,5-6.75.38-3.44,1.47-3.81,5.22-3.08,6-4.68,4.06-3.41,5.78-11.21,2.53-17.3,2.94-18.2,2.9-7.1,5.4-8.83,7.56-4.4,9.9-5,5.68-3.91,7.79-4.92a24.71,24.71,0,0,1,6-2c1.11-.11,7.63-4.38,8.49-6.29s5.12-5.86,5.12-5.86-2.23-9.86-3.8-12.3-1.41-8.78-2.2-10.15-5.1-4.53-5.69-8.8.17-18.33,0-19.92,2.63-4.87,2.8-8.25,0-12,1-12.61,5-2.84,5.49-3.62,1.07-6.57,2.41-7.9,2.39-1.83,3.32-3.9,1.2-4.87,2.54-5.06-.16,3.19,4.32,0,3.45-6,5-6.55,3.14-.07,6.54-2.57S584,98,584.87,97.29,590.22,90,590.22,90s3.9-4.17,4.17-5.68-2-5.16-2.28-6.3-4.49-3.83-2.83-5.88,5-3.29,6.15-5.92a9.74,9.74,0,0,0,0-6.73c-.41-1.21-.42-1.82,1.56-3.36s3.17-5,4.5-7.34S605,44,606.85,43.6a10.34,10.34,0,0,1,5.73,0c1.94.72,4.59-.82,7.21,0s9.52,1.33,10,2.73S629,49.9,631.39,50s2.72-2.49,3.77,0,2.3,6.08,3.44,9.44S640.19,76,640.92,77s2.4,9.75,1.92,11.77,0,9.72,0,10.66-2.13,7.14-1.92,8.77,1.94,9.56,1.92,10.4-1.1,7.55-1.1,9.15,1.1,8.33,1.1,8.33A6.07,6.07,0,0,1,646.4,141c.68,4-.29,6.73,0,8.52s1.34,9.87,0,11.74-7.12,8.13-7.8,9.84-3.44,9.85-3.44,9.85-4.52,2.75-5.33,2.45-5.34-3.14-8.53-3.57-9,.47-9.76,0-8.22-1.43-8.22-1.43"
            />
            <path
                id="Path_1861"
                data-name="Path 1861"
                class="cls-1"
                d="M665,173.16c-.8.14-6-.81-7,0s-2.92,3.17-5.16,3.05-3.24,1-4.45,0-3.31-1.48-4.1-1-5.44,2.82-6.36,3.39-5.38,1.35-5.38,1.35"
            />
            <path
                id="Path_1862"
                data-name="Path 1862"
                class="cls-1"
                d="M590.85,9.5s5,5.78,7.51,7.6,7.75,9.41,9.21,10.66,2.95,1.87,3.18,4.7-1.32,10.7-1.32,10.7"
            />
            <path
                id="Path_1863"
                data-name="Path 1863"
                class="cls-1"
                d="M553.85,202.08A46,46,0,0,1,543.13,200a32,32,0,0,0-9.76-1.5c-3.72-.07-9,2.34-11.61,2.34a62.07,62.07,0,0,1-8-.83c-3.15-.42-7.34-2.48-9.14-1.5s-2.6,2.41-5.43,2.33-7.91-2.34-10.63-4.1-4.76-3.24-6.95-3.89-5.67-5.26-5.67-5.26-1.42,8.77-1.28,9.15,2.34,4.79,3.1,5.36,3.78,4.7,3.85,6.38a36.62,36.62,0,0,0,1.35,5.84s4.73,7.92,5.61,12-.71,5.39,0,7.84,1.38,8,2.81,10.6,7.81,12.38,7.81,12.38L496,275.43"
            />
            <path
                id="Path_1864"
                data-name="Path 1864"
                class="cls-1"
                d="M488.3,234.46a24.13,24.13,0,0,0,10.36,0c4.44-1.17,4.72-2.37,6-2.48s9.62,1,9.62,1"
            />
            <path
                id="Path_1865"
                data-name="Path 1865"
                class="cls-1"
                d="M466.1,236.4s-.26,7.82,0,8.7-3.17,6.87-2.26,8.74,4.88,5.53,4.86,6.82-2.39,9.56-3.65,10.64-3.5,5.45-4.2,7.79a41.79,41.79,0,0,0-1,9.92c.11,2.23.55,6.95,2.31,8.82a57.61,57.61,0,0,1,3.78,4.66l2.24,2.4s-.8,6.86-1.08,10.34-.58,2.71-2,3.51-3.68,2.49-6.56,4.49-3.19,2.15-4.78,3.91-4.71.42-6.53,0"
            />
            <path
                id="Path_1866"
                data-name="Path 1866"
                class="cls-1"
                d="M460.75,276.45s3.88,4.3,6.6,4.27,5.17.13,9.92,0,6.55-.68,8.85-1.57a8,8,0,0,1,4.9,0"
            />
            <path
                id="Path_1867"
                data-name="Path 1867"
                class="cls-1"
                d="M474.61,240c-2.14.79-3.36,3.29-4.8,4.43s-6.09,6.62-6.09,6.62"
            />
            <path
                id="Path_1868"
                data-name="Path 1868"
                class="cls-1"
                d="M404.77,359.24c-.18.61,3.06,6.33,4.24,7.9s3,7.8,4,9.05,3.57,6,6.22,6.5a21.15,21.15,0,0,0,7,0c1.49-.3,6.05-2.75,6.24-4.77s-.63-3.7,1.29-4.3,2.49-.3,4.34-1.28,7.65-5.11,7.65-5.11"
            />
            <path
                id="Path_1869"
                data-name="Path 1869"
                class="cls-1"
                d="M423.64,389.76s-.07-1.71,0-3.29,0-4.18,0-4.18"
            />
            <path
                id="Path_1870"
                data-name="Path 1870"
                class="cls-1"
                d="M429.24,392.64h-6.67c-1.83.09-3.67.09-5.51,0-.66-.19-6.16,0-6.16,0"
            />
            <path
                id="Path_1871"
                data-name="Path 1871"
                class="cls-1"
                d="M475.89,463.89c-.15-.89-.73-7.55,0-9.72s.45-4.75,1.62-7,1.53-5.08,2.1-7.3a17.39,17.39,0,0,1,3.19-5.71c1.05-1.41,1.82-2.7,1.3-4.7a59.89,59.89,0,0,1-1.3-6.23c0-.66,5.45-3.08,5.86-3.69s3.08-5.5,3.08-5.5"
            />
            <path
                id="Path_1872"
                data-name="Path 1872"
                class="cls-1"
                d="M349.63,463.36s2.82,2.51,5.16,2.67,4.37,3.23,5.44,3.87,0,.56,3.22,1.6,5.22.72,6.64,3.33,1.61,7.12,3.06,7.66,3.18,1.74,4.91,2.19,10.23,2.56,10.23,2.56"
            />
            <path
                id="Path_1873"
                data-name="Path 1873"
                class="cls-1"
                d="M344.9,508c-.1.71-3.15,5.79-3.21,6.75s-1.89,6.38,0,8.64,4.26,6.47,5.61,7.33,5.3,6.14,5.3,6.14a26.16,26.16,0,0,1-3.84,2.6c-.84.23-6.45.81-7.07,0s-4.07-1-5.51-.91-4.82,1.17-5.73,0-3.11-4.6-3.11-4.6-5.73-2-7.63-3.22a22.28,22.28,0,0,1-4.21-4.57"
            />
            <path
                id="Path_1874"
                data-name="Path 1874"
                class="cls-1"
                d="M516.51,519.22c1.57-.14,4.22-2.34,6-3s3.08,1.19,6.78,0,4.77-2.69,7.66-2.14,5.23.78,6.06,2.14,3,7.4,3,7.4"
            />
            <path
                id="Path_1875"
                data-name="Path 1875"
                class="cls-1"
                d="M523.84,570.75l-5.73-3.66"
            />
            <path
                id="Path_1876"
                data-name="Path 1876"
                class="cls-1"
                d="M635.32,499.61c1.5.86,4.31,3.66,6,3.61s2.57-.32,5.45,1.69,4.75,4.17,8.84,5,10.78,4.07,15,5.27S682,519,684.25,519.9s11.25,2.41,14.63,2.22,9.69.27,13.08,0,21.09-4.59,27.74-4.13,8.22-1,14.45,1.91,12.16,7,19.05,8.67,22.34,6.52,25.15,6.81-1.76-.18,9.46-1.09a97.59,97.59,0,0,0,16.91-2.49c2-.41,13.42,6.61,15.85,6.41s8.35-4,8.86-6.41,3-4.1,0-4.42-5.19-7.73-5.85-9.39-2.69-4.22-5-7-3.75-4.73-1.78-6,4.68-2.24,9.54-1.69,7.34-.38,8.2,1.69-2.44,1.27,2.4,6,9.48,7,13,8.94,13.21,2.9,15.65,4.21,14.86,6.5,14.86,6.5l4.67,1.18,5.47-4.41"
            />
            <path
                id="Path_1877"
                data-name="Path 1877"
                class="cls-1"
                d="M869.83,549c-.2-1.62-2.62-4,0-5s2.6-2.75,1.58-3.84-13.2-5.45-13.83-5.92a16.52,16.52,0,0,0-6.08-1.72c-1.78.05-1.31-4.32-1.31-4.32"
            />
            <path
                id="Path_1878"
                data-name="Path 1878"
                class="cls-1"
                d="M855.11,508.62c-.05.93-1.49,5.24-1.65,6.41s-1.69,1.9-1.78,4.26,1.56,3.69,1.78,5.21-3.21,6.22-3.21,6.22"
            />
            <path
                id="Path_1879"
                data-name="Path 1879"
                class="cls-1"
                d="M817.88,509.86c1.06-1.22,2-7,2.88-7.56a6.28,6.28,0,0,1,3.28-1.56c2.63-.68,5-4,7.83-2.4a29.59,29.59,0,0,0,5.79,2.4,13.16,13.16,0,0,1,3.74,2.43"
            />
            <path
                id="Path_1880"
                data-name="Path 1880"
                class="cls-1"
                d="M112.46,88.92C117,91.3,130,103.55,136.88,104.09s17.3-4.83,21.32-6.44,6.15,6.7,13.54,0,20-11.28,23.47-17.77,0-21,0-21a12.53,12.53,0,0,1-16.53,0c-8.84-7.13-25.83-8.11-25.83-8.11h-16l-16.79,8.11-7.63,13.86Z"
            />
            <circle
                id="Ellipse_192"
                data-name="Ellipse 192"
                class="cls-2"
                cx="113.99"
                cy="89.13"
                r="7.65"
            />
            <circle
                id="Ellipse_193"
                data-name="Ellipse 193"
                class="cls-2"
                cx="350.99"
                cy="351.17"
                r="7.65"
            />
            <circle
                id="Ellipse_194"
                data-name="Ellipse 194"
                class="cls-2"
                cx="416.99"
                cy="345.27"
                r="7.65"
            />
            <circle
                id="Ellipse_195"
                data-name="Ellipse 195"
                class="cls-2"
                cx="463.99"
                cy="362.95"
                r="7.65"
            />
            <circle
                id="Ellipse_196"
                data-name="Ellipse 196"
                class="cls-2"
                cx="422.99"
                cy="382.6"
                r="7.65"
            />
            <circle
                id="Ellipse_197"
                data-name="Ellipse 197"
                class="cls-2"
                cx="252.99"
                cy="549.13"
                r="7.65"
            />
            <circle
                id="Ellipse_198"
                data-name="Ellipse 198"
                class="cls-2"
                cx="496.99"
                cy="504.13"
                r="7.65"
            />
            <circle
                id="Ellipse_199"
                data-name="Ellipse 199"
                class="cls-2"
                cx="567.99"
                cy="418.95"
                r="7.65"
            />
            <circle
                id="Ellipse_200"
                data-name="Ellipse 200"
                class="cls-2"
                cx="539.99"
                cy="393.41"
                r="7.65"
            />
            <circle
                id="Ellipse_201"
                data-name="Ellipse 201"
                class="cls-2"
                cx="622.99"
                cy="249"
                r="7.65"
            />
            <circle
                id="Ellipse_202"
                data-name="Ellipse 202"
                class="cls-2"
                cx="555.99"
                cy="201.85"
                r="7.65"
            />
            <circle
                id="Ellipse_203"
                data-name="Ellipse 203"
                class="cls-2"
                cx="475.99"
                cy="186.13"
                r="7.65"
            />
            <circle
                id="Ellipse_204"
                data-name="Ellipse 204"
                class="cls-2"
                cx="495.99"
                cy="276.51"
                r="7.65"
            />
            <circle
                id="Ellipse_205"
                data-name="Ellipse 205"
                class="cls-2"
                cx="634.99"
                cy="499.13"
                r="7.65"
            />
            <circle
                id="Ellipse_206"
                data-name="Ellipse 206"
                class="cls-2"
                cx="910.99"
                cy="527.13"
                r="7.65"
            />
            <circle
                id="Ellipse_207"
                data-name="Ellipse 207"
                class="cls-2"
                cx="850.99"
                cy="528.13"
                r="7.65"
            />
            <circle
                id="Ellipse_208"
                data-name="Ellipse 208"
                class="cls-2"
                cx="752.99"
                cy="520.13"
                r="7.65"
            />
        </g>
    </g>
</svg>
