export default {
  calculateSavings(constants, inputs) {
    const perTonneCo2SavingsRoad = 0.0602;
    const perTonneCo2SavingsRail = 0.0155;
    const perTonneCo2SavingsSea = 0.0074;

    const perTonneFuelSavingsRoad = 0.0233;
    const perTonneFuelSavingsRail = 0.0059;
    const perTonneFuelSavingsSea = 0.0026;

    const CO2_SAVINGS_ROAD =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneCo2SavingsRoad *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRoad;

    const CO2_SAVINGS_RAIL =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneCo2SavingsRail *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRail;

    const CO2_SAVINGS_SEA =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneCo2SavingsSea *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearSea;

    const CO2_OFFSET =
      constants.CO2_OFFSET * inputs.numberOfBoxes * inputs.operationalLife;

    const TREES_SAVED = inputs.numberOfBoxes * constants.TREE_SAVINGS;

    const CO2_TOTAL =
      CO2_SAVINGS_ROAD + CO2_SAVINGS_RAIL + CO2_SAVINGS_SEA + CO2_OFFSET;

    const CARGO_ROAD =
      (constants.ADDITIONAL_CARGO / 1000) *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRoad;
    const CARGO_ROAD_SAVINGS = CARGO_ROAD * inputs.cargoPriceRoad;

    const CARGO_RAIL =
      (constants.ADDITIONAL_CARGO / 1000) *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRail;
    const CARGO_RAIL_SAVINGS = CARGO_RAIL * inputs.cargoPriceRail;

    const CARGO_SEA =
      (constants.ADDITIONAL_CARGO / 1000) *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearSea;
    const CARGO_SEA_SAVINGS = CARGO_SEA * inputs.cargoPriceSea;

    const CARGO_TOTAL =
      CARGO_ROAD_SAVINGS + CARGO_RAIL_SAVINGS + CARGO_SEA_SAVINGS;

    const FUEL_ROAD =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneFuelSavingsRoad *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRoad;

    const FUEL_RAIL =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneFuelSavingsRail *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearRail;

    const FUEL_SEA =
      (constants.ADDITIONAL_CARGO / 1000) *
      perTonneFuelSavingsSea *
      inputs.numberOfBoxes *
      inputs.operationalLife *
      inputs.kmYearSea;

    const FUEL_TOTAL =
      FUEL_ROAD * inputs.fuelPriceRoad +
      FUEL_RAIL * inputs.fuelPriceRail +
      FUEL_SEA * inputs.fuelPriceSea;

    const RESIDUAL_VALUE =
      constants.AL_FLOOR_W *
      inputs.numberOfBoxes *
      inputs.alIngotPrice *
      (inputs.scrapValue / 100);

    return {
      CO2_SAVINGS_ROAD,
      CO2_SAVINGS_RAIL,
      CO2_SAVINGS_SEA,
      CO2_OFFSET,
      TREES_SAVED,
      CO2_TOTAL,
      CARGO_ROAD,
      CARGO_ROAD_SAVINGS,
      CARGO_RAIL,
      CARGO_RAIL_SAVINGS,
      CARGO_SEA,
      CARGO_SEA_SAVINGS,
      CARGO_TOTAL,
      FUEL_ROAD,
      FUEL_RAIL,
      FUEL_SEA,
      FUEL_TOTAL,
      RESIDUAL_VALUE
    };
  }
};
