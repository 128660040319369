<template lang="html" src="./model.section.html"></template>
<style lang="scss" scoped src="./_model.section.mobile.scss"></style>
<style lang="scss" scoped src="./_model.section.desktop.scss"></style>

<script>
export default {
  name: "ModelSection",
  props: ["isActive"],
  data() {
    return {
      videoEnded: false,
      txtOvlay: "Container<span class='sup'>2.0</span>",
      animate: false
    };
  },
  mounted() {
    // Do not replay video on subsequent visits
    if (sessionStorage.getItem("video") === "true") {
      this.onVideoEnd();
    }
  },
  methods: {
    onVideoEnd() {
      this.videoEnded = true;
      this.$emit("video-end");
      // Do not replay video on subsequent visits
      sessionStorage.setItem("video", "true");
    },
    sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    async txtAnimation() {
      await this.sleep(500);
      this.animate = true;
      await this.sleep(1000);
      this.txtOvlay =
        "<span class='txt-1'>The most environmentally friendly </br> and smart intermodal container.</span>";
      this.animate = false;
      await this.sleep(2500);
      this.animate = true;
      await this.sleep(1000);
      this.txtOvlay =
        "<span class='txt-2'>Light as a cloud and </br> connected to one too.</span>";
      this.animate = false;
    }
  }
};
</script>
