<footer :class="{'scroll-snap-item': scrollSnap}">
  <div class="footer-container">
    <a href="http://people-t.com/" class="footer-logo-link">
      <img alt="People image" class="footer-logo">
    </a>
    <div class="footer-links">
      <a
        href="https://www.people-techsolutions.com/about"
        class="footer-link"
      >
        About PEOPLE
      </a>
      <a
        href="https://www.people-techsolutions.com/news-technology"
        class="footer-link"
      >
        News
      </a>
      <a
        href="https://www.people-techsolutions.com/careers"
        class="footer-link"
      >
        Career
      </a>
      <a
        href="https://www.people-techsolutions.com/contact"
        class="footer-link"
      >
        Contact
      </a>
      <a
        href="https://www.linkedin.com/company/people-technology/about/"
        class="footer-link"
      >
        Linkedin
      </a>
      <a
        href="https://www.youtube.com/playlist?list=PLai8FL7IBTmYTlZaey6rjJTNCu12KwyvZ"
        class="footer-link"
      >
        YouTube
      </a>
      <a
        href="https://www.people-techsolutions.com/terms-and-services"
        class="footer-link"
      >
        Terms of use
      </a>
      <a
        href="https://www.people-techsolutions.com/cookies-policy"
        class="footer-link"
      >
        Privacy policy
      </a>
      <a
      href="https://drive.google.com/file/d/1WegHw6iguAUfZBXY7V1gS9KTsbQw_dTg/view"
      class="footer-link"
    >
      Research
    </a>
    </div>
    <div class="copyright">&#169; Copyright 2021 PEOPLE, All rights reserved</div>
  </div>
</footer>
