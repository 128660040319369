<template lang="html" src="./container.html"></template>
<style lang="scss" scoped src="./_container.mobile.scss"></style>
<style lang="scss" scoped src="./_container.desktop.scss"></style>

<script>
export default {
  name: "Container",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payloads: {
        type20d: "28500kg",
        type40d: "29210kg",
        type45d: "30050kg",
        type53d: "26290kg"
      }
    };
  },
  emits: ["changeStep"]
};
</script>
