<template lang="html" src="./live-monitoring.section.html"></template>
<style lang="scss" scoped src="./_live-monitoring.section.mobile.scss"></style>
<style lang="scss" scoped src="./_live-monitoring.section.desktop.scss"></style>

<script>
export default {
  name: "LiveMonitoringSection",
  props: ["isActive", "isLearnMoreActive"],
  methods: {
    openLearnMore() {
      this.$emit("learn-more", "live-monitoring");
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    },
    changeSlide(slide) {
      this.currSlide = slide;
    }
  }
};
</script>
