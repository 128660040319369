import { createWebHistory, createRouter } from "vue-router";
// import App from "@/app.vue";
import Type20d from "@/views/type20d/main.vue";
import Type40d from "@/views/type40d/main.vue";
import Type45d from "@/views/type45d/main.vue";
import Type53d from "@/views/type53d/main.vue";
import RequestForm from "@/views/request-form/request-form.vue";
import SupplyChainRequestForm from "@/views/supply-chain-request-form/supply-chain-request-form.vue";
import CookiesPolicy from "@/views/cookies-policy/cookies-policy.vue";
import TermsAndServices from "@/views/terms-and-services/terms-and-services.vue";
import SupplyChain from "@/views/supply-chain/supply-chain.vue";

console.log("Host:",window.location.host);
console.log("hostname:",window.location.host);

const routes = [
  {
    path: "/",
    redirect: window.location.hostname == "container2.com" ? "/type40d" : "/supply-chain"
  },
  {
    path: "/type20d",
    name: "type20d",
    component: Type20d
  },
  {
    path: "/type40d",
    name: "type40d",
    component: Type40d
  },
  {
    path: "/type45d",
    name: "type45d",
    component: Type45d
  },
  {
    path: "/type53d",
    name: "type53d",
    component: Type53d
  },
  {
    path: "/request-form",
    name: "request-form",
    component: RequestForm
  },
  {
    path: "/privacy-policy",
    name: "cookies-policy",
    component: CookiesPolicy
  },
  {
    path: "/terms-and-services",
    name: "terms-and-services",
    component: TermsAndServices
  },
  {
    path: "/supply-chain",
    name: "supply-chain",
    component: SupplyChain
  },
  {
    path: "/supply-chain-request-form",
    name: "supply-chain-request-form",
    component: SupplyChainRequestForm
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
