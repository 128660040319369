<template>
  <!-- Components -->
  <NavbarComponent
    v-bind:isWhite="isWhite"
    v-bind:videoEnded="videoEnded"
    v-bind:selectedType="'Type 40'"
    v-bind:displaySelectMenu="true"
  />
  <SidenavComponent
    v-bind:currentActive="currentActive"
    v-bind:isWhite="isWhite"
    v-bind:typeName="'Type 40D'"
    v-show="viewMode === 'main'"
  />
  <ChatComponent v-bind:inverted="currentActive === 'tech-specs'" />
  <!-- Calculate savings form -->
  <CalculateSavingsComponent
    @modal="toggleModal"
    v-show="openModal"
    v-bind:defaultTypeKey="'type40d'"
  />

  <!-- Sections -->
  <ModelSection
    id="model"
    @modal="toggleModal"
    @video-end="oneVideoEnd"
    v-show="viewMode === 'main'"
    v-bind:isActive="currentActive === 'model'"
  />
  <SustainabilitySection
    id="sustainability"
    @modal="toggleModal"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'sustainability'"
    v-bind:isLearnMoreActive="viewMode === 'sustainability'"
    v-show="viewMode === 'sustainability' || viewMode === 'main'"
  />
  <SafetySection
    id="safety"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'safety'"
    v-bind:isLearnMoreActive="viewMode === 'safety'"
    v-show="viewMode === 'safety' || viewMode === 'main'"
  />
  <PerformanceSection
    id="performance"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'performance'"
    v-bind:isLearnMoreActive="viewMode === 'performance'"
    v-show="viewMode === 'performance' || viewMode === 'main'"
  />
  <ConnectivitySection
    id="connectivity"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'connectivity'"
    v-bind:isLearnMoreActive="viewMode === 'connectivity'"
    v-show="viewMode === 'connectivity' || viewMode === 'main'"
  />
  <ExteriorInteriorSection
    id="exterior-interior"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'exterior-interior'"
    v-bind:isLearnMoreActive="viewMode === 'exterior-interior'"
    v-show="viewMode === 'exterior-interior' || viewMode === 'main'"
  />
  <TechSpecsSection
    id="tech-specs"
    v-bind:isActive="currentActive === 'tech-specs'"
    v-show="viewMode === 'main'"
  />
  <RequestQuoteSection
    id="request-quote"
    v-bind:isActive="currentActive === 'request-quote'"
    v-show="viewMode === 'main'"
  />
  <FooterComponent scroll-snap v-show="viewMode === 'main'" />
</template>

<script>
// Components
import NavbarComponent from "@/components/navbar/navbar.component.vue";
import SidenavComponent from "@/components/sidenav/sidenav.component.vue";
import ChatComponent from "@/components/chat/chat.component.vue";
import FooterComponent from "@/components/footer/footer.component.vue";

// Forms
import CalculateSavingsComponent from "@/components/calculate-savings/calculate-savings.component.vue";
// Sections
import ModelSection from "./sections/model/model.section.vue";
import SustainabilitySection from "./sections/sustainability/sustainability.section.vue";
import SafetySection from "./sections/safety/safety.section.vue";
import PerformanceSection from "./sections/performance/performance.section.vue";
import ConnectivitySection from "./sections/connectivity/connectivity.section.vue";
import ExteriorInteriorSection from "./sections/exterior-interior/exterior-interior.section.vue";
import TechSpecsSection from "./sections/tech-specs/tech-specs.section";
import RequestQuoteSection from "./sections/request-quote/request-quote.section.vue";
import { nextTick } from "vue";

export default {
  name: "Main",
  components: {
    NavbarComponent,
    SidenavComponent,
    ChatComponent,
    FooterComponent,
    CalculateSavingsComponent,
    ModelSection,
    SustainabilitySection,
    SafetySection,
    PerformanceSection,
    ConnectivitySection,
    ExteriorInteriorSection,
    TechSpecsSection,
    RequestQuoteSection
  },
  data() {
    return {
      app: null,
      openModal: false,
      isSafari: false,
      viewMode: "main",
      currentActive: 0,
      sectionEls: [],
      videoEnded: false,
      whiteSections: ["sustainability", "performance", "tech-specs"] // Based on designs
    };
  },
  computed: {
    isWhite() {
      return this.whiteSections.includes(this.currentActive);
    }
  },
  methods: {
    toggleModal(value) {
      if (value) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
      this.openModal = value;
    },
    async onLearnMoreToggle(value) {
      const fullModeActivate = value === "main";
      const openSection = "#" + (fullModeActivate ? this.viewMode : value);
      if (fullModeActivate) {
        this.currentActive = this.viewMode;
        if (!this.isSafari) {
          // Update what is visible
          this.viewMode = value;
          // Scroll back to section hero without smooth-scrolling
          await nextTick();
          this.app.style.scrollBehavior = "auto";
          await nextTick();
          document.querySelector(openSection).scrollIntoView();
          // Restore scroll snapping for subsection after scroll finishes
          await nextTick();
          await this.delay(1000);
          this.app.style.scrollSnapType = "y mandatory";
          await nextTick();
          await this.delay(1000);
          this.app.style.scrollBehavior = "smooth";
        } else {
          // Special handling for Safari

          // Restore scroll snapping for subsection after scroll finishes
          await nextTick();
          this.app.style.scrollSnapType = "y mandatory";
          await this.delay(500);

          // Update what is visible
          await nextTick();
          this.viewMode = value;

          // Scroll back to section hero without smooth-scrolling
          await nextTick();
          document.querySelector(openSection).scrollIntoView();
        }
      } else {
        // Remove scroll snapping while in subsection
        this.app.style.scrollSnapType = "unset";
        await nextTick();
        this.viewMode = value;
        await nextTick();
        document.querySelector(openSection + "-learn-more").scrollIntoView();
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    oneVideoEnd() {
      this.videoEnded = true;
    }
  },
  mounted() {
    const UA = navigator.userAgent.toLowerCase();
    this.isSafari = UA.indexOf("safari") > 0 && UA.indexOf("chrome") < 0;
    document.location.hash = "";
    this.app = document.querySelector("#app");
    this.sectionEls = document.querySelectorAll("section");
    this.currentActive = "model";
    const handleIntersectionEvent = entries => {
      const entriesLength = entries.length;
      // On Safari the entries array contains 2 elements in a fixed order
      // which respects the sections' order regardles of which one we chose
      // first.
      if (entriesLength === 2) {
        const firstEntry = entries[0].target.id;
        const secondEntry = entries[entriesLength - 1].target.id;
        if (this.currentActive === secondEntry) {
          this.currentActive = firstEntry;
        } else {
          this.currentActive = secondEntry;
        }
      } else {
        this.currentActive = entries[0].target.id;
      }
    };
    const options = {
      threshold: 0.9
    };
    const observer = new IntersectionObserver(handleIntersectionEvent, options);
    this.sectionEls.forEach(section => {
      observer.observe(section);
    });
  }
};
</script>
