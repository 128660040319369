<template>
  <div class="request-form">
    <Navbar
      :formSteps="formSteps"
      :currentStep="currentStep"
      @changeStep="changeStep"
    />

    <!-- Inactive components will be cached -->
    <!-- <keep-alive> -->
    <component
      :is="currentStepComponent"
      :form="form"
      :requestSent="requestSent"
      :requestLoading="requestLoading"
      @changeStep="changeStep"
      @valueChange="updateForm"
      @submitForm="submitForm"
    >
      <!-- Pass the component to be used in slots -->
      <Footer
        :calculateForm="calculateForm"
        :currentStep="currentStep"
        @modal="toggleModal(true)"
        @changeStep="changeStep"
      />
    </component>
    <!-- </keep-alive> -->
    <EspaComponent v-bind:topRight="false" v-bind:bottomLeft="true" />
  </div>
</template>
<style
  lang="scss"
  scoped
  src="./_supply-chain-request-form.mobile.scss"
></style>
<style
  lang="scss"
  scoped
  src="./_supply-chain-request-form.desktop.scss"
></style>

<script>
import Navbar from "./navbar/navbar.vue";
import Footer from "./footer/footer.vue";
import Devices from "./devices/devices.vue";
import Fmaas from "./fmaas/fmaas.vue";
import Platform from "./platform/platform.vue";
import Enquiry from "./enquiry/enquiry.vue";
import constants from "../../constants";
import EspaComponent from "@/components/espa/espa.component.vue";

export default {
  name: "SupplyChainRequestForm",
  components: {
    Navbar,
    Footer,
    EspaComponent,
    Devices,
    Fmaas,
    Platform,
    "request-quote": Enquiry
  },
  data() {
    return {
      requestSent: false,
      requestLoading: false,
      constants: constants.savingsConstants,
      emailEndpoint: process.env.VUE_APP_EMAIL_ENDPOINT,
      openModal: false,
      formSteps: [
        {
          id: "devices",
          num: "1",
          label: "1. Devices"
        },
        {
          id: "fmaas",
          num: "2",
          label: "2. FMaaS"
        },
        {
          id: "platform",
          num: "3",
          label: "3. Platform"
        },
        {
          id: "request-quote",
          num: "4",
          label: "4. Request Quote"
        }
      ],
      currentStep: "devices",
      form: {
        devices: {
          containerP: {
            type: "containerP",
            quantity: "",
            selected: ""
          },
          containerS: {
            type: "containerS",
            quantity: "",
            selected: ""
          },
          doubleL: {
            type: "doubleL",
            quantity: "",
            selected: ""
          },
          flexM: {
            type: "flexM",
            quantity: "",
            selected: ""
          },
          flexS: {
            type: "flexS",
            quantity: "",
            selected: ""
          },
          coin: {
            type: "coin",
            quantity: "",
            selected: ""
          }
        },
        fmaas: {
          short: {
            type: "Short",
            months: "",
            selected: ""
          },
          medium: {
            type: "Medium",
            months: "",
            selected: ""
          },
          long: {
            type: "Long",
            months: "",
            selected: ""
          }
        },
        platform: {
          basic: {
            users: ""
          },
          location: {
            users: ""
          },
          standard: {
            users: ""
          },
          advanced: {
            users: ""
          },
          premium: {
            users: ""
          }
        },
        container: {
          type: "40d",
          quantity: ""
        },
        enquiry: {
          firstname: "",
          lastname: "",
          company: "",
          country: "",
          email: "",
          phone: "",
          recaptcha: ""
        }
      }
    };
  },
  methods: {
    changeStep(step) {
      const getIndex = item =>
        this.formSteps.findIndex(formStep => formStep.id === item);
      if (
        step === "next" &&
        getIndex(this.currentStep) !== this.formSteps.length - 1
      ) {
        this.currentStep = this.formSteps[getIndex(this.currentStep) + 1].id;
      } else if (step === "previous" && getIndex(this.currentStep) !== 0) {
        this.currentStep = this.formSteps[getIndex(this.currentStep) - 1].id;
      } else {
        this.currentStep = step;
      }
    },
    updateForm(newForm) {
      if (newForm) {
        this.form = newForm;
      }
    },
    async submitForm() {
      this.requestLoading = true;
      /* eslint-disable */
      let devicesHelper= "";
      if(this.form.devices.containerP.quantity > 0) {
        devicesHelper += `<div>Container P: ${this.form.devices.containerP.quantity} units</div>`
      }
      if(this.form.devices.containerS.quantity > 0) {
        devicesHelper += `<div>Container S: ${this.form.devices.containerS.quantity} units</div>`
      }
      if(this.form.devices.doubleL.quantity > 0) {
        devicesHelper += `<div>Double L: ${this.form.devices.doubleL.quantity} units</div>`
      }
      if(this.form.devices.flexM.quantity > 0) {
        devicesHelper += `<div>Flex Multi Use: ${this.form.devices.flexM.quantity} units</div>`
      }
      if(this.form.devices.flexS.quantity > 0) {
        devicesHelper += `<div>Flex Single Use: ${this.form.devices.flexS.quantity} units</div>`
      }
      if(this.form.devices.coin.quantity > 0) {
        devicesHelper += `<div>Coin: ${this.form.devices.coin.quantity} units</div>`
      }

      let timeHelper = "";
      if (this.form.fmaas.short.months > 0) {
        timeHelper += `<div>Short-term: ${this.form.fmaas.short.months} months</div>`;
      }
      if (this.form.fmaas.medium.months > 0) {
        timeHelper += `<div>Medium-term: ${this.form.fmaas.medium.months} months</div>`;
      }
      if (this.form.fmaas.long.months > 0) {
        timeHelper += `<div>Long-term: ${this.form.fmaas.long.months} months</div>`;
      }
    
      let planHelper = "";
      if (this.form.platform.location.users > 0) {
        planHelper += `<div>Location: ${this.form.platform.location.users} users</div>`
      }
      if (this.form.platform.basic.users > 0) {
        planHelper += `<div>Basic: ${this.form.platform.basic.users} users</div>`
      }
      if (this.form.platform.standard.users > 0) {
        planHelper += `<div>Standard: ${this.form.platform.standard.users} users</div>`
      }
      if (this.form.platform.advanced.users > 0) {
        planHelper += `<div>Advanced: ${this.form.platform.advanced.users} users</div>`
      }
      if (this.form.platform.premium.users > 0) {
        planHelper += `<div>Premium: ${this.form.platform.premium.users} users</div>`
      }
      if (planHelper === "") {
        planHelper = "None selected";
      }
      try {
        const subject = "Request quote";
        const emailBody = `
        <div style="line-height: 30px;">New quote request:</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Contact</b></div>
        <div style="line-height: 30px; margin-left: 25px;">First Name: ${this.form.enquiry.firstname}</div>
        <div style="line-height: 30px; margin-left: 25px;">Last Name: ${this.form.enquiry.lastname}</div>
        <div style="line-height: 30px; margin-left: 25px;">Company: ${this.form.enquiry.company}</div>
        <div style="line-height: 30px; margin-left: 25px;">Country: ${this.form.enquiry.country}</div>
        <div style="line-height: 30px; margin-left: 25px;">Email: ${this.form.enquiry.email}</div>
        <div style="line-height: 30px; margin-left: 25px;">Phone: ${this.form.enquiry.phone}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Devices</b>: ${devicesHelper}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>FMaaS</b>: ${timeHelper}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Platform</b>: ${planHelper}</div>
        <br/>
        `;
        const requestBody = {
          subject,
          body: emailBody,
          images: [],
          captcha: this.form.enquiry.recaptcha
        };
        // Send the request
        const res = await fetch(this.emailEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
        });
        this.requestSent = true;
        // this.showToast(this.form, 'success');
      } catch(err) {
        // this.showToast(this.form, 'error');
        console.error("Email request error:", err);
      } finally {
        this.requestLoading = false;
      }
    },
    showToast(data, type) {
      if (type === 'success') {
        console.log("successful submission", data);
      } else {
        console.log("unsuccessful submission", data);
      }
    },
    toggleModal(value) {
      this.openModal = value;
      if (value) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    },
  },
  computed: {
    currentStepComponent() {
      return this.currentStep;
    },
    calculateForm() {
      return {
        Container: [
          {
            title: "",
            prefix: "",
            value: "Global cellular coverage",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "BLE Connectivity",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Built in technology",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Zero initial investment",
            suffix: ""
          }
        ],
        DoubleL: [
          {
            title: "",
            prefix: "",
            value: "Global cellular coverage",
            suffix: ""
          },
          {
            title: "",
            prefix: "$",
            value: "BLE Connectivity",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Long battery life",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Zero initial investment",
            suffix: ""
          }
        ],
        Flex: [
          {
            title: "",
            prefix: "",
            value: "Global cellular coverage",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "BLE Connectivity",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Long battery life",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Portable",
            suffix: ""
          }
        ],
        Coin: [
          {
            title: "",
            prefix: "",
            value: "BLE Connectivity",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Long battery life",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Portable",
            suffix: ""
          },
          {
            title: "",
            prefix: "",
            value: "Disposable or multiple use",
            suffix: ""
          }
        ],
      }
    }
  }
};
</script>
