<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="model-info-wrapper" :class="form.container.type">
          <video autoplay muted loop playsinline class="video-hero">
            <source v-if="form.container.type === 'type20d'" src="../../../assets/media/videos/type20d/connectivity-hero.mp4" type="video/mp4" />
            <source v-if="form.container.type === 'type40d'" src="../../../assets/media/videos/type40d/connectivity-hero.mp4" type="video/mp4" />
            <source v-if="form.container.type === 'type45d'" src="../../../assets/media/videos/type45d/connectivity-hero.mp4" type="video/mp4" />
            <source v-if="form.container.type === 'type53d'" src="../../../assets/media/videos/type53d/connectivity-hero.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="section type">
          <div class="header">Select your FMaaS plan</div>
          <div class="desc">Configure your Freight Monitoring as a Service (FMaaS) plan for your selection of devices.</div>
          <div class="form">
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="short" name="short" value="selected" v-model="form.fmaas.short.selected">-->
                  <label :class="{active: form.fmaas.short.months > 0 }" for="short">
                    <div class="header">Short-term</div>
                    <div class="desc">Best for businesses that require a short-term commitment below 12 months.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.fmaas.short.months" placeholder="No of months" required>
                </div>              
              </div>
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <label :class="{active: form.fmaas.medium.months > 0 }" for="medium">
                    <div class="header">Medium-term</div>
                    <div class="desc">Best for businesses that require a medium-term plan with 12 months commitment.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.fmaas.medium.months" placeholder="No of months" required>
                </div>
              </div>
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <label :class="{active: form.fmaas.long.months > 0 }" for="long">
                    <div class="header">Long-term</div>
                    <div class="desc">Best for businesses that require a long-term strategic plan with more than 24 months commitment.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.fmaas.long.months" placeholder="No of months" required>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-section">
        <button @click="$emit('changeStep', 'smart-specs')" class="btn-secondary">Next</button>
      </div>

    </div>
  </div>

  <!-- Mobile design -->
  <div class="mobile">
    <div class="container">
      <div class="header">Select your FMaaS plan</div>
      <div class="container-wrapper">
        <div class="outer-circle"></div>
        <div class="mid-circle"></div>
        <div class="inner-circle"></div>
        <div class="img"></div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Short</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.fmaas.short.months" placeholder="No of months" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Medium</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.fmaas.medium.months" placeholder="No of months" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Long</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.fmaas.long.months" placeholder="No of months" required>
        </div>
      </div>

      <div v-for="option in package">
        <div class="description">
          <div class="description-title">
            {{ option.title }}
          </div>
          <div class="description-body">
            {{ option.body }}
          </div>
        </div>
      </div>
  </div>
  <slot></slot>
</div>
</div>