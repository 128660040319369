<!-- Insights section -->
<section class="scroll-snap-item">
  <!-- Insights always displayed info -->
  <div class="main">
    <div class="img">
      <div class="tp-layer"></div>
      <div class="bt-layer"></div>
      <!-- Mobile -->
      <div class="info mobile">
        <div class="info-item">
          <div class="info-item-header">
            Meaningful insights
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Create new revenue streams
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="info-item-header">
            Clear data suggestion
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="container desktop">
        <div class="info">
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent']">
            <div class="info-item-header">
              Meaningful insights
            </div>
            <div class="info-item-desc">
              Meaningful insights bring about valuable information and metrics for business growth
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-02s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-04s']">
            <div class="info-item-header">
              Create new revenue streams
            </div>
            <div class="info-item-desc">
              Optimise asset use, reveal hidden revenue streams and improve overall financial return
            </div>
          </div>
          <div class="divider" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-06s']"></div>
          <div class="info-item" :class="[{ 'animate__animated animate__fadeInUp': isActive}, 'transparent', 'animate__delay-08s']">
            <div class="info-item-header">
              Clear data suggestion
            </div>
            <div class="info-item-desc">
              Improve a company’s total performance with clear data insights and suggestions
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Insights</h5>
        <h3>Mitigate risk and anticipate future opportunities</h3>
        <p>
          Data and analytics are only worthwhile when they bring valuable insights, 
          so translating these findings into meaningful actions is crucial. With an eye 
          for identifying areas for business growth, we are able to challenge pre-existing 
          actions or responses, and suggest more effective solutions going forward.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
        <button class="btn-primary mobile">
          <router-link to="/request-form">Request quote</router-link>
        </button>
      </div>
    </div>
  </div>

  <!-- Insights learn more sub-sections -->
  <div id="insights-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="subsection-2 bottom">
      <div class="header">
        <h3>Optimise a supply chain through predictive behaviours and prescriptive suggestions</h3>
        <p>In addition to real-time monitoring and reporting, deep analytics provides the opportunity
           to understand patterns and trends to forecast future outcomes. Our deep analytics system 
           goes beyond leveraging the collected data, such as cargo temperature fluctuation, to 
           anticipate and optimise future performance. It also suggests solutions on how to take 
           advantage of a future opportunity or mitigate future risk.</p>
      </div>
      <div class="img">
        <video autoplay muted loop playsinline class="video-hero">
          <source src="../../../../assets/media/videos/supply-chain/insights-graph.mp4" type="video/mp4" />
        </video>
      </div>
      <a class="btn-close" @click="closeLearnMore"></a>
    </div>
  </div>
</section>
