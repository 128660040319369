<template lang="html" src="./analytics.section.html"></template>
<style lang="scss" scoped src="./_analytics.section.mobile.scss"></style>
<style lang="scss" scoped src="./_analytics.section.desktop.scss"></style>

<script>
export default {
  name: "AnalyticsSection",
  props: ["isActive", "isLearnMoreActive"],
  data() {
    return {
      currSlide: "first"
    };
  },
  methods: {
    openLearnMore() {
      if (!this.isLearnMoreActive) {
        this.$emit("learn-more", "analytics");
      }
    },
    closeLearnMore() {
      this.$emit("learn-more", "main");
    }
  }
};
</script>
