<section class="scroll-snap-item">
  <div class="main">
    <div class="spec-tabs container">
      <div class="horizontal-wrapper">
        <div class="device-specs-horizontal-info">
          <div class="horizontal-info ">
            <div class="horizontal-item" :class="{ active: currSlide === 'first'}" @click="changeSlide('first')">
              <i class="container-icon icon"></i>
              <h3>Container<span class="sup">1.5</span></h3>
            </div>
            <div class="horizontal-item" :class="{ active: currSlide === 'second'}" @click="changeSlide('second')">
            <i class="doubleL-icon icon"></i>
              <h3>DoubleL</h3>
            </div>
            <div class="horizontal-item" :class="{ active: currSlide === 'third'}" @click="changeSlide('third')">
              <i class="flex-icon icon"></i>
              <h3>Flex</h3>
            </div>
            <div class="horizontal-item" :class="{ active: currSlide === 'fourth'}" @click="changeSlide('fourth')">
              <i class="coin-icon icon"></i>
              <h3>Coin</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!--Mobile section-->
    <div class="mobile">
      <div class="specs device-specs">
        <div class="note">Smart devices</div>
        <div class="header" v-if="currSlide === 'first'">Container<span class="sup">1.5</span></div>
        <div class="header" v-if="currSlide === 'second'">DoubleL</div>
        <div class="header" v-if="currSlide === 'third'">Flex</div>
        <div class="header" v-if="currSlide === 'fourth'">Coin</div>
      </div>
    </div>
  
    <div class="wrapper">
      <div class="specs device-specs">
        <div class="spec-item-header desktop">
          <div class="note">Smart devices</div>
          <div v-if="currSlide === 'first'">Container<span class="sup">1.5</span></div>
          <div v-if="currSlide === 'second'">DoubleL</div>
          <div v-if="currSlide === 'third'">Flex</div>
          <div v-if="currSlide === 'fourth'">Coin</div>
        </div>
        <div class="desktop">
          <div v-if="currSlide === 'first'">
            <div  v-for="spec in containerInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
            <div v-for="application in containerApplication" class="spec-item">
              <div class="spec-item-title" v-html="application.title"></div>
              <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'second'">
            <div  v-for="spec in doubleLInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
            <div v-for="application in doubleLApplication" class="spec-item">
              <div class="spec-item-title" v-html="application.title"></div>
              <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'third'">
            <div  v-for="spec in flexInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
            <div v-for="application in flexApplication" class="spec-item">
              <div class="spec-item-title" v-html="application.title"></div>
              <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'fourth'">
            <div  v-for="spec in coinInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
            <div v-for="application in coinApplication" class="spec-item">
              <div class="spec-item-title" v-html="application.title"></div>
              <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div v-if="currSlide === 'first'">
            <div  v-for="spec in containerInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'second'">
            <div  v-for="spec in doubleLInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'third'">
            <div  v-for="spec in flexInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
          </div>
          <div v-if="currSlide === 'fourth'">
            <div  v-for="spec in coinInfo" class="spec-item">
              <div class="spec-item-title" v-html="spec.title"></div>
              <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="img" :class="currSlide"></div>
      <div class="specs application-specs mobile">
        <div v-if="currSlide === 'first'">
          <div  v-for="application in containerApplication" class="spec-item">
            <div class="spec-item-title" v-html="application.title"></div>
            <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
          </div>
        </div>
        <div v-if="currSlide === 'second'">
          <div  v-for="application in doubleLApplication" class="spec-item">
            <div class="spec-item-title" v-html="application.title"></div>
            <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
          </div>
        </div>
        <div v-if="currSlide === 'third'">
          <div  v-for="application in flexApplication" class="spec-item">
            <div class="spec-item-title" v-html="application.title"></div>
            <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
          </div>
        </div>
        <div v-if="currSlide === 'fourth'">
          <div  v-for="application in coinApplication" class="spec-item">
            <div class="spec-item-title" v-html="application.title"></div>
            <div v-for="info in application.info" class="spec-item-info" v-html="application.info"></div>
          </div>
        </div>
      </div>

      <div v-if="currSlide === 'first'" class="specs device-specs-info">
        <div v-for="spec in containerSpecs" class="spec-item">
          <div class="spec-item-title" v-html="spec.title"></div>
          <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
        </div>
        <div class="spec-actions">
          <div></div>
          <div class="spec-item-header">
            <a class="download-wrapper" href="Container1.5 Specs.pdf" download>
              <i class="download-icon"></i>
              Download specs
            </a>
          </div>
        </div>
      </div>
      <div v-if="currSlide === 'second'" class="specs device-specs-info">
        <div v-for="spec in doubleLSpecs" class="spec-item">
          <div class="spec-item-title" v-html="spec.title"></div>
          <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
        </div>
        <div class="spec-actions">
          <div></div>
          <div class="spec-item-header desktop">
            <a class="download-wrapper" href="DoubleL Specs.pdf" download>
              <i class="download-icon"></i>
              Download specs
            </a>
          </div>
        </div>
      </div>
      <div v-if="currSlide === 'third'" class="specs device-specs-info">
        <div v-for="spec in flexSpecs" class="spec-item">
          <div class="spec-item-title" v-html="spec.title"></div>
          <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
        </div>
        <div class="spec-actions">
          <div></div>
          <div class="spec-item-header desktop">
            <a class="download-wrapper" href="Flex Specs.pdf" download>
              <i class="download-icon"></i>
              Download specs
            </a>
          </div>
        </div>
      </div>
      <div v-if="currSlide === 'fourth'" class="specs device-specs-info">
        <div v-for="spec in coinSpecs" class="spec-item">
          <div class="spec-item-title" v-html="spec.title"></div>
          <div v-for="info in spec.info" class="spec-item-info" v-html="spec.info"></div>
        </div>
        <div class="spec-item-header desktop">
          <a class="download-wrapper" href="Coin Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div>
      </div>

      <div v-if="currSlide === 'first'" class="specs specs-action mobile">
        <div class="download mobile">
          <a class="download-wrapper" href="Container1.5 Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div> 
      </div>
      <div v-if="currSlide === 'second'" class="specs specs-action mobile">
        <div class="download mobile">
          <a class="download-wrapper" href="DoubleL Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div>
      </div>
      <div v-if="currSlide === 'third'" class="specs specs-action mobile">
        <div class="download mobile">
          <a class="download-wrapper" href="Flex Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div>
      </div>
      <div v-if="currSlide === 'fourth'" class="specs specs-action mobile">
        <div class="download mobile">
          <a class="download-wrapper" href="Coin Specs.pdf" download>
            <i class="download-icon"></i>
            Download specs
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
