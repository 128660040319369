<template>
  <CalculateSavingsComponent
    @modal="toggleModal"
    v-show="openModal"
    :defaultTypeKey="form.container.type"
  />
  <div class="request-form">
    <Navbar
      :formSteps="formSteps"
      :currentStep="currentStep"
      @changeStep="changeStep"
    />
    <!-- Inactive components will be cached -->
    <!-- <keep-alive> -->
    <component
      :is="currentStepComponent"
      :form="form"
      :requestSent="requestSent"
      :requestLoading="requestLoading"
      @changeStep="changeStep"
      @valueChange="updateForm"
      @submitForm="submitForm"
    >
      <!-- Pass the component to be used in slots -->
      <Footer
        :calculateForm="calculateForm"
        :currentStep="currentStep"
        @modal="toggleModal(true)"
        @changeStep="changeStep"
      />
    </component>
    <!-- </keep-alive> -->
  </div>
</template>
<style lang="scss" scoped src="./_request-form.mobile.scss"></style>
<style lang="scss" scoped src="./_request-form.desktop.scss"></style>

<script>
import CalculateSavingsComponent from "@/components/calculate-savings/calculate-savings.component.vue";

import Navbar from "./navbar/navbar.vue";
import Footer from "./footer/footer.vue";
import Container from "./container/container.vue";
import Exterior from "./exterior/exterior.vue";
import Interior from "./interior/interior.vue";
import SmartSpecs from "./smart-specs/smart-specs.vue";
import Fmaas from "./fmaas/fmaas.vue";
import Enquiry from "./enquiry/enquiry.vue";
import constants from "../../constants";
import savingsUtils from "../../helpers/savings";

export default {
  name: "RequestForm",
  components: {
    CalculateSavingsComponent,
    Navbar,
    Footer,
    Container,
    Exterior,
    Interior,
    Fmaas,
    "smart-specs": SmartSpecs,
    "request-quote": Enquiry
  },
  data() {
    return {
      requestSent: false,
      requestLoading: false,
      constants: constants.savingsConstants,
      emailEndpoint: process.env.VUE_APP_EMAIL_ENDPOINT,
      openModal: false,
      formSteps: [
        {
          id: "container",
          label: "1. Container<span class='sup'>2.0</span>"
        },
        {
          id: "exterior",
          label: "2. Exterior"
        },
        {
          id: "interior",
          label: "3. Interior"
        },
        {
          id: "fmaas",
          label: "4. FMaaS"
        },
        {
          id: "smart-specs",
          label: "5. Platform"
        },
        {
          id: "request-quote",
          label: "6. Request Quote"
        }
      ],
      currentStep: "container",
      form: {
        container: {
          type: "type40d",
          quantity: "1",
          purchase: "buy",
          time: {
            lease: "",
            rent: ""
          }
        },
        exterior: {
          color: "#ffffff",
          logos: {
            logo1: {
              label: "",
              fileURL: "",
              fileb64: ""
            },
            logo2: {
              label: "",
              fileURL: "",
              fileb64: ""
            },
            logo3: {
              label: "",
              fileURL: "",
              fileb64: ""
            }
          }
        },
        interior: {
          floor: "optionA"
        },
        fmaas: {
          short: {
            type: "Short",
            months: "",
            selected: ""
          },
          medium: {
            type: "Medium",
            months: "",
            selected: ""
          },
          long: {
            type: "Long",
            months: "",
            selected: ""
          }
        },
        smartspecs: {
          basic: "",
          standard: "",
          advanced: "",
          premium: ""
        },
        enquiry: {
          firstname: "",
          lastname: "",
          company: "",
          country: "",
          email: "",
          phone: "",
          recaptcha: ""
        }
      }
    };
  },
  methods: {
    changeStep(step) {
      const getIndex = item =>
        this.formSteps.findIndex(formStep => formStep.id === item);
      if (
        step === "next" &&
        getIndex(this.currentStep) !== this.formSteps.length - 1
      ) {
        this.currentStep = this.formSteps[getIndex(this.currentStep) + 1].id;
      } else if (step === "previous" && getIndex(this.currentStep) !== 0) {
        this.currentStep = this.formSteps[getIndex(this.currentStep) - 1].id;
      } else {
        this.currentStep = step;
      }
    },
    updateForm(newForm) {
      if (newForm) {
        this.form = newForm;
      }
    },
    async submitForm() {
      this.requestLoading = true;
      /* eslint-disable */
      let timeHelper = "";
      if (this.form.container.purchase === "rent") {
        timeHelper = this.form.container.time.rent + " months";
      } else if (this.form.container.purchase === "lease") {
        timeHelper = this.form.container.time.lease + " months";
      }

      let fmaasHelper = "";
      if (this.form.fmaas.short.months > 0) {
        fmaasHelper += `<div>Short-term: ${this.form.fmaas.short.months} months</div>`;
      }
      if (this.form.fmaas.medium.months > 0) {
        fmaasHelper += `<div>Medium-term: ${this.form.fmaas.medium.months} months</div>`;
      }
      if (this.form.fmaas.long.months > 0) {
        fmaasHelper += `<div>Long-term: ${this.form.fmaas.long.months} months</div>`;
      }

      let planHelper = "";
      if (this.form.smartspecs.basic > 0) {
        planHelper += `<div>Basic: ${this.form.smartspecs.basic} users</div>`
      }
      if (this.form.smartspecs.standard > 0) {
        planHelper += `<div>Standard: ${this.form.smartspecs.standard} users</div>`
      }
      if (this.form.smartspecs.advanced > 0) {
        planHelper += `<div>Advanced: ${this.form.smartspecs.advanced} users</div>`
      }
      if (this.form.smartspecs.premium > 0) {
        planHelper += `<div>Premium: ${this.form.smartspecs.premium} users</div>`
      }
      if (planHelper === "") {
        planHelper = "None selected";
      }
      try {
        const subject = "Request quote";
        const emailBody = `
        <div style="line-height: 30px;">New quote request:</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Contact</b></div>
        <div style="line-height: 30px; margin-left: 25px;">&ensp; First Name: ${this.form.enquiry.firstname}</div>
        <div style="line-height: 30px; margin-left: 25px;">Last Name: ${this.form.enquiry.lastname}</div>
        <div style="line-height: 30px; margin-left: 25px;">Company: ${this.form.enquiry.company}</div>
        <div style="line-height: 30px; margin-left: 25px;">Country: ${this.form.enquiry.country}</div>
        <div style="line-height: 30px; margin-left: 25px;">Email: ${this.form.enquiry.email}</div>
        <div style="line-height: 30px; margin-left: 25px;">Phone: ${this.form.enquiry.phone}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Device information</b></div>
        <div style="line-height: 30px; margin-left: 25px;">Type: ${this.form.container.type.slice(0, -1)}</div>
        <div style="line-height: 30px; margin-left: 25px;">Quantity: ${this.form.container.quantity}</div>
        <div style="line-height: 30px; margin-left: 25px;">Purchase option: ${this.form.container.purchase} ${timeHelper}</div>
        <div style="line-height: 30px; margin-left: 25px;">Color: ${this.form.exterior.color}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>FMaaS</b>: ${fmaasHelper}</div>
        <div style="line-height: 30px; margin-left: 25px;"><b>Platform</b>: ${planHelper}</div>
        <br/>
        `;
        const requestBody = {
          subject,
          body: emailBody,
          images: [],
          captcha: this.form.enquiry.recaptcha
        };
        // Add any decals
        const decals = this.form.exterior.logos;
        if (decals.logo1.fileb64) {
          requestBody.images.push(decals.logo1.fileb64);
        }
        if (decals.logo2.fileb64) {
          requestBody.images.push(decals.logo2.fileb64);
        }
        if (decals.logo3.fileb64) {
          requestBody.images.push(decals.logo3.fileb64);
        }
        // Send the request
        const res = await fetch(this.emailEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
        });
        this.requestSent = true;
        // this.showToast(this.form, 'success');
      } catch(err) {
        // this.showToast(this.form, 'error');
        console.error("Email request error:", err);
      } finally {
        this.requestLoading = false;
      }
    },
    showToast(data, type) {
      if (type === 'success') {
        console.log("successful submission", data);
      } else {
        console.log("unsuccessful submission", data);
      }
    },
    toggleModal(value) {
      this.openModal = value;
      if (value) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    },
  },
  computed: {
    currentStepComponent() {
      return this.currentStep;
    },
    calculateForm() {
      const constants = this.constants[this.form.container.type];
      const calculateFormValues = savingsUtils.calculateSavings(
        constants,
        {
          numberOfBoxes: this.form.container.quantity || 1,
          weightSaving: constants.ADDITIONAL_CARGO,
          operationalLife: 12,
          kmYearRoad: 72405,
          kmYearRail: 37055,
          kmYearSea: 280094,
          alIngotPrice: 2031,
          scrapValue: 75,
          cargoPriceRoad: 0.115,
          cargoPriceRail: 0.017,
          cargoPriceSea: 0.0013,
          fuelPriceRoad: 1.961,
          fuelPriceRail: 1.961,
          fuelPriceSea: 1.0
        }
      );
      return {
        weight: [
          {
            title: "CO<span class='sub'>2</span> offset",
            prefix: "",
            value: calculateFormValues.CO2_OFFSET,
            suffix: "kg"
          },
          {
            title: "Additional income or cost savings",
            prefix: "$",
            value: calculateFormValues.CARGO_TOTAL,
            suffix: ""
          },
          {
            title: "Additional residual value",
            prefix: "$",
            value: calculateFormValues.RESIDUAL_VALUE,
            suffix: ""
          }
        ],
        volume: [
          {
            title: "CO<span class='sub'>2</span> total reduction",
            prefix: "",
            value: calculateFormValues.CO2_TOTAL,
            suffix: "kg"
          },
          {
            title: "Fuel Savings",
            prefix: "$",
            value: calculateFormValues.FUEL_TOTAL,
            suffix: ""
          },
          {
            title: "Additional residual value",
            prefix: "$",
            value: calculateFormValues.RESIDUAL_VALUE,
            suffix: ""
          }
        ]
      }
    }
  }
};
</script>
