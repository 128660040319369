<div>
  <!-- Info modal -->
  <div class="modal" v-show="showModal">
    <div class="modal-bg" @click="toggleModal()"></div>
    <div class="smart-specs-modal">
      <!-- Close button -->
      <div class="close-modal" @click="toggleModal()">
        <div class="cross-1"></div>
        <div class="cross-2"></div>
      </div>
      <div class="info-container">
        <div class="info-flex">
          <div class="banners">
            <div class="label-header"></div>
            <div class="banner-header">
              <!-- <div class="title">PEOPLE<br>Monitoring System</div> -->
              <div class="category">Business<br/>Basic</div>
              <div class="details">Best for businesses that need simple and easy to use monitoring procedures without time consuming installation and complex software</div>
            </div>
            <div class="banner-header">
              <!-- <div class="title">PEOPLE<br>Monitoring System</div> -->
              <div class="category">Business Standard</div>
              <div class="details">Best for businesses that need simple monitoring and effective management of their cargo / asset with crucial notifications and alerts</div>
            </div>
            <div class="banner-header">
              <!-- <div class="title">PEOPLE<br>Monitoring System</div> -->
              <div class="category">Business Advanced</div>
              <div class="details">Best for businesses that need advanced cargo monitoring procedures, effective notifications and efficient insights for business optimization</div>
            </div>
            <div class="banner-header">
              <!-- <div class="title">PEOPLE<br>Monitoring System</div> -->
              <div class="category">Business Premium</div>
              <div class="details">Best for businesses that need have full control of their cargo quality and integrity, simple monitoring procedures, full interaction with all stakeholders and advanced analytics</div>
            </div>
            <div class="details-header desktop"></div>
          </div>
          <div class="table">
            <div class="section" v-for="section in info">
              <div class="section-title">
                <span>{{ section.title }}</span>
              </div>
              <div class="row" v-for="row in section.rows">
                <div class="label" v-html="row.label"></div>
                <div class="checkmark" :class="{ check: row.basic }"></div>
                <div class="checkmark" :class="{ check: row.standard }"></div>
                <div class="checkmark" :class="{ check: row.advanced }"></div>
                <div class="checkmark" :class="{ check: row.premium }"></div>
                <div class="details"><span>{{ row.details }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="model-info-wrapper">
          <video autoplay muted loop playsinline class="video-hero">
            <source src="../../../assets/media/videos/supply-chain/request-quote-form-platform.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">

      <div class="controls-section">
        <div class="package">
          <div class="header">Configure your platform plan</div>
          <div class="desc">Choose the platform plan that best suits your needs.</div>
          <div class="option-item" v-for="option in package">
            <div class="form-control" :key="option.id">
              <div class="option-title" :class="{active: form.smartspecs[option.id] > 0 }">{{ option.title }}</div>
              <div class="option-body" :class="{active: form.smartspecs[option.id] > 0 }">{{ option.body }}</div>
            </div>
            <div class="input form-control">
              <input
                min="0"
                type="number"
                v-model="form.smartspecs[option.id]"
                placeholder="No of users"
                required
              >
            </div>
          </div>
        </div>

        <button class="btn btn-primary" @click="toggleModal()">Learn more</button>
        
      </div>

      <div class="button-section">
        <button @click="$emit('changeStep', 'request-quote')" class="btn-secondary">Next</button>
      </div>

    </div>
  </div>
  <!-- Mobile designs -->
  <div class="mobile">
    <div class="container">

      <div class="header">Configure your platform plan</div>
      <div class="model-info-wrapper">
        <video autoplay muted loop playsinline class="video-hero">
          <source src="../../../assets/media/videos/supply-chain/request-quote-form-platform.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="option-item" v-for="option in package">
        <div class="form-control" :key="option.id">
          <span>{{ option.title }}</span>
        </div>
        <div class="input form-control">
          <input
            min="0"
            type="number"
            v-model="form.smartspecs[option.id]"
            placeholder="No of users"
            required
          >
        </div>
      </div>

      <button class="btn btn-primary" @click="toggleModal()">Learn more</button>

      <div v-for="option in package">
        <div class="description">
          <div class="description-title">
            {{ option.title }}
          </div>
          <div class="description-body">
            {{ option.body }}
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
    <!-- Mobile -->
  </div>
</div>
