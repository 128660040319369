<template lang="html" src="./supplynavbar.html"></template>
<style lang="scss" scoped src="./_supplynavbar.mobile.scss"></style>
<style lang="scss" scoped src="./_supplynavbar.desktop.scss"></style>

<script>
import chatUtils from "../../helpers/chat";

export default {
  name: "supplyNavbarComponent",
  props: ["isWhite", "videoEnded", "selectedType", "displaySelectMenu"],
  data() {
    return {
      isMenuActive: false,
      isSelectMenuActive: false,
      displayedType: this.$props.selectedType
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    toggleSelectMenu() {
      this.isSelectMenuActive = !this.isSelectMenuActive;
    },
    setDisplayedType(value) {
      this.displayedType = value;
    },
    openChat() {
      chatUtils.openChat();
    }
  }
};
</script>
