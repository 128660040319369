<template lang="html" src="./footer.html"></template>
<style lang="scss" scoped src="./_footer.mobile.scss"></style>
<style lang="scss" scoped src="./_footer.desktop.scss"></style>

<script>
import numberUtils from "../../../helpers/numbers";
export default {
  name: "Footer",
  props: {
    calculateForm: {
      type: Object,
      required: true
    },
    currentStep: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      transportMethods: ["Container", "DoubleL", "Flex", "Coin"],
      activeTransport: "Container",
      isListActive: false
    };
  },
  emits: ["changeStep", "modal"],
  methods: {
    toggleList() {
      this.isListActive = !this.isListActive;
    },
    changeTransport(method) {
      this.activeTransport = method;
      this.toggleList();
    },
    toFixedDecimals(value, count) {
      return numberUtils.toFixedDecimals(value, count);
    }
  }
};
</script>
