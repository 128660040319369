<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="floor-image-background" :class="form.container.type"></div>
      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="floor">
          <div class="header">Interior floor</div>
          <div class="form-control temporarily-hidden">
            <input type="radio" id="optionA" name="type" value="optionA" v-model="form.interior.floor">
            <label for="optionA" :class="[{ active: form.interior.floor === 'optionA' }]">Option A</label>
          </div>
          <div class="form-control temporarily-hidden">
            <input type="radio" id="optionB" name="type" value="optionB" v-model="form.interior.floor">
            <label for="optionB">Option B</label>
          </div>
          <div class="form-control temporarily-hidden">
            <input type="radio" id="optionC" name="type" value="optionC" v-model="form.interior.floor">
            <label for="optionC">Option C</label>
          </div>
          <div class="description">
            <div class="description-title temporarily-hidden">
              {{ description[form.interior.floor].title }}
            </div>
            <div class="description-body" v-html="description[form.interior.floor].body[form.container.type]"></div>
          </div>
        </div>
        <div class="trees">
          <div class="description">
            <div class="description-body">
              <span class="text-bold">You save:</span>
              {{toFixedDecimals(form.container.quantity * constants[form.container.type].TREE_SAVINGS, 2)}} trees
            </div>
          </div>
        </div>
      </div>
      <div class="button-section">
        <button @click="$emit('changeStep', 'fmaas')" class="btn-secondary">Next</button>
      </div>
    </div>
  </div>

  <!-- Mobile designs -->
  <div class="mobile">
    <div class="container">

      <div class="header">Select interior floor</div>
      <div class="img" :class="form.container.type"></div>
      <div class="form-group temporarily-hidden">
        <div class="form-control">
          <input type="radio" id="optionAm" name="typem" value="optionA" v-model="form.interior.floor">
          <label for="optionAm">Option A</label>
        </div>
        <div class="form-control">
          <input type="radio" id="optionBm" name="typem" value="optionB" v-model="form.interior.floor">
          <label for="optionBm">Option B</label>
        </div>
        <div class="form-control">
          <input type="radio" id="optionCm" name="typem" value="optionC" v-model="form.interior.floor">
          <label for="optionCm">Option C</label>
        </div>
      </div>

      <div class="description">
        <div class="description-title temporarily-hidden">
          {{ description[form.interior.floor].title }}
        </div>
        <div class="description-body" v-html="description[form.interior.floor].body[form.container.type]"></div>
      </div>
    <!-- container -->
    </div>
    <slot></slot>
    <!-- Mobile -->
  </div>

</div>
