<div class="sidenav" :class="{ white: isWhite }">
  <a href="#model" :class="[{ active: currentActive === 'model' }, 'nav-link']">
    <span>{{ typeName }}</span>
  </a>
  <a href="#sustainability" :class="[{ active: currentActive === 'sustainability' }, 'nav-link']">
    <span>Sustainability</span>
  </a>
  <a href="#safety" :class="[{ active: currentActive === 'safety' }, 'nav-link']">
    <span>Safety</span>
  </a>
  <a href="#performance" :class="[{ active: currentActive === 'performance' }, 'nav-link']">
    <span>Performance</span>
  </a>
  <a href="#connectivity" :class="[{ active: currentActive === 'connectivity' }, 'nav-link']">
    <span>Connectivity</span>
  </a>
  <a href="#exterior-interior" :class="[{ active: currentActive === 'exterior-interior' }, 'nav-link']">
    <span>Exterior/Interior</span>
  </a>
  <a href="#tech-specs" :class="[{ active: currentActive === 'tech-specs' }, 'nav-link']">
    <span>Tech specs</span>
  </a>
  <a href="#request-quote" :class="[{ active: currentActive === 'request-quote' }, 'nav-link']">
    <span>Request quote</span>
  </a>
</div>
