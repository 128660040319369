<template lang="html" src="./supplysidenav.html"></template>
<style lang="scss" scoped src="./_supplysidenav.mobile.scss"></style>
<style lang="scss" scoped src="./_supplysidenav.desktop.scss"></style>

<script>
export default {
  name: "SupplySidenavComponent",
  props: ["currentActive", "isWhite", "typeName"],
  data() {
    return {
      sectionMargin: 150
    };
  }
};
</script>
