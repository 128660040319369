<section class="scroll-snap-item">
  <div class="main">
    <div class="img">
      <div class="img-wrapper">
        <div class="indicator-1">All-metallic <br> shipping container</div>
        <svg class="line-1">
          <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
          <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
        </svg>
        <div class="indicator-2">Light and durable <br> internal flooring</div>
        <svg class="line-2">
          <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
          <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
        </svg>
        <div class="indicator-3">CORTEN equivalent <br> steel grade structure</div>
        <svg class="line-3">
          <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
          <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
        </svg>
      </div>
    </div>
    <div class="container">
      <div class="subsection-info">
        <h5>Safety</h5>
        <div class="header">
          <h3>Built to protect</h3>
          <div class="certified">
            <img alt="ISO certified">
            Designed to ISO and CSC standards
          </div>
        </div>
        <p>
          With uncompromising strength, durability and up to 1tn less tare weight,
          Container<span class="sup">2.0</span> is built to protect people and cargo in transport.
          Designed to the industry standards, it goes beyond the requirements to ensure
          the safety of goods and equipment.
        </p>
      </div>
      <div class="btn-group">
        <a class="left" @click="openLearnMore" :class="{ disabled: isLearnMoreActive }">
          <span class="btn-more" :class="{ 'btn-more--opened': isLearnMoreActive }"></span>
          <span class="label">Learn more</span>
        </a>
      </div>
    </div>
  </div>

  <!-- Safety learn more sub-section -->
  <div id="safety-learn-more" class="learn-more" v-show="isLearnMoreActive">
    <div class="header">
      <h3>Standard ISO best in class</h3>
      <p>The Container<span class="sup">2.0</span> is one of the safest intermodal containers ever created,
        withstanding higher forces during ISO tests than the traditional containers, due to its higher payload</p>
    </div>
    <div class="img">
      <div class="img-wrapper">
        <div class="mobile">
          <div class="indicator-1">1</div>
          <div class="indicator-2">2</div>
          <div class="indicator-3">3</div>
          <div class="indicator-4">4</div>
        </div>
        <div class="desktop">
          <svg class="line-1">
            <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
            <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
          </svg>
          <div class="indicator-1">
            <div class="header">Sidewall strength</div>
            <div class="text">Designed sidewall <br> strength of 18030kg – <br> best in class</div>
          </div>
          <svg class="line-2">
            <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
            <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
          </svg>
          <div class="indicator-2">
            <div class="header">Floor and base</div>
            <div class="text">Designed lift load 64050kg <br> – best in class</div>
          </div>
          <svg class="line-3">
            <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
            <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
          </svg>
          <div class="indicator-3">
            <div class="header">Internal floor</div>
            <div class="text">Designed strength <br> of 7260kg.</div>
          </div>
          <svg class="line-4">
            <line x1="4" y1="7" x2="4" y2="100%" stroke-width="2" />
            <circle cx="4" cy="4" r="3" stroke="black" stroke-width="1" fill="white" />
          </svg>
          <div class="indicator-4">
            <div class="header">Door and front wall</div>
            <div class="text">Designed end-wall <br> strength 12020kg – <br> best in class</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mobile">
      <div class="subsection-info">
        <div class="row">
          <div class="number">1</div>
          <div class="text">
            <h5>Sidewall strength</h5>
            <p>Designed sidewall strength of 17520kg – Best in class</p>
          </div>
        </div>
        <div class="row">
          <div class="number">2</div>
          <div class="text">
            <h5>Floor and base</h5>
            <p>Designed lift load 64050kg – Best in class</p>
          </div>
        </div>
        <div class="row">
          <div class="number">3</div>
          <div class="text">
            <h5>Internal floor</h5>
            <p>Designed strength of 7260kg.</p>
          </div>
        </div>
        <div class="row">
          <div class="number">4</div>
          <div class="text">
            <h5>Door and front wall</h5>
            <p>Designed end-wall strength 12020kg – Best in class</p>
          </div>
        </div>
      </div>
    </div>
    <a class="btn-close" @click="closeLearnMore"></a>
  </div>
</section>
