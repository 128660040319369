<div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">
        <div class="container-background" :class="form.container.type" id="container-background">
          <div class="container-image" :class="form.container.type"></div>
          <img class="logo" v-if="form.exterior.logos.logo1.fileURL" :src="form.exterior.logos.logo1.fileURL">
        </div>
      </div>
      <div class="row-2">
        <!-- Footer slot -->
        <slot></slot>
      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="color-section">
          <div class="header">Select colour</div>
          <div class="colorpicker-box">
            <div class="input form-control">
              <label for="ralinput">RAL:</label>
              <input placeholder="Enter RAL code and press enter"
                v-model="ralColor"
                name="ralinput"
                type="number"
                @change="changeRal">
            </div>
            <div :class="[{'visible': ralError}, 'ral-error']">Invalid RAL code</div>
            <ColorPicker theme="dark"
              :color="form.exterior.color"
              :colors-default="[]"
              :sucker-hide="true"
              @changeColor="changeColor" />
          </div>
        </div>

        <div class="graphic-wrapper">
          <div class="graphic">
            <div>
              <div class="header">Upload decals</div>
              <div class="info">.jpg or .tiff under 10mb</div>
            </div>
            <div>
              <span>Side</span>
              <input type="file" id="firstLogo" @change="onLogoAdd($event, 'logo1')">
              <label for="firstLogo" class="btn btn-primary">
                {{form.exterior.logos.logo1.label ? form.exterior.logos.logo1.label : defaultLabel}}
              </label>
            </div>
            <div>
              <span>Front</span>
              <input type="file" id="secondLogo" @change="onLogoAdd($event, 'logo2')">
              <label for="secondLogo" class="btn btn-primary">
                {{form.exterior.logos.logo2.label ? form.exterior.logos.logo2.label : defaultLabel}}
              </label>
            </div>
            <div>
              <span>Door</span>
              <input type="file" id="thirdLogo" @change="onLogoAdd($event, 'logo3')">
              <label for="thirdLogo" class="btn btn-primary">
                {{form.exterior.logos.logo3.label ? form.exterior.logos.logo3.label : defaultLabel}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="button-section">
        <button @click="$emit('changeStep', 'interior')" class="btn-secondary">Next</button>
      </div>

    </div>
  </div>

  <!-- Mobile designs -->
  <div class="mobile">
    <div class="container">

      <div class="header">Select colour</div>
      <div class="colorpicker-box">
        <div class="input form-control">
          <label for="ralinput">RAL:</label>
          <input placeholder="Enter RAL code and press enter"
            v-model="ralColor"
            name="ralinput"
            type="number"
            @change="changeRal">
        </div>
        <ColorPicker theme="dark"
          :color="form.exterior.color"
          :colors-default="[]"
          :sucker-hide="true"
          @changeColor="changeColor" />
      </div>

      <div class="header">
        Upload decals
        <div class="info">.jpg or .tiff under 10mb</div>
      </div>

      <div class="container-background" id="container-background-m">
        <div class="container-image img" :class="form.container.type"></div>
        <img class="logo" v-if="form.exterior.logos.logo1.fileURL" :src="form.exterior.logos.logo1.fileURL">
      </div>

      <div class="graphic">
        <div>
          <span>Side</span>
          <input type="file" id="firstLogo" @change="onLogoAdd($event, 'logo1')">
          <label for="firstLogo" class="btn btn-primary">
            {{form.exterior.logos.logo1.label ? form.exterior.logos.logo1.label : defaultLabel}}
          </label>
        </div>
        <div>
          <span>Front</span>
          <input type="file" id="secondLogo" @change="onLogoAdd($event, 'logo2')">
          <label for="secondLogo" class="btn btn-primary">
            {{form.exterior.logos.logo2.label ? form.exterior.logos.logo2.label : defaultLabel}}
          </label>
        </div>
        <div>
          <span>Door</span>
          <input type="file" id="thirdLogo" @change="onLogoAdd($event, 'logo3')">
          <label for="thirdLogo" class="btn btn-primary">
            {{form.exterior.logos.logo3.label ? form.exterior.logos.logo3.label : defaultLabel}}
          </label>
        </div>
      </div>
      <!-- container -->
    </div>
    <slot></slot>
    <!-- Mobile -->
  </div>

</div>