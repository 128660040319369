<!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 1240.5 602.1" style="enable-background:new 0 0 1240.5 602.1;" xml:space="preserve">
    <g id="Routes">
    </g>
    <g id="Locations">
    </g>
    <g id="America_x2F_Canada_MAP">
    </g>
    <g id="AMERICA_x2F_CANADA_MAP_FINAL">

        <g>
            <path class="st4" d="M242.6,35.5c0,0,21.6,4.4,25.9,4s11.3-0.1,14.8-1.2s9.8,2.8,31,11.6s54.2,14.4,59.9,16.8
			c2.8,1.2,8,7,12.2,12.8c4.5,6.2,8,12.5,6.4,13.4c-19.9,10.4-26,17.6-26,17.6s-12.1-4.2-16.5-3.9c-4.4,0.3-6.2,4.7-7.2,8.1
			c-1,3.4-1.9,4.4-10.6,6.5c-8.8,2.2-14.7-2.5-14.7-2.5" />
            <path class="st4" d="M576.6,148.3c0,0-9.4-11.6-13.1-14c-3.7-2.4-14.9-7.3-20.2-8.7c-5.4-1.3-11.7-4.3-16.5-6.7
			c-4.8-2.4-13.3-5.8-16-5.3c-2.7,0.5-7.6,2.9-7.6,2.9l-11,3c0,0-9.7,0.1-13.5-0.5c-3.8-0.6-17.2-2-24.7-3.6
			c-7.5-1.6-17.3-0.6-17.3-0.6s-12.3-3.6-16-6s0,0-2.6-4s-3.4,3.3-7.1,3.2s-0.5-1.9-3.1-3.3c-2.7-1.5-7.1,2.5-8.8,5.2
			c-1.6,2.7-20.9-5.9-24.2-6" />
            <path class="st4" d="M503.1,116.5c0,0-12.1-11.7-17.4-15s-5.8-4.3-12.2-6.8c-6.4-2.5-9.5-6.3-13.3-6.4c-3.8-0.1-16.1-0.6-16.1-0.6
			s-0.9,8.2-3.2,10.8c-2.3,2.7-4,6.2-5.2,8.5c-1.2,2.2,0.8,7.8,0.8,7.8s2,5.5,6.3,7.3c4.3,1.8,9.5,5.6,12.1,7.9
			c2.6,2.4,7.4,6.2,19.2,8.4c11.8,2.2,9.3-1.9,16.3-2.2c7-0.3,14.6,1,19.4,0.7c4.9-0.4,11.6,9.1,15.3,11.7
			c3.7,2.6,13.6,14,16.2,16.4s24.5,11.3,24.5,11.3L581,190c0,0,6.3,4.8,8.9,7.1c2.6,2.4,10.5,7.7,10.5,7.7l21.9,6.2" />
            <path class="st4" d="M624.6,149.5c0,0,0.6,5.2,7.7,6.8c7.1,1.6,16.2,5.6,18.8,7c2.7,1.5,1.9,6.9,7.4,5.3c5.5-1.6,4.4-1.2,7.7-3.8
			s4-6.1,7.2-4.4s7.4,4.5,12.8,4.7c5.4,0.2,8.6,0.3,14,1.4c5.4,1.1,6.2-0.2,8.3,1.8s15.4,2,17.5,3.5s10.3,6.4,12.9,10.2
			c2.6,3.8,11.6,15.4,13.2,16.8c1.6,1.4,8.8,9.9,8.8,9.9l3.5,10c0,0,1.7-16.8,0.8-22.7c-0.9-6-4.6-6.5-4.6-6.5s-9.2-17.8-12.9-19.8
			c-3.7-2-14.5-0.8-18.2-3.2c-3.7-2.4-6.5-0.7-12.8-3.6c-6.4-3,0,0-4.2-2.4s0.9-10.9-3.9-12.1s-16.3-21.7-18.3-22.4
			c-27.3-8.9-25.6,3-33.2,7.4c-7.5,4.4-37.8,16.5-37.8,16.5s-21.7,1.1-42.7-1.5c-21-2.6-15.5-3.7-21.4-6.7
			c-5.8-2.9-30.3-14.7-37.2-18.2s-9-5.3-14.9-6.9c-5.9-1.6-11.7-5.4-16.5-7.9s-24.9-11.8-24.9-11.8l-17.6-9.1c0,0-15-2.9-20.4-4
			s-8.7,0.1-11.4,1.9c-2.8,1.7-22-5-25.8-6" />
            <path class="st4" d="M820.6,187.6c0,0,11.9,0.9,13.4,2.6c1.6,1.7,2.5,5.3,7.2,8.6c4.7,3.4,9.7,0.3,14.1,0.5c0,0-2.8,8.7-6.1,11.8
			c-3.4,3.1-6.7,12.4-8.8,12.3s-0.5-0.9-9.2-0.3c-8.7,0.6-8.7,0.6-8.7,0.6" />
            <path class="st4" d="M917.1,181.4c0,0,2.4-5.8,0.3-8.2c-2.1-2.4-5.8-3.8-9.6-4.9c-3.7-1-8.7,1.5-15.7-0.6c-7-2.1-9.3,2.5-13-0.6
			c-3.7-3.1-23.6-6.7-26.2-7c-2.7-0.2-1.8-0.7-5.6,3.3s-13.3,10.5-16.9,12.6c-3.5,2.2-4.9,8.9-9.8,11.5c-5,2.6-20.4,10.2-20.4,10.2
			s-13.3,10.5-15,11.8c-1.7,1.3-20.8,9.1-20.8,9.1s7.8,5.4,10.1,3.3s15.7,0.5,17.8,0.6c2.2,0.1,17.1-2.3,17.1-2.3l13.1,3.4
			c-4.9,1.6-13.6,8.6-13.6,8.6s-9.5,1.5-12.8,1.4c-3.2-0.1-25.9-0.9-25.9-0.9l-9.7-0.3c0,0-17.1,10.8-19.3,11.6s-9.3,3.8-10.4,2.4
			c-1-1.4-5.7-7-5.7-7l-15.5-5.1l-9-5.3" />
            <path class="st4" d="M703.2,253.3c0,0,5.3,5.5,7.8,8.3c2.5,2.8,4.6,5.2,7.8,8.5s-0.6,7.3-0.4,12.3c0.2,5,2.3,8.3,5.8,12.2
			c3.5,3.9,8.4,2.4,12.1,3c3.7,0.6,10.3,0.4,12.5,0s11.5-1.7,11.5-1.7l8.8,2.4c0,0-3.8,4-5,6.5s-4.8,10.9-8.1,13.5s-5.5,13.9-7.4,18
			c-2,4-3.1,14-3.8,14.1c-0.4,0.1-2.8,3.9-5.5,8.3c-2.7,4.3-5.6,9.2-6.8,11.2c-2.5,4,0.7,26.9,0.6,30.1c-0.1,3.2-2.5,17.7-2.5,17.7
			s5.7-7.1,7.3-9.8s-0.9-6.4,0.4-11.8c1.3-5.4-7.8-19.8-7.8-19.8s-6.3-5.3-7.8-6.7c-1.5-1.4-9-7.1-10.8-8.8
			c-1.8-1.7-5.3-1.8-6.8-4.4c-1.4-2.6-13.8-8-13.7-10.3c0.1-2.3-1-13.6-1-13.6l1.8-10c0,0-3.3-10.2-4.2-11.1
			c-0.9-0.9-6.2-7.7-6.2-9.4c0.1-1.7,0.7-20.3,0.7-20.3l3.1-14.2l-4.5-16" />
            <path class="st4"
                d="M763.4,259.5c0,0,22.4,15.4,26.1,16.9c3.7,1.5,6.5-5.1,7.8,3.6c1.3,8.7-0.6,17-0.6,17l-4.2,14.7" />
            <path class="st4" d="M814.2,262c-2.7,0.8-3.4,4.9-9.3,1.9c-5.8-2.9-14.3-7.8-16.5-7.4c-2.2,0.4-12.6,3.6-14.7,3.3
			c-2.2-0.3-8.1,0.4-10.2-0.4c-2.1-0.8-12.4-2.5-16.6-4.5c-4.3-1.9-23.8-0.8-23.8-0.8l-40.9-3" />
            <path class="st4" d="M498.2,263.6c0,0,6.8-3.9,11.6-2.5s9.8,4.2,15.3,5.3s8.2,1.7,12.6,0.4s4.4-1.7,9.8-1.7s19-1.8,22.2-1.9
			s11.3,2,13.5,0.3c2.2-1.8,9.9-6,9.9-6s19.4,2.5,27.5,3.2c8.1,0.7,16.8,0.1,29.2,1s8.7-1.5,15.8-3.5c7.1-2,16.5-6.9,16.5-6.9
			s-4.4,14.6-5.5,17.3c-1.2,2.7-2.4,6.7-4.7,11.7c-2.3,4.9-7.5,12.2-7.5,12.2s1.4,5.5-0.3,8.8c-1.7,3.3-4.6,7.7-8.5,10.3
			c-3.9,2.6-10.8,14.6-13.6,16.8c-2.8,2.2-27.1,17.4-27.1,17.4s-5.7,6.9-7.3,7.1c-1.6,0.2-7.2,4.1-7.8,6.3s-10.2,11.9-10.2,11.9
			l-19,16.6l-18.3,13l-13.6,18.6c0,0-8.8,2.9-9.9,4.2c-1.1,1.3-7.8,0.6-5.4,5.7c2.4,5.1,3.2,12.4,3.2,12.4" />
            <path class="st4" d="M594,397.7c0,0,10.7-18.3,25.4-11.4c3.8,1.8-1.6-16.4-2.6-18.3c-1-1.9-9.6-20.1-10-22.5s1.4-8.3,0.4-10.6
			c-1-2.3,2.7-20.4,0.7-24.1c-1.9-3.7-2.3-23.8-2.2-25.1c0,0-6.8,9.8-6.9,11.1c0,1.4,1.6,16.4-0.8,22.3c-2.4,5.8-11,21-11,21
			s-10.5,10.1-11.7,11.7c-1.2,1.6-6.7,4.5-4.7,8.7c2,4.2,4.1,18.2,6.5,21.8S594,397.7,594,397.7L594,397.7" />
            <path class="st4" d="M664.9,296.8c0,0-0.9,4.4,4.4,10.6c5.2,6.2-0.8,5.4-0.3,9.1c0.4,3.7-7.9,8.8-9.6,11.5s-11,6.4-13.3,9.1
			c-2.3,2.7-16.6,11.7-20.8,16.3c-4.1,4.6-17.7-0.6-17.7-0.6l-4.3,14.6c-1.7,3.1-4.1,8.1-5.9,12.5c-1.8,4.5-3.5,17.6-3.5,17.6
			s-4.4,1.7-12.6,0.7c-1.8-0.2-7.4,0.1-12.1,0.1c-3.5,0-6.4-0.1-7-0.8c-2.2-2.4-9.8,3-9.8,3s-16.2-0.1-18.9-0.7
			c-2.7-0.6-14.7-5.4-16.6-8.5c-1.9-3.1-8.9-3.8-15.3-6.3c-6.4-2.5-11.8-1.3-16.6-5.6c-4.7-4.3-15.3-11.5-15.3-11.5" />
            <path class="st4" d="M657,287.4c0,0,13.6-1.3,18.9,0.7c5.3,2,22.1,2.6,25.8,3.2c3.8,0.6,9.1,1.7,11.7,5c2.6,3.3,4.2,4.2,4.5,9.3
			s0.5,15-0.6,16.8s-3.6,9.9-3.6,9.9l1.3,9.3l2.1,5.4l4,6.2c0,0-15,12.6-16.1,13.6c-1.2,1.1-13.7,1.5-13.9,6.1c-0.2,4.6,0,0,0,0
			s15.5,8.5,22.6,6.5s10.7,10.1,15,10.7c4.3,0.6,6.6,4,6.6,4" />
            <path class="st4" d="M697.7,252.5c0,0,2.5,6,2.9,8.7c0.4,2.7-3.9,4.4,2.9,8.7c6.9,4.3,10.7,3.1,12.7,8.2s3.2,7.4,2.9,11.5
			c-0.2,4.1-1.2,15.9-1.2,15.9" />
            <path class="st4" d="M787.9,285.2c0,0-15.5,26.4-16.7,27.7c-1.1,1.3-11.8,13.2-17.8,15.3c-6,2.1-17.5,6.8-19.2,7.8
			c-1.7,1-10,11.2-10,11.2" />
            <path class="st4" d="M797.3,280.1c0,0-7.1,3.4-9.4,5.1c-2.2,1.7-18.4,13.6-18.4,13.6c-1.6,0-9.3,4.2-12.6,5.6
			c-3.3,1.4-18.1,7.3-18.1,7.3" />
            <polyline class="st4" points="788.4,256.6 789.5,270.7 787.9,285.2 		" />
            <path class="st4" d="M582.5,148.9c0,0,2.3-0.5,11.1,6.5c8.8,7,27.6,13.3,30.3,15.6c2.6,2.4,8.4,13.6,7.4,15.8s1.1,5.5,3.2,7.4
			s7.7,11.2,11.4,14.1c3.7,2.9,12.6,10.1,15.3,9.7c2.7-0.4,10.1,4.4,10.1,4.4s-0.7,13.2,2.8,16" />
            <path class="st4" d="M319.5,137.7c0,0-7.1,7.4-7.7,10.4s0,14-1.3,14.6c-1.3,0.6-4.2,7.3-3.2,10c1,2.7,3.5,4,6.1,9.4
			c2.7,5.5,3.3,9,2.1,14.3c-1.2,5.2-12.6,11.1-11,17.3c1.6,6.2,0.3,17.2,2.9,19.2s-4.4,21-4.4,21s2.8,1.9,8.1,3.9
			c5.3,2,9.2,2.4,10.4,6.6s6,13.6,6.6,16.2c0.6,2.7,0.3,7.8,4.7,8.5c4.5,0.7,9.6,5.9,14.5,5.7c4.9-0.3,16.5-2.9,19.2-2.9
			c2.7,0.1,7.3,1.3,8.2-3.5c0.9-4.8,0.2-9.7,3-12.9c2.8-3.2,3-8.5,6.8-11.1s5.2-5,9.1-7.4c3.9-2.4,11.5-10.4,13.4-12.6
			s5.7-5.5,6.8-9.3s-1.6-10.7-1.6-10.7s-7.7-0.3-11-0.7c-3.3-0.4-5.5-0.6-9.5-1.4s-3.6,1.2-7.2-0.9c-3.6-2.1-6.8-1.6-9.9-0.3
			c-3.1,1.4-6.2,6.2-8.8,3c-2.7-3.2-3.6-5.8-6.8-5.3c-3.2,0.5-5.3-2.4-7.5,1.1s-8.2,7.2-10,9.8s-1.8,6-7.3,6.2
			c-5.4,0.3-8.6-0.3-12.4,2.4c-3.8,2.7-4.5,2.4-8.9,5.1l-6.2,8.6" />
            <path class="st4" d="M307.1,248.8l5.2-12.3c0,0,5.6-7.5,8.4-8.8s4.3,0.1,5.7,0.2s4.4,3.3,7.6,4.7c3.2,1.4,5.6-5.6,9.4-6.8
			c3.8-1.2,10.3-1.5,10.3-1.5l4.8,2.9c0,0,17.7,5.4,22.5,5.3s6.5-3.6,10.9-2.4s1.4,6.1,5.7,6.6s0.2-5.9,3.5-6.3
			c3.3-0.3,10.7,3.1,13.2,4c2.5,0.9,9.3,4,20.6,3.9c11.3-0.1,6.7-2.9,6.7-2.9" />
            <path class="st4" d="M381.8,147.9c0,0-7.3,7.9-10.6,9.6c-3.3,1.7-11.3,3.2-6.9,5.2c4.4,2,6.7,2.1,7.7,3.9c1,1.9-0.1,0.7,2.4,7.7
			c2.5,7,4,9.8,6.1,12.2c2.1,2.4,5.3,4.6,4.1,7.1c-1.2,2.5-0.8,9.4-0.3,8.9c0.5-0.4,2,3.3,6.8,5.7c4.8,2.4,15,5.1,16.6,5.1
			c1.6,0.1,6.4-1.6,11.5,0.4c5.2,2,11.5,4.5,12.3,6.8c0.8,2.3,3.9,6.2,5.9,9.3c2.2,3.3,3.9,7.7,10.6,6.6c5.4-0.8,21.6,0.8,21.6,0.8
			s10.9-1.5,15.1,0.8s8.9,7,11.6,8c2.7,1,9.1,3.2,13.4,3.9c4.3,0.8,10.3-1.6,13.5,0.5c3.2,2.1,15.1,1.6,21.5,2.8
			c6.4,1.1,15,2.8,15,2.8" />
            <path class="st4" d="M403.7,142.6c2.1,1.4,10.9,12.4,10.3,15.6c-0.7,3.2-0.1,4.1,3.6,5.6c3.7,1.5,6.4,3,10.1,5.4
			c3.7,2.4,6.3,5.2,10.6,4.9c4.3-0.3,6-1.8,6.4,2.8s0.2,8.3,4,8.5c3.8,0.1,13.5,2.4,13.5,2.4l4.6,6.1c0,0,5.6,10,5.5,11.9
			s-1.1,1.8-2.9,5.4c-1.7,3.6-3.5,6.7-0.8,8.1s7.4,3.9,9.6,4c2.2,0.1,5.4-1.2,7,0.2s7.6-1.9,9.6,2.4c2,4.3,3.4,10.1,4.2,12.5
			c0.7,2.4-4.1,17.7-4.1,17.7l-1.8,4.3" />
            <path class="st4" d="M314.6,120c3.7,2,38.3,18.7,41.5,20.9s8.4,6,12.2,6.6s9.8-1.5,13.5,0.5c3.7,2,8.5,4.4,11.8,2.7
			s7.3-6.5,10-8.1c2.8-1.5,8.1,0.5,10.2,2.6c2.1,2.1,9.2,0.1,10.8,0.4c1.6,0.3,19.9,4.1,19.9,4.1s9.7,1.3,14.5,1.9s16.7,1,18.3,2.7
			c1.6,1.7,5.2,4.8,8.5,4.1s3.3-2,6.5,0.4c3.2,2.4,12.2,5.9,13.8,5.9c1.6,0.1,13.9,3.2,13.9,3.2s0.1-2.3,5-3
			c4.9-0.7,16.2,0.1,16.2,0.1s17.2,2,20.9,6.7c3.6,4.7,19.6,12.1,22.2,13.5c2.7,1.4,8.7-0.6,14,3s4.3,1,12.1,8.7s16.4,16.7,19.7,20
			c3.3,3.3,10.2,8.1,11,13.6c0.9,5.5,13.4,18.2,13.4,18.2c-1.2,4.1-4.8,13-4.8,13s-1.1,0.4-9.9,4s-25.8,10.7-27.5,13.4
			s-6.7,6.6-6.7,6.6s-16-6.9-20.2-10.9s-15.8-11.5-15.8-11.5s-10-6.9-16.4-11.5c-6.3-4.5-11.5-9.8-11.5-9.8s-10.3,0.1-18.9-0.7
			c-8.6-0.8-7.3-6.6-8.9-9.4c-1.5-2.8-2.4-9.6-3.9-12.9c-1.5-3.2-5.7-6.1-10.2-8.8c-4.5-2.7-15.2-6.2-18.9-7.7
			c-3.7-1.5-3.3-0.1-4.2-5.5c-0.9-5.4-14.7-9.2-14.7-9.2s19.3,3.1,25.9,0.9c6.6-2.2,8.5-4.8,13-6.5c4.6-1.7,5.1-1.2,8.2,1.7
			s7.9,5.1,13.8,6.1c5.9,1,3.2,2.2,11.7,4.4c8.6,2.2,9,2.9,13.8,5.2c4.9,2.3,12.9,3.9,12.9,3.9s12.9-0.4,17.7,2.5
			c4.8,2.9,18,4.5,21.4,4.1c3.4-0.4,21.7,1.1,21.7,1.1" />
            <path class="st4" d="M346.1,311.5c0,0,2.2,17.8,8.7,19c3.2,0.6,7,1.9,10.6,2.9c3.6,1,7.1,1.9,9.8,1.5c5.4-0.7,19-1.3,22.7,1
			c3.7,2.2,7.8,7.8,11.5,11.2c3.7,3.3,4.1,6.1,9,4.4s4.4-4.4,8.5-2.9s6.1,3.7,12.6,4.2c6.5,0.6,16.7,3.2,16.7,3.2
			s8.2,10.8,13.6,12.3c5.4,1.6,13.9,4.6,18.8,3.4c4.9-1.2,13.6-2.7,19.6-4.8c6-2.1,18.6-6.2,21.8-6.1c3.2,0.1,20.6-1.1,26-1.4
			c5.4-0.3,14.6,1,14.6,1" />
            <path class="st4" d="M365.4,333.4c0.9-1.8-10.6-13.5-9.7-15.3" />
            <path class="st4"
                d="M376.4,334.4c7.2-2.7,15.2-5.4,23.4-4c-4.8-5.2-8.2-8.5-6.9-15.3c1.4-5.7,7.6-6.5,13.6-5.2
			c6.1,1.2,9.6,4.4,15.7,5.7c8.5,1.3,16-3.5,24.5-3.2c4.7,6.3,10.6,11.7,19.4,9.5c-2.7,5-3.6-0.2,0,0c-8.3,9.9-7.5,21.7-11.4,32.9" />
            <path class="st4" d="M328.5,310c16-7.5,37,16.6,47.8,24.4" />
            <path class="st4" d="M462.2,357.5c2.6-0.4,4,4.3,6-5.5c2-9.7,1.5-10.4,2.7-13.9c1.2-3.5,7.9-10.1,10.1-11.1c2.2-1-7.6,1.7-9.8,1.2
			s-7.9-1.8-7.9-1.8" />
            <path class="st4" d="M575.3,351.7c0,0,0.2-7.1,0.4-12.5s-4.5-12-4.4-13.7s4.7-11.4,5.4-14.5c0.7-3.2,0.2-8.2,0.4-10.5
			c0.1-2.3,6.2-7.5,11-8.7c4.8-1.2,17.6-6.2,17.6-6.2l-12.9,13.1c0,0-2.3,4.7-1.4,7.9c1,3.2-4.2,10.5-4.2,10.5" />
            <path class="st4" d="M570.3,355.7l-13.7-12c0,0-12.8-4.3-14.9-5.7c-2.1-1.4-7.3-8.7-10-9.1c-2.7-0.4-8.7-2.4-8.7-2.4
			s8.5-5.7,11.7-6.5c3.3-0.8,10.3-3.3,12.8-4.8c2.5-1.5,13-7.9,15.8-9.6c2.8-1.8,17.3-9.6,17.3-9.6" />
            <path class="st4" d="M340.6,299.4c2.1-0.6,14.6,9.3,18.6,9.2c4-0.1,10.6-2.9,15.4-2.8c4.9,0.1,9.8-3.2,13.6-3.3
			c3.8-0.2,6.1-3.6,9.5,0.3c3.4,3.9,8.8,7.5,8.8,7.5" />
            <path class="st4" d="M504.9,505.8c0.5,0.4,2.6,1,4.8-0.7c2.2-1.7,2.6-3.1,3.4-6s3.4-12,3.4-12" />
            <path class="st4"
                d="M445,415.1c0,0,7.3,7,8.3,1.8s1-5.2,1-5.2s8.9-9.5,9.7-11c0.8-1.5,1.8-6.7,4.6-6.6c2.8,0.1,6.9-1.9,9.9-1.4
			c3,0.5,1.8,2.1,6.3,3.3s3.3,7,7.2,9.9c3.9,2.9,5.5,7,9.4,9.3c3.9,2.3,9.5,3.9,9.7,8.9s5.1,8.9,6,10.2c0.9,1.3,5.3,1.6,7.1,3.6
			s1.3,9.5,1.3,9.5s-6.4,5.5-5.4,8.1c1,2.6,8,4.9,8.1,7.1c0.2,2.3,1.7,12.2,1.7,12.2s2.9,4.2,6,5.6c3.1,1.4,6.2,5.8,6.2,5.8" />
            <path class="st4" d="M528.7,517.2c5.2-2.1,5.2-5.8,3.7-9.7c-1.5-3.9-2.7-4.2-0.5-6.8s11.8-7.3,13.5-8.6c1.8-1.3,4.9-6.6,4.9-6.6
			l-8.2,0.7c0,0-6.1,2-9.1,1c-3.1-1-7.1,1.9-11,1.5c-4-0.4-2.5-0.6-5.6-1.5c-3.1-1-6-4.2-11.9-5.2c-6-1-8-1.2-8-1.2s0.7-5.6-0.9-6.8
			c-1.6-1.2-6.2-9.7-9.2-10.7c-3-1-7.9-7.6-12.2-10.3c-4.3-2.6-2.4-7-7.1-12.3c-4.6-5.3-11.6-13.1-16-16.6
			c-4.4-3.5-5.8-10.2-10.2-15.1c-4.4-4.9-2.6-3.8-9.6-6.9s-2.8-3.7-4.1-5.9s1-10.7-2.1-15.3c-3.1-4.6,0.6-6.1-1.1-7.7
			s3.1-5.5,2.1-9.9c-1-4.5,3-5.7,3-5.7l-1.3-9.2" />
            <path class="st4"
                d="M730.9,246.3c0,0,5.7-7.5,10.7-12.6c2.2-2.3,4.2-4.1,5.6-4.6c4.4-1.7,17.2-10.5,17.2-10.5" />
            <path class="st4"
                d="M697.7,252.5c3.4-3.1,12-18.3,19.7-21.1c0,0,6.1,1.3,10.9,0.4c4.8-0.9,13.3,1.9,13.3,1.9" />
            <path class="st4" d="M533.1,156.3c0,0,24,19.2,25.6,20.2s22.3,13.5,28.7,13.8c6.4,0.2,15.3,10.1,20.6,13
			c5.3,2.9,68.2,43.4,68.2,43.4l-3.4-49.6c0,0-6.4-4.6-4.4-9.5s3.6-6,3.4-10.8c-0.2-4.8-13.4-8.1-13.4-8.1" />
            <path class="st4" d="M773.7,259.9c0,0,8.8-4.9,7.9-9.5c-0.9-4.6-2.2-10.6-3.3-11c-1.1-0.5-7.9-6.7-7.9-6.7s-14.7,5.9-15.4,8.6
			c-0.7,2.7-5.6,4.8-3.9,8.1c1.8,3.3,12.4,10.2,12.4,10.2" />
            <path class="st4" d="M716.3,278.1l7.6-16.1c0,0-9.9,6-12.1,6.2c-2.2,0.2-19.1,5.5-21.8,5.8c-2.7,0.4-18.1,6.2-18.1,6.2
			c-8.1-1.7-7.9-5.2-14.5-3c-6.6,2.2-5,4.6-11.5,3c-6.4-1.6-7.3-6.6-12.3-3.8c-5,2.7-11.4,1.1-13.6,3.3c-2.2,2.2-14.3,5.9-14.3,5.9
			s-15.7-0.1-20-0.7s-9.9,4.2-12.7,6.4c-2.8,2.2-7.7,4.3-17,6.1s-7.1,2.2-15.2,1.9c-8.1-0.3-14.9,8.6-17.3,9.9
			c-2.4,1.3-13.1,8.6-20.4,14c-7.2,5.4-14.8,5-22.3,3.9" />
            <path class="st4" d="M722.4,318c0,0,0-16.5,0.1-19.2l-3.4-9.3" />
            <path class="st4" d="M718.5,353.4c0,0,6-0.4,11.9-0.1c5.9,0.3,16.6,1.1,16.6,1.1" />
            <path class="st4" d="M685.9,377.1c0,0-1.8-8.1-4.2-6s-24.5-4.1-26-5c-1.5-0.9-5.4,16.3-5.4,16.3l7.4-4.9l11.2-8.7l8.1-12.2
			c0,0,15-9.4,17.3-10.2c2.3-0.8,12.3,0,15.7,2.4s2.2,4.2,2.4,7.7c0.3,3.5,3.3,15.2,3.7,16.5c0.3,1.3,5.5,7.1,5.5,7.1
			s7.3-0.6,9.3,0.3c1.9,0.9,5.2,4.9,6.6,7.6c1.4,2.6,5.6,15.3,7.8,19.7c2.2,4.4,5,11.4,5.2,15.3s-0.4,12.1-0.4,12.1" />
            <path class="st4" d="M619.4,386.3c0,0,2.3-2.9,12.2,0.5c9.9,3.4,9-3,13.8-2.4c4.8,0.6,0,0,0,0s9.1-27,9.3-33.9
			c0.2-6.9,4.6-22.6,4.6-22.6s3.7,3.6,10.7,4.6c7,1,8,3.6,14.4,4.4c6.5,0.8,10.3,0.4,10.3,0.4l19.1-5.1c0,0,6.4-12.9,8.6-14.2
			c2.2-1.3,16.4-6.3,16.4-6.3s9.4,8,9.6,11c0.2,3-0.3,7.3-0.3,7.3s3.4,3,3.4,3.9s10.9,1.7,11.9,4.6" />
            <path class="st4" d="M619.4,386.3c0,0,6.6-9.3,13-7.4c6.4,1.9,9.5,10,12.9,5.6c3.4-4.4,0,0,0,0" />
            <path class="st4" d="M560,498.9c0,0-1.2-8.6-4.1-8.3s-10.5,1.5-10.5,1.5" />
            <path class="st4" d="M569.3,495.9c0.4-1.4,0.8-5-2.8-6.9c-3.7-1.9-7.6-5.7-11.9-4.6c-4.2,1-4.2,1-4.2,1l-3.2-11.3
			c-3.2-3.2-5-9-5.2-14c-0.2-5,0.4-23.6-0.2-25.3c-0.6-1.7-1.2-8.5-1.2-8.5l6.5-3.2c0,0-0.1-0.5,4.8-0.7c4.9-0.2,5.8-3.6,9.6-5
			c3.7-1.4,11.3,1.3,13-0.3" />
            <path class="st4" d="M576.5,358.1c0,0,8.4-4.5,15.9-1.7c7.5,2.7,13.7,3,17.6,4.1c3.9,1.2,4.6,2.3,15,3.4c10.4,1,30.8,2.1,30.8,2.1
			" />
            <line class="st4" x1="692.9" y1="377" x2="657.7" y2="377.5" />
            <path class="st4" d="M654.3,327.1c0,0-16.6-14.2-18.7-15.9c-2.1-1.8-4-2.2-8.7-3.3c-4.7-1.1-2-0.9-7.9-0.3s-6.3,3.8-11,3.2
			S598,319,598,319s-6.6-2.5-10.8-2s-12.3-0.9-12.3-0.9s-15.9-4.5-19.1-5.9" />
            <path class="st4"
                d="M619,307.5c0,0,0,0,8.2-7.6c8.2-7.6,9.5-5,18.3-8.3c8.8-3.3,11.5-4.2,11.5-4.2l14.9-7.2" />
            <path class="st4" d="M676.3,246.7c0,0-6.6,2.5-14.1,2.2c-7.6-0.3-13.5-0.9-20.5-0.7s-11,1.9-17.2,2.6c-6.2,0.7-12.7,4.7-21.6,2.7
			c-8.9-2-32.8-1.3-35.5-1.3c-2.7,0.1-7.7,4-7.7,4" />
            <path class="st4" d="M677,356.6c0,0-0.5-9.7-4.2-13.5c-3.7-3.8-13.5-15-13.5-15" />
            <line class="st4" x1="346.1" y1="311.5" x2="355.7" y2="308.5" />
            <path class="st4" d="M846.2,222c0,0-10.6,10.1-11.2,10.3c-0.6,0.3-25.6,9-25.6,9l15.6,5.8l-11.5,11.3" />
            <line class="st4" x1="805" y1="244.3" x2="798.6" y2="259.3" />
            <polyline class="st4" points="840.2,218.9 841.3,198.8 822.6,223.6 		" />
            <line class="st4" x1="318.3" y1="121.9" x2="319.5" y2="137.7" />
            <line class="st0" x1="545.4" y1="408" x2="547.1" y2="423.2" />
        </g>
        <g id="LOCATIONS">

            <ellipse id="Ellipse_202_5_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 45.4051 180.6562)" class="st0"
                cx="240.9" cy="35.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_6_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 62.7901 281.0647)" class="st0"
                cx="370.9" cy="64.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_7_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 68.6769 341.5242)" class="st0"
                cx="446.8" cy="87.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_8_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 46.3541 341.1048)" class="st0"
                cx="435.2" cy="114.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_9_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 18.1527 277.7478)" class="st0"
                cx="344.5" cy="116.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_10_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -4.0976 263.6679)" class="st0"
                cx="316.4" cy="136.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_11_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -24.5757 268.7125)" class="st0"
                cx="312.3" cy="164" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_12_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 6.8963 261.7719)" class="st0"
                cx="319.6" cy="122.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_13_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 9.7508 320.0729)" class="st0"
                cx="391.5" cy="148.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_14_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -69.385 308.0849)" class="st0"
                cx="335.2" cy="237.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_15_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -90.2704 289.5273)" class="st0"
                cx="304.6" cy="253.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_16_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -90.3224 302.8649)" class="st0"
                cx="318.4" cy="259.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_17_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -98.4434 309.5002)" class="st0"
                cx="324.6" cy="273.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_18_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -111.6012 324.08)" class="st0"
                cx="335.6" cy="296.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_19_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -123.1537 324.1345)"
                class="st0" cx="329.9" cy="310.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_20_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -118.9076 335.513)" class="st0"
                cx="345.8" cy="311.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_21_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -95.5315 348.8837)" class="st0"
                cx="373.6" cy="289.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_22_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -43.6552 360.8734)" class="st0"
                cx="414" cy="233.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_23_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -41.9701 425.4961)" class="st0"
                cx="492.9" cy="263.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_24_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 51.8982 403.1451)" class="st0"
                cx="512.9" cy="138.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_96_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 64.5739 389.929)" class="st0"
                cx="503.2" cy="117" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_25_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 65.2011 456.4501)" class="st0"
                cx="583.9" cy="149.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_26_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 76.9385 487.9034)" class="st0"
                cx="627.8" cy="151" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_27_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 106.5411 635.9085)" class="st0"
                cx="816.7" cy="190" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_28_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 141.6789 704.8256)" class="st0"
                cx="917" cy="182.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_95_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 147.5765 686.6909)" class="st0"
                cx="898.2" cy="166.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_29_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 108.6377 654.9525)" class="st0"
                cx="840.6" cy="197.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_30_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 112.1165 666.6967)" class="st0"
                cx="856.5" cy="198.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_31_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 92.6297 665.4474)" class="st0"
                cx="845.2" cy="221.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_32_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 84.319 649.7005)" class="st0"
                cx="822.2" cy="223.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_34_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 78.8193 633.0048)" class="st0"
                cx="799.4" cy="221.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_35_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 68.7936 606.3123)" class="st0"
                cx="762.3" cy="220.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_36_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 66.2912 645.4286)" class="st0"
                cx="808" cy="243.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_37_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 68.0006 658.9366)" class="st0"
                cx="825.1" cy="247.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_38_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 57.3183 654.3964)" class="st0"
                cx="814.3" cy="258.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_43_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 11.2178 652.9654)" class="st0"
                cx="789.5" cy="313" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_46_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -14.6086 642.0989)" class="st0"
                cx="763.6" cy="338.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_47_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -31.473 631.9907)" class="st0"
                cx="743" cy="353.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_52_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -60.6669 600.1334)" class="st0"
                cx="690.2" cy="372.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_53_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -82.4816 571.9514)" class="st0"
                cx="645.4" cy="385" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_54_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -84.9062 514.3257)" class="st0"
                cx="575" cy="359.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_55_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -21.7148 585.3461)" class="st0"
                cx="691.9" cy="318.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_56_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -10.1383 570.5421)" class="st0"
                cx="684" cy="297.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_57_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 1.4622 586.618)" class="st0"
                cx="705" cy="291.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_58_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 6.4761 597.37)" class="st0"
                cx="720.4" cy="290.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_59_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 13.7422 591.6963)" class="st0"
                cx="717.2" cy="279.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_60_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 26.801 589.3458)" class="st0"
                cx="725.2" cy="262.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_76_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 45.2152 604.5948)" class="st0"
                cx="748.5" cy="248" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_77_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 62.8421 616.3417)" class="st0"
                cx="771.4" cy="232.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_71_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 29.879 576.768)" class="st0"
                cx="711.6" cy="252.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_72_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 44.1513 561.8087)" class="st0"
                cx="700.6" cy="227.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_73_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 39.7128 471.0841)" class="st0"
                cx="588.8" cy="187.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_74_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 33.8557 504.1582)" class="st0"
                cx="625.8" cy="211.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_75_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 4.8068 387.7434)" class="st0"
                cx="470.7" cy="188.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_70_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 42.648 582.329)" class="st0"
                cx="724.7" cy="239.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_61_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 10.709 572.6269)" class="st0"
                cx="697" cy="273.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_62_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -0.4444 548.9908)" class="st0"
                cx="662.8" cy="275" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_63_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -12.0023 547.8906)" class="st0"
                cx="655.7" cy="288.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_64_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -23.1218 511.3437)" class="st0"
                cx="606" cy="283.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_65_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -9.9454 495.3189)" class="st0"
                cx="593.3" cy="259.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_66_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 6.0137 514.3703)" class="st0"
                cx="624.3" cy="249.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_67_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 12.9357 527.7581)" class="st0"
                cx="643.9" cy="248.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_68_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 31.3642 547.2694)" class="st0"
                cx="676.7" cy="235.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_69_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 21.8682 552.6682)" class="st0"
                cx="678.4" cy="249.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_48_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -73.0718 654.8365)" class="st0"
                cx="749.6" cy="415.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_94_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -61.2768 640.9623)" class="st0"
                cx="738.9" cy="394" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_49_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -80.1977 641.6552)" class="st0"
                cx="730.2" cy="417.1" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_51_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -84.1347 658.4656)" class="st0"
                cx="748.5" cy="430.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_44_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 6.6624 642.0273)" class="st0"
                cx="774.1" cy="313" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_45_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -3.8037 637.5989)" class="st0"
                cx="763.6" cy="323.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_39_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 51.8699 643.127)" class="st0"
                cx="798" cy="259.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_40_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 38.0751 649.3506)" class="st0"
                cx="798.6" cy="279" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_41_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 36.6283 640.8621)" class="st0"
                cx="787.7" cy="276.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_42_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 40.2138 621.0661)" class="st0"
                cx="765.7" cy="262.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_78_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 24.325 602.4284)" class="st0"
                cx="735.4" cy="272" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_79_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -2.5691 617.4492)" class="st0"
                cx="740" cy="311.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_80_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -67.2192 573.232)" class="st0"
                cx="654.6" cy="367.3" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_81_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -39.0889 565.4372)" class="st0"
                cx="659.3" cy="329.6" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_82_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -77.7372 465.1942)" class="st0"
                cx="523" cy="326.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_85_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -90.9922 469.8618)" class="st0"
                cx="518.6" cy="344.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_86_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -118.534 511.3863)" class="st0"
                cx="554.7" cy="398" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_100_" transform="matrix(0.6873 -0.7264 0.7264 0.6873 -125.7729 523.7486)"
                class="st0" cx="545.4" cy="408" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_101_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -33.7369 589.7869)"
                class="st0" cx="691.2" cy="335.4" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_102_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -42.8246 596.7684)"
                class="st0" cx="695" cy="349.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_103_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -36.3581 616.1048)"
                class="st0" cx="721.5" cy="351.7" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_97_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -119.7017 431.8481)"
                class="st0" cx="461.7" cy="360.5" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_98_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -125.9645 391.5325)"
                class="st0" cx="409.9" cy="347.9" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_99_" transform="matrix(0.9905 -0.1373 0.1373 0.9905 -48.8211 62.3512)" class="st0"
                cx="427.5" cy="385" rx="5.9" ry="5.6" />

            <ellipse id="Ellipse_202_87_" transform="matrix(0.9905 -0.1373 0.1373 0.9905 -53.9575 78.3843)" class="st0"
                cx="541.1" cy="430.2" rx="5.9" ry="5.6" />

            <ellipse id="Ellipse_202_89_" transform="matrix(0.707 -0.7072 0.7072 0.707 -170.2172 520.1218)" class="st0"
                cx="542.6" cy="465.5" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_90_" transform="matrix(0.9905 -0.1373 0.1373 0.9905 -61.1064 80.106)" class="st0"
                cx="550" cy="482.9" rx="5.9" ry="5.6" />

            <ellipse id="Ellipse_202_91_" transform="matrix(0.707 -0.7072 0.7072 0.707 -182.798 549.8562)" class="st0"
                cx="572.2" cy="495.5" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_92_" transform="matrix(0.707 -0.7072 0.7072 0.707 -188.9424 541.8502)" class="st0"
                cx="559.4" cy="498.9" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_93_" transform="matrix(0.707 -0.7072 0.7072 0.707 -209.5653 527.6368)" class="st0"
                cx="532" cy="516.7" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_4_" transform="matrix(0.707 -0.7072 0.7072 0.707 -209.5136 505.1441)" class="st0"
                cx="504.9" cy="505.4" rx="5.6" ry="5.5" />

            <ellipse id="Ellipse_202_88_" transform="matrix(0.9905 -0.1373 0.1373 0.9905 -52.5754 79.4053)" class="st0"
                cx="549.2" cy="420.7" rx="5.9" ry="5.6" />

            <ellipse id="Ellipse_202_50_" transform="matrix(0.9905 -0.1373 0.1373 0.9905 -51.917 82.532)" class="st0"
                cx="572.2" cy="417.5" rx="5.9" ry="5.6" />

            <ellipse id="Ellipse_202_83_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 -90.393 425.3164)" class="st0"
                cx="465.4" cy="321.2" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_84_" transform="matrix(0.7074 -0.7068 0.7068 0.7074 -115.5102 380.5755)"
                class="st0" cx="401.9" cy="329.8" rx="5.5" ry="5.6" />

            <ellipse id="Ellipse_202_33_" transform="matrix(0.7044 -0.7098 0.7098 0.7044 81.9446 661.4177)" class="st0"
                cx="835" cy="232.3" rx="5.5" ry="5.6" />
        </g>
    </g>
</svg>
