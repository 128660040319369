<template lang="html" src="./model.section.html"></template>
<style lang="scss" scoped src="./_model.section.mobile.scss"></style>
<style lang="scss" scoped src="./_model.section.desktop.scss"></style>

<script>
export default {
  name: "ModelSection",
  props: ["isActive"],
  data() {
    return {
      animate: false,
      txtOvlay:
        "<span>Our Supply Chain<span class='sup'>4.0</span> system provides all the<br/>vital information you need to<br/>take full control anytime, anywhere</span>",
      videoEnded: false
    };
  },
  mounted() {
    // Do not replay video on subsequent visits
    if (sessionStorage.getItem("video") === "true") {
      this.onVideoEnd();
    }
  },
  methods: {
    onVideoEnd() {
      this.videoEnded = true;
      this.$emit("video-end");
      // Do not replay video on subsequent visits
      sessionStorage.setItem("video", "true");
    },
    sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    async txtAnimation() {
      await this.sleep(13000);
      this.animate = true;
    }
  }
};
</script>
