<template lang="html" src="./devices.html"></template>
<style lang="scss" scoped src="./_devices.mobile.scss"></style>
<style lang="scss" scoped src="./_devices.desktop.scss"></style>

<script>
export default {
  name: "Devices",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currSlide: "first",
      showModal: false,
      showContainerPPopup: false,
      showContainerSPopup: false,
      showDoubleLPopup: false,
      showFlexMPopup: false,
      showFlexSPopup: false,
      package: {
        containerP: {
          title: "Container P",
          id: "containerP",
          body:
            "Best for businesses that need an invisible “install and forget” solution for their containers. Up to 10 year battery life without recharging."
        },
        containerS: {
          title: "Container S",
          id: "containerS",
          body:
            "Best for businesses that need an invisible solution for their containers with a rechargable long life battery."
        },
        doubleL: {
          title: "Double L",
          id: "doubleL",
          body:
            "Best for businesses that need a portable, rechargeable, multiple-use solution with long life battery."
        },
        flexM: {
          title: "Flex Multi-Use",
          id: "flexM",
          body:
            "Best for businesses that need a small, portable, and rechargable, multiple-use solution."
        },
        flexS: {
          title: "Flex Single-Use",
          id: "flexS",
          body:
            "Best for businesses that need a low-cost, small, portable, and customisable single-use solution."
        },
        coin: {
          title: "Coin",
          id: "coin",
          body:
            "Best for businesses that need a very low-cost, multiple-use solution, to record temperature, humidity and light."
        }
      },
      containerInfo: [
        {
          title: "<div>Permanent mounting</div>",
          info: [""]
        },
        {
          title: "",
          info: [
            "Global coverage<br/>Self powered<br/>Recording 24/7<br/>Long battery life<br/>Integrated into dry container's structure"
          ]
        }
      ],
      containerApplication: [
        {
          title: "Application",
          info: [
            "<div class='spec-icon-container container-device'><i class='load-icon spec-icon container-icon'></i> New</div><div class='spec-icon-container container-device'><i class='load-icon spec-icon container-icon'></i> Old</div>"
          ]
        }
      ],
      containerSpecs: [
        {
          title: "Type",
          info: ["Real time monitoring device"]
        },
        {
          title: "Temperature measurement range",
          info: ["-30°C to 60°C (±0.2°C)"]
        },
        {
          title: "Security",
          info: ["HTTPS TLS 1.2<br/>Blockchain method"]
        },
        {
          title: "Wireless connectivity",
          info: ["4G LTE Cat M1<br/>GPRS (2G)<br/>Bluetooth"]
        },
        {
          title: "Humidity measurement range",
          info: ["0 to 100% RH (±1.5%)"]
        },
        {
          title: "Other Features",
          info: [
            "Recording<br/>Real-time alarms<br/>OTA FW update<br/>OTA configuration<br/>Intra-device communication<br/>Global coverage<br/>Communication with backend system & mobile app"
          ]
        },
        {
          title: "Localisation method",
          info: ["Assisted GNSS (GPS, Glonass, Galileo, Beidu) Cell-locate"]
        },
        {
          title: "Light measurement range",
          info: ["0.008 to 70,000 lux (0.2%)"]
        },
        {
          title: "Performance",
          info: [
            "Water and dust proof IP67<br/>Impact resistant IK08<br/>UV resistant<br/>Chemicals resistant<br/>Flame proof"
          ]
        },
        {
          title: "Connectivity w/ ext. sensors",
          info: ["via Bluetooth"]
        },
        {
          title: "Charging",
          info: ["Micro USB"]
        },
        {
          title: "On-board sensors",
          info: [
            "Temperature, Humidity, Shock, Motion, Tilt, Hall effect, Light"
          ]
        },
        {
          title: "Battery",
          info: [
            "Container<span class='sup'>1.5</span><br/>P: Primary battery lifetime up to 10 years<br/>S: Secondary battery lifetime up to 18 months"
          ]
        }
      ],
      doubleLInfo: [
        {
          title: "Portable, Multi-use device",
          info: [""]
        },
        {
          title: "",
          info: [
            "Global coverage<br/>Self powered<br/>Recording 24/7<br/>Easily mounted<br/>Long life battery<br/>Zero initial investment"
          ]
        }
      ],
      doubleLApplication: [
        {
          title: "Application",
          info: [
            "<i class='stack-icon spec-icon'></i><i class='trailer-icon spec-icon'></i><i class='load-icon spec-icon'></i><i class='truck-icon spec-icon'></i><i class='train-icon spec-icon'></i><i class='transport-icon spec-icon'></i>"
          ]
        }
      ],
      doubleLSpecs: [
        {
          title: "Type",
          info: ["Real time monitoring device"]
        },
        {
          title: "Temperature measurement range",
          info: ["-30°C to 60°C (±0.2°C)"]
        },
        {
          title: "Security",
          info: ["HTTPS TLS 1.2<br/>Blockchain method"]
        },
        {
          title: "Wireless connectivity",
          info: ["4G LTE Cat M1<br/>GPRS (2G)<br/>Bluetooth"]
        },
        {
          title: "Humidity measurement range",
          info: ["0 to 100% RH (±1.5%)"]
        },
        {
          title: "Other Features",
          info: [
            "Recording<br/>Real-time alarms<br/>OTA FW update<br/>OTA configuration<br/>Intra-device communication<br/>Global coverage<br/>Communication with backend system & mobile app"
          ]
        },
        {
          title: "Localisation method",
          info: ["Assisted GNSS (GPS, Glonass, Galileo, Beidu) Cell-locate"]
        },
        {
          title: "Light measurement range",
          info: ["0.008 to 70,000 lux (0.2%)"]
        },
        {
          title: "Performance",
          info: [
            "Water and dust proof IP67<br/>Impact resistant IK08<br/>UV resistant<br/>Chemicals resistant<br/>Flame proof"
          ]
        },
        {
          title: "Connectivity w/ ext. sensors",
          info: ["via Bluetooth"]
        },
        {
          title: "Charging",
          info: ["Micro USB"]
        },
        {
          title: "On-board sensors",
          info: [
            "Temperature, Humidity, Shock, Motion, Tilt, Hall effect, Light"
          ]
        },
        {
          title: "Battery",
          info: ["Rechargeable LiFePO4<br/>Lifetime up to 18 months"]
        }
      ],
      flexInfo: [
        {
          title: "<div>Portable Multi or</div><div>SIngle use device</div>",
          info: [""]
        },
        {
          title: "",
          info: [
            "Global coverage<br/>Self powered<br/>Recording 24/7<br/>Easily mounted<br/>Long battery life<br/>Zero initial investment"
          ]
        }
      ],
      flexApplication: [
        {
          title: "Application",
          info: [
            "<i class='box-icon spec-icon'></i><i class='load-icon spec-icon'></i><i class='stack-icon spec-icon'></i><i class='transport-icon spec-icon'></i><i class='truck-icon spec-icon'></i>"
          ]
        }
      ],
      flexSpecs: [
        {
          title: "Type",
          info: ["Real time monitoring device"]
        },
        {
          title: "Temperature measurement range",
          info: ["-30°C to 60°C (±0.2°C)"]
        },
        {
          title: "Security",
          info: ["HTTPS TLS 1.2<br/>Blockchain method"]
        },
        {
          title: "Wireless connectivity",
          info: ["4G LTE Cat M1<br/>GPRS (2G)<br/>Bluetooth"]
        },
        {
          title: "Humidity measurement range",
          info: ["0 to 100% RH (±1.5%)"]
        },
        {
          title: "Other Features",
          info: [
            "Recording<br/>Real-time alarms<br/>OTA FW update<br/>OTA configuration<br/>Intra-device communication<br/>Global coverage<br/>Communication with backend system & mobile app"
          ]
        },
        {
          title: "Localisation method",
          info: ["Assisted GNSS (GPS, Glonass, Galileo, Beidu) Cell-locate"]
        },
        {
          title: "Light measurement range",
          info: ["0.008 to 70,000 lux (0.2%)"]
        },
        {
          title: "Performance",
          info: [
            "Water and dust proof IP67<br/>Impact resistant IK08<br/>UV resistant<br/>Chemicals resistant<br/>Flame proof"
          ]
        },
        {
          title: "Connectivity w/ ext. sensors",
          info: ["via Bluetooth"]
        },
        {
          title: "Charging",
          info: ["Micro USB"]
        },
        {
          title: "On-board sensors",
          info: [
            "Temperature, Humidity, Shock, Motion, Tilt, Hall effect, Light"
          ]
        },
        {
          title: "Battery",
          info: ["Rechargeable LiFePO4<br/>Lifetime up to 5 months"]
        }
      ],
      coinInfo: [
        {
          title: "Portable device",
          info: [""]
        },
        {
          title: "",
          info: [
            "Portability<br/>Self powered<br/>Recording 24/7<br/>Easily mounted<br/>Low cost<br/>Disposable or multi use"
          ]
        }
      ],
      coinApplication: [
        {
          title: "Application",
          info: [
            "<i class='box-icon spec-icon coin-box'></i><i class='stack-icon spec-icon'></i>"
          ]
        }
      ],
      coinSpecs: [
        {
          title: "Type",
          info: ["Real time monitoring device"]
        },
        {
          title: "Humidity measurement range",
          info: ["0 to 100% RH (±1.5%)"]
        },
        {
          title: "Battery",
          info: ["Primary Li<br/>Lifetime up to 12 months"]
        },
        {
          title: "Wireless connectivity",
          info: ["Bluetooth (Transmits via DOUBLEL or FLEX device)"]
        },
        {
          title: "Light measurement range",
          info: ["0.008 to 70,000 lux (0.2%)"]
        },
        {
          title: "Other features",
          info: [
            "Recording<br/>Real-time alarms (through, DoubleL, Flex, Container1.5 or   Container2.0)<br/>OTA FW update<br/>OTA configuration<br/>Communication with DoubleL, Flex, Container1.5, Container2.0 and mobile app"
          ]
        },
        {
          title: "On-board sensors",
          info: ["Temperature, Humidity, Light"]
        },
        {
          title: "Temperature measurement range",
          info: ["-40°C to 85°C (±0.2°C)"]
        }
      ]
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    },
    openContainerPPopup() {
      this.showContainerPPopup = true;
    },
    closeContainerPPopup() {
      this.showContainerPPopup = false;
    },
    openContainerSPopup() {
      this.showContainerSPopup = true;
    },
    closeContainerSPopup() {
      this.showContainerSPopup = false;
    },
    openDoubleLPopup() {
      this.showDoubleLPopup = true;
    },
    closeDoubleLPopup() {
      this.showDoubleLPopup = false;
    },
    openFlexMPopup() {
      this.showFlexMPopup = true;
    },
    closeFlexMPopup() {
      this.showFlexMPopup = false;
    },
    openFlexSPopup() {
      this.showFlexSPopup = true;
    },
    closeFlexSPopup() {
      this.showFlexSPopup = false;
    },
    changeSlide(slide) {
      this.currSlide = slide;
    }
  },
  emits: ["changeStep"]
};
</script>
