<template>
  <!-- Components -->

  <SupplyNavbarComponent
    v-bind:isWhite="isWhite"
    v-bind:videoEnded="true"
    v-bind:selectedType="'Type 20'"
    v-bind:displaySelectMenu="true"
  />
  <SupplySidenavComponent
    v-bind:currentActive="currentActive"
    v-bind:isWhite="isWhite"
    v-bind:typeName="'Supply Chain'"
    v-show="viewMode === 'main'"
  />
  <ChatComponent v-bind:inverted="currentActive === 'tech-specs'" />
  <!-- Calculate savings form -->
  <CalculateSavingsComponent
    @modal="toggleModal"
    v-show="openModal"
    v-bind:defaultTypeKey="'type20d'"
  />
  <!-- Sections -->
  <ModelSection
    id="model"
    @modal="toggleModal"
    @video-end="oneVideoEnd"
    v-show="viewMode === 'main'"
    v-bind:isActive="currentActive === 'model'"
  />
  <ConnectivitySection
    id="connectivity"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'connectivity'"
    v-bind:isLearnMoreActive="viewMode === 'connectivity'"
    v-show="viewMode === 'connectivity' || viewMode === 'main'"
  />
  <LiveMonitoringSection
    id="live-monitoring"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'live-monitoring'"
    v-bind:isLearnMoreActive="viewMode === 'live-monitoring'"
    v-show="viewMode === 'live-monitoring' || viewMode === 'main'"
  />
  <AnalyticsSection
    id="analytics"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'analytics'"
    v-bind:isLearnMoreActive="viewMode === 'analytics'"
    v-show="viewMode === 'analytics' || viewMode === 'main'"
  />
  <InsightsSection
    id="insights"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'insights'"
    v-bind:isLearnMoreActive="viewMode === 'insights'"
    v-show="viewMode === 'insights' || viewMode === 'main'"
  />
  <FieldOfApplicationSection
    id="field-of-application"
    @learn-more="onLearnMoreToggle"
    v-bind:isActive="currentActive === 'field-of-application'"
    v-bind:isLearnMoreActive="viewMode === 'field-of-application'"
    v-show="viewMode === 'field-of-application' || viewMode === 'main'"
  />
  <TechSpecsSection
    id="tech-specs"
    v-bind:isActive="currentActive === 'tech-specs'"
    v-show="viewMode === 'main'"
  />
  <RequestQuoteSection
    id="request-quote"
    v-bind:isActive="currentActive === 'request-quote'"
    v-show="viewMode === 'main'"
  />
  <EspaComponent v-bind:topRight="true" v-bind:bottomLeft="false" />
  <FooterComponent scroll-snap v-show="viewMode === 'main'" />
</template>

<script>
// Components
import SupplyNavbarComponent from "@/components/supplynavbar/supplynavbar.component.vue";
import SupplySidenavComponent from "@/components/supplysidenav/supplysidenav.component.vue";
import ChatComponent from "@/components/chat/chat.component.vue";
import FooterComponent from "@/components/footer/footer.component.vue";
import EspaComponent from "@/components/espa/espa.component.vue";
// Forms
import CalculateSavingsComponent from "@/components/calculate-savings/calculate-savings.component.vue";
// Sections
import ModelSection from "./sections/model/model.section.vue";
import ConnectivitySection from "./sections/connectivity/connectivity.section.vue";
import LiveMonitoringSection from "./sections/live-monitoring/live-monitoring.section.vue";
import FieldOfApplicationSection from "./sections/field-of-application/field-of-application.section.vue";
import AnalyticsSection from "./sections/analytics/analytics.section.vue";
import InsightsSection from "./sections/insights/insights.section.vue";
import TechSpecsSection from "./sections/tech-specs/tech-specs.section";
import RequestQuoteSection from "./sections/request-quote/request-quote.section.vue";
import { nextTick } from "vue";

export default {
  name: "Main",
  components: {
    SupplyNavbarComponent,
    SupplySidenavComponent,
    ChatComponent,
    FooterComponent,
    EspaComponent,
    CalculateSavingsComponent,
    ModelSection,
    LiveMonitoringSection,
    FieldOfApplicationSection,
    AnalyticsSection,
    InsightsSection,
    ConnectivitySection,
    TechSpecsSection,
    RequestQuoteSection
  },
  data() {
    return {
      app: null,
      openModal: false,
      isSafari: false,
      viewMode: "main",
      currentActive: 0,
      sectionEls: [],
      videoEnded: false,
      whiteSections: ["insights", "field-of-application", "tech-specs"] // Based on designs
    };
  },
  computed: {
    isWhite() {
      return this.whiteSections.includes(this.currentActive);
    }
  },
  methods: {
    toggleModal(value) {
      if (value) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
      this.openModal = value;
    },
    async onLearnMoreToggle(value) {
      const fullModeActivate = value === "main";
      const openSection = "#" + (fullModeActivate ? this.viewMode : value);
      if (fullModeActivate) {
        this.currentActive = this.viewMode;
        if (!this.isSafari) {
          // Update what is visible
          this.viewMode = value;
          // Scroll back to section hero without smooth-scrolling
          await nextTick();
          this.app.style.scrollBehavior = "auto";
          await nextTick();
          document.querySelector(openSection).scrollIntoView();
          // Restore scroll snapping for subsection after scroll finishes
          await nextTick();
          await this.delay(1000);
          this.app.style.scrollSnapType = "y mandatory";
          await nextTick();
          await this.delay(1000);
          this.app.style.scrollBehavior = "smooth";
        } else {
          // Special handling for Safari

          // Restore scroll snapping for subsection after scroll finishes
          await nextTick();
          this.app.style.scrollSnapType = "y mandatory";
          await this.delay(500);

          // Update what is visible
          await nextTick();
          this.viewMode = value;

          // Scroll back to section hero without smooth-scrolling
          await nextTick();
          document.querySelector(openSection).scrollIntoView();
        }
      } else {
        // Remove scroll snapping while in subsection
        this.app.style.scrollSnapType = "unset";
        await nextTick();
        this.viewMode = value;
        await nextTick();
        document.querySelector(openSection + "-learn-more").scrollIntoView();
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    oneVideoEnd() {
      this.videoEnded = true;
    }
  },
  mounted() {
    const UA = navigator.userAgent.toLowerCase();
    this.isSafari = UA.indexOf("safari") > 0 && UA.indexOf("chrome") < 0;
    document.location.hash = "";
    this.app = document.querySelector("#app");
    this.sectionEls = document.querySelectorAll("section");
    this.currentActive = "model";
    const handleIntersectionEvent = entries => {
      const entriesLength = entries.length;
      // On Safari the entries array contains 2 elements in a fixed order
      // which respects the sections' order regardles of which one we chose
      // first.
      if (entriesLength === 2) {
        const firstEntry = entries[0].target.id;
        const secondEntry = entries[entriesLength - 1].target.id;
        if (this.currentActive === secondEntry) {
          this.currentActive = firstEntry;
        } else {
          this.currentActive = secondEntry;
        }
      } else {
        this.currentActive = entries[0].target.id;
      }
    };
    const options = {
      threshold: 0.9
    };
    const observer = new IntersectionObserver(handleIntersectionEvent, options);
    this.sectionEls.forEach(section => {
      observer.observe(section);
    });
  }
};
</script>
