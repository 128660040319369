<section class="scroll-snap-item">
  <div class="main">
    <div class="img">
      <div class="img-wrapper">
        <div class="img-text">
          <span class="text-bold">
            Container<span class="sup">2.0</span>
          </span>
          Type45D
        </div>
      </div>
    </div>
    <div class="header-wrapper">
      <div class="header">The most environmentally friendly and smart intermodal container on earth</div>
      <button class="btn-primary">
        <router-link to="/request-form">Request quote</router-link>
      </button>
    </div>
  </div>
</section>