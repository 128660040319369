<section class="scroll-snap-item">

  <div class="video-container desktop" :class="[{ hide: videoEnded }]">
    <video autoplay muted id="intro-video" @play="txtAnimation()" @ended="onVideoEnd()">
      <source src="../../../../assets/media/videos/common/intro.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div 
      class="overlay-text" 
      v-html="txtOvlay"
      :class="[{ animate: animate && !videoEnded}]"
    >
    </div>
    <div @click="onVideoEnd()" class="skip-intro">
      Skip intro 
    </div>
  </div>

  <div class="main">
    <div class="model-menu-container">
      <div class="model-title text-bold" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">Container<span class="sup">2.0</span></div>
      <div class="model-menu-wrapper" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">
        <div class="model-menu">
          <div class="mobile-spacer mobile"></div>
          <a href="/type20d" class="model-menu-item">Type 20D</a>
          <a href="/type40d" class="model-menu-item">Type 40D</a>
          <a href="/type45d" class="model-menu-item">Type 45D</a>
          <a href="#" class="model-menu-item active">Type 53D</a>
          <div class="mobile-spacer mobile"></div>
        </div>
      </div>
    </div>

    <div class="img">
      <div class="img-wrapper">
        <div class="img-text" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent']">
          <span class="text-bold">
            Container<span class="sup">2.0</span>
          </span>
          Type53D
        </div>
      </div>
    </div>

    <div>
    <!-- Desktop -->
    <div class="model-info desktop-flex" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">
      <div class="model-info-item">CO<span class="sub">2</span> reduction</div>
      <div class="divider">|</div>
      <div class="model-info-item">100% Recyclable</div>
      <div class="divider">|</div>
      <div class="model-info-item">Higher Payload</div>
      <div class="divider">|</div>
      <div class="model-info-item">Fuel Savings</div>
      <div class="divider">|</div>
      <div class="model-info-item">Globally Connected</div>
    </div>
    <!-- Mobile -->
    <div class="model-info mobile">
      <div class="left">
        <div class="model-info-item">Fuel Savings</div>
        <div class="model-info-item">Globally Connected</div>
        <div class="model-info-item">Connected 24/7</div>
      </div>
      <div class="right">
        <div class="model-info-item">CO<span class="sub">2</span> reduction</div>
        <div class="model-info-item">100% Recyclable</div>
        <div class="model-info-item">Higher Payload</div>
      </div>
    </div>

    <div class="personalize" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">
      <button class="btn-primary" @click="$emit('modal', true);">Personalize <span class="desktop">Container<span class="sup">2.0</span></span>
        performance</button>
    </div>
  </div>

    <div class="scroll" :class="[{ 'animate__animated animate__fadeInUp': isActive && videoEnded }, 'transparent', 'animate__delay-1s']">Scroll to explore</div>
  </div>
</section>